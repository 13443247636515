import { BarChart, BoxPlot } from '../../../Charts';
import { IFilterState, } from '../../../redux/Filters/reducer';
import { colorPalette12, GROUP_3_COLOR_PALETTE } from "../../../styles/ColorCodes";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_LACTATION_FILTER } from '../../../redux/CowsList/constants';
import { SELECT_LACTATION_FILTERS, } from '../../../redux/Filters/constants';
import ODChart from "../KPICharts/ODChart";

const DOA: React.FC = () => {
  const { t } = useTranslation();
  // const history = useHistory();

  const path = "od";

  const odState = useSelector((state: any) => state.odState);
  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);
  const navigationState = useSelector((state: any) => state.navigationState);
  const [secondaryExpandedState, handleToggleSecondaryExpandedState] =
    useState(false);

  const dispatch = useDispatch();

  const keys = ["OpenDays", "CompletionStatus"];
  const filters: any = {};

  useEffect(() => {
    const startDate = new Date(filtersState.today);
    startDate.setDate(
      startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days
    );

    filtersState.lactationFilters.culledChecked =
      filtersState.lactationFilters.l1Checked =
      filtersState.lactationFilters.l2Checked =
      filtersState.lactationFilters.l3Checked =
      true;

    // get the cowlist
    dispatch({
      type: GET_COWS_LIST_REQUEST,
      payload: {
        filters,
        keys,
        path,
        startDate: startDate,
        stopDate: filtersState.today,
        lactationFilters: filtersState.lactationFilters,
      },
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]);

  const handleToggleLactationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return <KpiLayout
    title={t("ProjectedDaysOpen")}
    subHeader={t("EvaluatesHerdPerformanceOnDaysOpenOfNonPregnantCowsBasedOnTheirProjectedDaysOpen.")}
    libraryUrl="od/lvl2/summary"
    kpi='OD'
    chartImage={ODChart}
    handleToggleLactationFilter={handleToggleLactationFilter}
    chartSlot1={[{
      title: t("OverviewOfDaysOpenThePast") + " " + t(filtersState.anoestrusLengthSelectedTimePeriod.key),
      chart: <BoxPlot
        colors={colorPalette12}
        cowList={cowsListState.filteredData}
        cowListOpen={navigationState.cowListOpen}
        data={cowsListState.graphP2Data}
        yLegend={t("OpenDays")}
        xLegend={t("Month")}
        keys={["OpenDays"]}
        path={path}
      />
    }]}
    chartSlot2={[
      {
        title: t("InseminationNumber"),
        chart: <BarChart
          filterKeys={[
            { key: "CompletionStatus", value: "name" },
            { key: "MAXAINumber", value: "x" },
          ]}
          filters={["MAXAINumber", "CompletionStatus"]}
          path={path}
          title={t("InseminationNumber")}
          tooltip={{
            displayAxis: 'neither',
            title: 'ykey'
          }}
          data={cowsListState.graphP3Data}
          cowList={cowsListState.filteredData}
          // to trigger deselection of scatter area
          cowListOpen={false}
          keys={["Pregnant", "PotentiallyPregnant", "NotPregnant"]}
          indexBy="Bin"
          yLegend={t("NumberOfCows")}
          xLegend={t("InseminationNumber")}
          maxValue={cowsListState.graphP3Max}
          colors={GROUP_3_COLOR_PALETTE}
        />
      }
    ]}
  />
}

export default DOA;