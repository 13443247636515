import React, { Fragment } from "react";
import { ContentWrapper } from "../../Content";
import { useTranslation } from "react-i18next";
import { CurrentReproductionPerformance, BaselineReport } from "../../Cards";
import { DataTypeToggle } from "../../DataType";
import { BASELINE_REPORT_TOGGLE } from "../../redux/Navigation/constants";
import "./style.scss";
import { Breadcrumbs, Grid } from "lattec-ui-library";
import { Icon, Typography, muiCore, } from 'lattec-ui-library';

const BaselineReportPage: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumbs
        breadcrumbs={[
          { label: t("Dashboard"), link: "/dashboard" },
          { label: t("HNADashboard"), link: "/reproductionperformancerepro" },
          { label: t("BaselineReport") },
        ]}
        backToVariant={false}
        onClick={(link) => window.history.pushState(null, "", link)}
      />
      <Grid direction="row" container item alignItems="center" justify="flex-start" >
        <Grid item >
          <div style={{ marginBottom: '16px', marginRight: '20px' }}>
            <Icon variant="fal" fontAwesomeName="chart-line" size={32} colors={{ fillColor: 'rgb(113, 113, 113)' }} />
          </div>
        </Grid>
        <Grid item >
          <Typography variant="Heading 2" text={t("BaselineReport")} style={{ paddingBottom: '16px', color: 'rgb(113, 113, 113)' }} />
        </Grid>
        <Grid item style={{ marginLeft: 'auto' }}>
          <DataTypeToggle toogleId={BASELINE_REPORT_TOGGLE} />
        </Grid>
      </Grid>
      <muiCore.Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
      <Typography variant="Body Default" text={t("BaselineReportSubHeader")} style={{ color: 'rgb(113, 113, 113)', fontStyle: 'italic' }} />

      <ContentWrapper>
        <Grid container direction="row">
          <Grid xs={12} lg={6} direction="column">
            <Grid
              spacingRight={2}
              spacingBottom={2}
            >
              <BaselineReport
                disableOnClickHeader={true}
                headers={["Pin", "KPI", "BaselineStart", "Today", "_", "DiffNum"]}
                toogleId={BASELINE_REPORT_TOGGLE}
              />
            </Grid>
          </Grid>
          <Grid xs={12} lg={6} direction="column">
            <Grid>
              <Grid
                spacingBottom={2}
              >
                <CurrentReproductionPerformance
                  toggleId={BASELINE_REPORT_TOGGLE}
                  simple={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Fragment>
  );
};

export default BaselineReportPage;
