import { StepGuidesTranslations } from "./StepGuidesTranslations";
import belgian from "./bg-BG";
import czech from "./cs-CZ";
import danish from "./da-DK";
import deutch from "./de-DE";
import greek from "./el-GR";
import spanish from "./es-ES";
import estonian from "./et-EE";
import finish from "./fi-FI";
import frenchCA from "./fr-CA";
import frenchFR from "./fr-FR";
import hungarian from "./hu-HU";
import icelandic from "./is-IS";
import italien from "./it-IT";
import japaneese from "./ja-JP";
import korean from "./ko-KR";
import lithuanian from "./lt-LT";
import latvian from "./lv-LV";
import norvegean from "./nb-NO";
import dutch from "./nl-NL";
import polish from "./pl-PL";
import portugueseBR from "./pt-BR";
import portuguesePT from "./pt-PT";
import romanian from "./ro-RO";
import russian from "./ru-RU";
import slovenian from "./sl-SI";
import serbian from "./sr-RS";
import swedish from "./sv-SE";
import turkish from "./tr-TR";
import ukrainian from "./uk-UA";
import vietnamese from "./vi-VN";
import chineseCN from "./zh-CN";
import chineseTW from "./zh-TW";

import english from "./en";
export const translations = {
  "bg-BG": { translations: belgian },
  "cs-CZ": { translations: czech },
  "da-DK": { translations: danish },
  "de-DE": { translations: deutch },
  "el-GR": { translations: greek },
  "es-ES": { translations: spanish },
  "et-EE": { translations: estonian },
  "fi-FI": { translations: finish },
  "fr-CA": { translations: frenchCA },
  "fr-FR": { translations: frenchFR },
  "hu-HU": { translations: hungarian },
  "is-IS": { translations: icelandic },
  "it-IT": { translations: italien },
  "ja-JP": { translations: japaneese },
  "ko-KR": { translations: korean },
  "lt-LT": { translations: lithuanian },
  "lv-LV": { translations: latvian },
  "nb-NO": { translations: norvegean },
  "nl-NL": { translations: dutch },
  "pl-PL": { translations: polish },
  "pt-BR": { translations: portugueseBR },
  "pt-PT": { translations: portuguesePT },
  "ro-RO": { translations: romanian },
  "ru-RU": { translations: russian },
  "sl-SI": { translations: slovenian },
  "sr-RS": { translations: serbian },
  "sv-SE": { translations: swedish },
  "tr-TR": { translations: turkish },
  "uk-UA": { translations: ukrainian },
  "vi-VN": { translations: vietnamese },
  "zh-CN": { translations: chineseCN },
  "zh-TW": { translations: chineseTW },
  "options": {
    ...StepGuidesTranslations.options,
    "plural_rule": "n != 1",
    "plural_number": "2",
  },
  "en": { translations: english, },
}
