import {
  GET_PERFORMANCE_SCORE_HISTORY_REQUEST,
  GET_PERFORMANCE_SCORE_HISTORY_SUCCESS,
  GET_PERFORMANCE_SCORE_HISTORY_FAILURE,
} from "./constants";

export interface IState {
  gotData: boolean;
  loading: boolean;
  error: null | string;
  data: any;
  scoreValue: null | string,
  scoreDate: null | string,
}

export const initialState: IState = {
  gotData: false,
  loading: false,
  error: null,
  data: [],
  scoreValue: null,
  scoreDate: null,
};

export const PerformanceScoreHistoryReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_PERFORMANCE_SCORE_HISTORY_REQUEST:
      return {
        ...state,
        gotData: false,
        loading: true,
        error: null,
      };
    case GET_PERFORMANCE_SCORE_HISTORY_SUCCESS:

      const newState = {
        ...state,
        loading: false,
        gotData: true,
        data: action.payload.data,
        scoreValue: action.payload.scoreValue,
        scoreDate: action.payload.scoreDate,
      };

      return newState;

    case GET_PERFORMANCE_SCORE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
