import { BarChart, ScatterChart } from '../../../Charts';
import { IFilterState, } from '../../../redux/Filters/reducer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_LACTATION_FILTER } from '../../../redux/CowsList/constants';
import { SELECT_LACTATION_FILTERS, } from '../../../redux/Filters/constants';
import HnirChart from "../KPICharts/HNIRChart";
import { GROUP_3_COLOR_PALETTE, GROUP_2_COLOR_PALETTE } from '../../../styles/ColorCodes';

const HNIR: React.FC = () => {
  const { t } = useTranslation();

  const path = "hnir";

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);
  const navigationState = useSelector((state: any) => state.navigationState);
  const [secondaryExpandedState, handleToggleSecondaryExpandedState] =
    useState(false);

  const dispatch = useDispatch();
  const keys = ["Inseminated", "NotInseminated"];
  const filters: any = {};

  useEffect(() => {
    const startDate = new Date(filtersState.today);
    startDate.setDate(
      startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days
    );
    filtersState.lactationFilters.culledChecked =
      filtersState.lactationFilters.l1Checked =
      filtersState.lactationFilters.l2Checked =
      filtersState.lactationFilters.l3Checked =
      true;
    filtersState.lactationFilters.delayedChecked = false;

    // get the cowlist
    dispatch({
      type: GET_COWS_LIST_REQUEST,
      payload: {
        filters,
        keys,
        path,
        startDate: startDate,
        stopDate: filtersState.today,
        lactationFilters: filtersState.lactationFilters,
      },
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]);

  const handleToggleLactationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return <KpiLayout
    title={t("HNInseminationRate")}
    subHeader={t("EvaluatesHowManyHNHeats(OccuringAfterVoluntaryWaitingPeriod),WhichAreAccompaniedByAnInsemination.")}
    kpi='HNIR'
    libraryUrl="hnir/lvl2/summary"
    chartImage={HnirChart}
    handleToggleLactationFilter={handleToggleLactationFilter}
    chartSlot1={[{
      title: t("InseminationsOnDaysInMilk"),
      chart: <ScatterChart
        data={cowsListState.scatterP1HNIR}
        cowList={cowsListState.filteredData}
        cowListOpen={navigationState.cowListOpen}
        yLegend={t("HeatNumber")}
        xLegend={t("DIMOnHeat")}
        colors={GROUP_2_COLOR_PALETTE}
        keys={["DaysFromCalving", "HeatNo"]}
        path={path}
        stepSizeX={20}
        stepSizeY={1}
        delayed={filtersState.lactationFilters.delayedChecked}
      />

    }]}
    chartSlot2={[{
      title: t("HeatAfterVoluntaryWaitingPeriod"),
      chart: <BarChart
        colors={GROUP_2_COLOR_PALETTE}
        filterKeys={[
          { key: "HNIRState", value: "name" },
          { key: "ranges", value: "x" },
        ]}
        filters={["HeatNo", "HNIRState"]}
        path={path}
        title=''
        tooltip={{
          displayAxis: 'neither',
          title: 'ykey'
        }}
        data={cowsListState.graphP3Data}
        cowList={cowsListState.filteredData}
        // to trigger deselection of scatter area
        cowListOpen={false}
        keys={["Inseminated", "NotInseminated"]}
        indexBy="Bin"
        yLegend={t("NumberOfCows")}
        xLegend={t("HeatNumber")}
        maxValue={cowsListState.graphP3Max}
        delayed={filtersState.lactationFilters.delayedChecked}
      />
    }
    ]}
  />;
}

export default HNIR;