import { getBaseUrl } from '../../utills/envHelper';
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postXML } from "../../api/requests";

import {
  GET_USER_OPTIONS_REQUEST,
  GET_USER_OPTIONS_SUCCESS,
  GET_USER_OPTIONS_FAILURE,
  SET_USER_OPTIONS_REQUEST,
  SET_USER_OPTIONS_FAILURE,
} from "./constants";

function* getUserOptionsSaga(action: Action<any>): SagaIterator {
  try {
    // TODO when backend ready get user option ala: const userOptions = yield call(postXML, getBaseUrl() + "/...");

    const payload = {
      baselineReport: {
        ProportionOfCowsBred: true,
        CalvingToFirstAIInterval: false,
        FirstToLastAIInterval: true,
        AIsPrCow: false,
        InseminationRate: true,
        AIsPrPregnancy: false,
        PregnancyRate: true,
        OpenDays: true,
      },
      dataType: 0,
      isOwnFarm: 0,
      dataBaseLineReportType: 1,
      sticksUsed: "SticksUsedPrPregnancy",
    }

    yield put({ type: GET_USER_OPTIONS_SUCCESS, payload });
  } catch (error) {
    console.log(GET_USER_OPTIONS_FAILURE, error);
    yield put({ type: GET_USER_OPTIONS_FAILURE, payload: "Unable to get data" });
  }
}

function* setUserOptionsSaga(action: any): SagaIterator {
  try {
    // TODO when backend ready post users new options

    yield put({ type: GET_USER_OPTIONS_SUCCESS, payload: action.payload });
  } catch (error) {
    console.log(SET_USER_OPTIONS_FAILURE, error);
    yield put({
      type: SET_USER_OPTIONS_FAILURE,
      payload: "Unable to get data",
    });
  }
}

export function* watchGetUserOptionsSaga(): SagaIterator {
  yield takeEvery(GET_USER_OPTIONS_REQUEST, getUserOptionsSaga);
  yield takeEvery(SET_USER_OPTIONS_REQUEST, setUserOptionsSaga);
}
