import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentReproductionPerformance } from "../Cards";
import Progressbar from "../Progressbar";
import {
  GET_HNINSIGHTS_QUESTIONS_REQUEST,
  GO_TO_PREVIOUS_QUESTION_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
  POST_HNINSIGHTS_ANSWER_REQUEST,
  REPLACE_INSIGHT_REQUEST,
  SET_HNINSIGHTS_STAGE,
  SET_SELECTED_ANSWER,
  STAGES,
} from "../redux/HNInsights/constants";
import { initialState } from "../redux/HNInsights/reducer";
import "./Questionaire.scss";
import Loader from "./QuestionaireLoader";
import { AnswerElement } from "./types";
import { DASHBOARD_TOGGLE } from "../redux/Navigation/constants";
import { iQuestionaire, } from "./interface";
import HoverGuide from "../HoverGuide";
import { IState } from "../redux/HNInsights/types";
import { Icon, Button, Grid } from "lattec-ui-library";
import { InsightText, Insight, QuestionText } from "../redux/HNInsights/types";
import parse from "html-react-parser";

import { getI18n, useTranslation } from "react-i18next";

const Questionaire: React.FC<iQuestionaire> = (props): JSX.Element => {
  const { t } = useTranslation();

  const hnInsightsState: IState = useSelector((state: IState) => state.hnInsightsState);
  const [
    activeInsight,
    handleSetActiveInsight,
  ] = useState<Insight | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_HNINSIGHTS_QUESTIONS_REQUEST });
  }, [dispatch]);

  const handleSelectAnswer = (el: any) =>
    dispatch({ type: SET_SELECTED_ANSWER, payload: el });

  const setStage = useCallback(
    (stage: string) => dispatch({ type: SET_HNINSIGHTS_STAGE, payload: stage }),
    [dispatch]
  );

  const handleGoToNextQuestion = () => {
    // post answer to server
    // if question id, next question should come from that

    // if sub question next question should be hnInsightsState.questions.open[0]
    // else hnInsightsState.questions.open[1]

    const isSubQuestion =
      hnInsightsState.questions.open.length > 0 &&
      hnInsightsState.activeQuestion.questionId !==
      hnInsightsState.questions.open[0].questionId;
    // when moving to next question - we need to also put the sub question into the answered array

    dispatch({
      type: POST_HNINSIGHTS_ANSWER_REQUEST,
      payload: {
        selectedAnswer: hnInsightsState.selectedAnswer,
        nextQuestion: isSubQuestion
          ? hnInsightsState.questions.open[0]
          : hnInsightsState.questions.open[1],
        currentStage: hnInsightsState.stage,
      },
    });
  };

  const handleGoToPreviousQuestion = () => {
    // Get the last item in the answered array
    const answeredQuestion =
      hnInsightsState.questions.answered[
      hnInsightsState.questions.answered.length - 1
      ];

    // set answered question to null
    handleSelectAnswer(initialState.selectedAnswer);

    dispatch({
      type: GO_TO_PREVIOUS_QUESTION_REQUEST,
      payload: answeredQuestion,
    });

    // handleSelectAnswer(initialSelectedAnswer);
  };

  const handleGetAnalysisQuestions = (numOfQestions: number) => {
    // fetch all questions
    dispatch({
      type: GET_HNINSIGHTS_QUESTIONS_REQUEST,
      payload: numOfQestions,
    });
  };

  const handlePinToDashboard = (id: string) => {
    let elem = hnInsightsState.insights.find((x) => x.id == id);
    dispatch({
      type: PIN_INSIGHT_TO_DASHBOARD_REQUEST,
      payload: { id, pin: elem?.dashBoard === 0 },
    });
  };

  const handleReplaceInsight = (id: string) => {
    dispatch({ type: REPLACE_INSIGHT_REQUEST, payload: { id } });
  };

  const allInsightsExpired =
    hnInsightsState.insights.findIndex((x) => x.expired === 0) === -1;

  const renderQuestionaireButtons = (
    <>
      <br />
      <div className="questionaire__actions">
        <div>
          <Button
            onClick={() => props.close()}
            label={t("NoThanks,IWillComeBackLater")}
            variant="primary"
          />
        </div>
        <div>
          <Button
            onClick={() => handleGetAnalysisQuestions(3)}
            label={t("QuickSession")}
            variant="primary"
          />
          <Button
            onClick={() => handleGetAnalysisQuestions(0)}
            label={t("FullSession")}
            variant="primary"
          />
        </div>
      </div>
    </>
  );

  const SpidersComponent = (
    <Grid item={true}>
      {activeInsight !== null ? (
        <div className="questionaire__detailed-insights-wrapper">
          <div className="questionaire__detailed-insights-headline-wrapper">
            <h3>{(t(activeInsight.key) as InsightText).header}</h3>
            <Button
              onClick={() => handleSetActiveInsight(null)}
              icon={{ fontAwesomeName: 'times', position: 'left', variant: 'fas' }}
              variant="text"
            />
          </div>
          <div className="questionaire__insight-body-wrapper">
            <p className="questionaire__insight-body">{(t(activeInsight.key) as InsightText).body}</p>
          </div>
          <div className="questionaire__detailed-action-list-wrapper">
            <h4 className="questionaire__detailed-action-list-headline">{t("Possible action points")}</h4>
            {(t(activeInsight.key) as InsightText).actionlist.map((actionListElement: string, index: number) => {
              return (
                <div className="questionaire__detailed-action-list-element" key={`action-list-element-${index}`}>
                  <p className="questionaire__insight-body">- {actionListElement}</p>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div className="spiderContainer">
          <CurrentReproductionPerformance
            embedded={true}
            toggleId={DASHBOARD_TOGGLE}
          />
        </div>
      )}
    </Grid>
  );

  const ListOfInsightsComponent = () => {
    return (
      <Grid item>
        {hnInsightsState.insights.map((el, index) => {
          return (
            <div
              key={el.key}
              className={
                "questionaire__insights" +
                (el.dashBoard ? " pinned" : "") +
                (el.expired ? " expired" : "")
              }
            >
              <div
                className={el.expired ? " expired" : ""}
                hidden={el.expired === 0}
              >
                <p>Expired</p>
              </div>
              <div className="questionaire__insight-wrapper">
                <div className="questionaire__insight-headline-wrapper" >
                  <Grid container>
                    <Grid ><HoverGuide content="Pin to dashboard" direction="right">

                      <Button className="infoButton" style={{ position: "absolute", margin: "-40px -54px" }} icon={{ fontAwesomeName: "thumbtack", style: { transform: "rotate(330deg)" }, variant: (el.dashBoard ? "fas" : "fal"), colors: { fillColor: "#0091FF", strokeColor: "#0091FF" } }} variant="text" onClick={() => handlePinToDashboard(el.id)} />

                    </HoverGuide></Grid>
                    <Grid xs={10}> <div className="insight-rank">{index + 1}.{(t(el.key) as InsightText).header}</div></Grid>
                    <Grid>  <HoverGuide content="Dismiss insight" direction="right">
                      <Button style={{ margin: 0 }} icon={{ fontAwesomeName: "trash", variant: "fas", colors: { fillColor: "#0091FF", strokeColor: "#0091FF" } }} variant="text" onClick={() => handleReplaceInsight(el.id)} />
                    </HoverGuide>
                    </Grid>
                  </Grid>
                </div>
                <div className="questionaire__insight-body-wrapper">
                  <p className="questionaire__insight-body">{(t(el.key) as InsightText).summary}</p>
                </div>
                <div className="questionaire__insight-bottom">
                  <div className="questionaire__insight-suggestive-icons">
                    {(t(el.key) as InsightText).icon.map((iconElement: string) => {
                      return (
                        <Fragment key={`key__${iconElement}`}>
                          {iconElement === "Breed" ? (
                            <HoverGuide
                              content="Breeding advisor"
                              direction="right"
                            >
                              <div
                                className="questionaire__insight-suggestive-icon-wrapper"
                                key={iconElement}
                              >
                                <Icon size={35} variant={'lattec'} lattecName="Insemination" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Management" ? (
                            <HoverGuide
                              content="Management/Housing advisor"
                              direction="right"
                            >
                              <div
                                className="questionaire__insight-suggestive-icon-wrapper"
                                key={iconElement}
                              >
                                <Icon size={35} variant={'lattec'} lattecName="Herd_operations" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Feed" ? (
                            <HoverGuide
                              content="Feed advisor"
                              direction="right"
                            >
                              <div
                                className="questionaire__insight-suggestive-icon-wrapper"
                                key={iconElement}
                              >
                                <Icon size={35} variant={'lattec'} lattecName="Feed" />
                              </div>
                            </HoverGuide>
                          ) : iconElement === "Veterinarian" ||
                            iconElement === "Vet" ? (
                            <HoverGuide
                              content="Veterinarian"
                              direction="right"
                            >
                              <div
                                className="questionaire__insight-suggestive-icon-wrapper"
                                key={iconElement}
                              >
                                <Icon size={35} variant={'lattec'} lattecName="Health_check" />
                              </div>
                            </HoverGuide>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </div>

                  <HoverGuide content="Read more" direction="bottom">
                    <Button icon={{ fontAwesomeName: "ellipsis-h", variant: "fas" }} variant="text" onClick={() => handleSetActiveInsight(el)} />
                  </HoverGuide>
                </div>
              </div>
            </div>
          );
        })}
      </Grid>
    )
  }
  return (
    <div className="questionaire">
      {hnInsightsState.error ? (
        <div>{hnInsightsState.error}</div>
      ) : (
        <Fragment>
          {hnInsightsState.stage === STAGES.CONFIG_INTRO && hnInsightsState.status === "config_questions_expired" ? (
            <div className="questionaire__container">
              <h3 className="questionaire__headline">{t("ManagementAndFarmSetupQuestionsExpired")}</h3>
              <p className="questionaire__info-text">
                {t("ItSeemsToHaveBeenAWhileSinceYouAnsweredYourFarmManagementAndSetupQuestions.")}
                <br />
                {t("ToMakeSureThatWeStillPresentTheMostAccurateInsights,Let'sStepThroughTheQuestionsAgain.")}
              </p>
              <div className="questionaire__actions__singular">
                <Button
                  style={{ float: 'right' }}
                  label={t("Ok,Let'sGo")}
                  variant="primary"
                  onClick={() => setStage(STAGES.CONFIG_QUESTIONAIRE)}
                />
              </div>
            </div>
          ) : hnInsightsState.stage === STAGES.CONFIG_INTRO ? (
            <Fragment>
              <div className="questionaire__container">
                <h3 className="questionaire__headline">Before we begin...</h3>
                <p className="questionaire__info-text">
                  {t("WeNeedToKnowSomeBasicsAboutYourFarmManagementAndSetup.")}
                  <br />
                  <br />
                  {t("ThisHelpsUsGiveYouTheMostAccurateInsights.")}
                  <br />
                  <br />
                  <i>
                    {t("YouWillOnlyHaveToAnswerTheseQuestionsOnYourFirstVisit-AndAfterThatJustACoupleOfTimesAYear,SoPleaseBeAccurateInYourAnswers.")}
                  </i>
                </p>
                <div className="questionaire__actions__singular">
                  <Button
                    style={{ float: 'right' }}
                    label={t("Ok,Let'sGo")}
                    variant="primary"
                    onClick={() => setStage(STAGES.CONFIG_QUESTIONAIRE)}
                  />
                </div>
              </div>
            </Fragment>
          ) : hnInsightsState.stage === STAGES.CONFIG_QUESTIONAIRE ? (
            <Fragment>
              <div className="questionaire__container">
                <h3 className="questionaire__headline">
                  {(t(hnInsightsState.activeQuestion.key) as QuestionText).question}
                </h3>
                <p className="questionaire__info-text">
                  {(t(hnInsightsState.activeQuestion.key) as QuestionText).text}
                </p>
                <Progressbar
                  progress={hnInsightsState.questions.answered.length}
                  steps={
                    hnInsightsState.questions.open.length +
                    hnInsightsState.questions.answered.length
                  }
                />
                <div className="questionaire__answers-list">
                  {hnInsightsState.activeQuestion.answerOptions.map(
                    (el: AnswerElement, index: number) => {
                      const isSelected = hnInsightsState.selectedAnswer.answer.key === el.key;

                      return (
                        <div
                          className="questionaire__answer"
                          key={`answer-${index}`}
                          onClick={() => handleSelectAnswer(el)}
                        >
                          <div
                            className={`questionaire__answer-box ${isSelected ? "questionaire__answer-box--selected" : ""
                              }`}
                          />
                          <span className="questionaire__answer-text">
                            {t(el.key as string)}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="questionaire__actions">
                  <Button
                    onClick={handleGoToPreviousQuestion}
                    label={t("Previous")}
                    variant="secondary"
                    disabled={hnInsightsState.questions.answered.length === 0} // disabled if answer array is empty
                  />
                  <Button
                    onClick={handleGoToNextQuestion}
                    label={t("Next")}
                    variant="primary"
                    disabled={hnInsightsState.selectedAnswer.answer.key === null || hnInsightsState.selectedAnswer.answer.key === undefined}
                  />
                </div>
              </div>
            </Fragment>
          ) : hnInsightsState.stage === STAGES.CONFIG_FINISH || (hnInsightsState.stage === STAGES.ANALYSIS_INTRO && hnInsightsState.insights.length < 1) ? (
            <Fragment>
              <div className="questionaire__container">
                <h3 className="questionaire__headline">
                  {t("ManagementAndFarmSetupCompleted")}
                </h3>
                <p className="questionaire__info-text">
                  {parse(t("Awesome,YouHaveAnsweredYourFarmManagementAndSetupQuestions.NowWeCanProceedToTheQuestionsWhichWillHelpUsTowardsIncreasingYourCows'ReproductionPerformance-BasedOnTheirCurrentPerformance."))}
                </p>
                <p className="questionaire__insight-body">
                  {parse(t("YouCanChoseBetweenA<b>QuickSession</b>(~5-10 minutes)OrA<b>FullSession</b>(~20-30 minutes).WithinEachSessionYouWillBeAskedANumberOfQuestionsAboutTheCurrentSituationOfTheCowsOnYourFarm.ForExampleRelatedToBodyCondition,DiseaseOccurrenceEtc."))}
                  <br />
                  <br />
                  {parse(t("InTheQuickSessionYouWillBeAskedASmallNumberOfQuestions,WhileInTheFullSessionYouWillBeAskedALargerNumberOfQuestions."))}
                  <br />
                  <br />
                  <i>
                    {parse(t("PleaseBeQccurateInYourReplies.TheMoreAccurateYourAnswersAre,TheBetterWeCanQuideYouTowardsWhereToFocusToImprove."))}
                  </i>
                </p>
                {renderQuestionaireButtons}
              </div>
            </Fragment>
          ) : hnInsightsState.stage === STAGES.ANALYSIS_INTRO && hnInsightsState.insights.length > 0 && allInsightsExpired ? (
            <div className="questionaire__container">
              <h3 className="questionaire__headline">{t("WelcomeBack!")}</h3>
              <p className="questionaire__info-text">{t("YourInsightsFromLastSessionAreNoLongerUp-To-Date.")}</p>
              <p className="questionaire__insight-body">
                {t("PleaseGoThroughTheAnalysisAgainToGetInsightsBasedOnYourMostRecentEeproductionPerformance.")}
              </p>

              {renderQuestionaireButtons}
            </div>
          ) : hnInsightsState.stage === STAGES.ANALYSIS_INTRO &&
            hnInsightsState.insights.length > 0 ? (
            <div className="questionaire__container">
              <h3 className="questionaire__headline">{t("WelcomeBack!")}</h3>
              <p className="questionaire__info-text">{t("These are your insights from last time")}</p>
              <div className="insight-Wrapper">
                <Grid xs={5}>
                  {ListOfInsightsComponent()}
                </Grid>
                <Grid xs={7}>
                  {SpidersComponent}
                </Grid>
              </div>
              <p className="questionaire__insight-disclaimer">
                *{t("NoteThatTheseInsightsAreBasedOnYourAnswersWithinThisSession.TheInsightsAreMeantAsAGuideTowardsAreasForYouToInvestigateOnYourOwnOrWithYourLocalAdviser.IfInDoubt,AlwaysConsultYourLocalAdviser.DeLavalCannotBeHeldLiableForAnyAdverseEffectsBasedOnThisAnalysis.")}
              </p>
              <p className="questionaire__insight-body">
                {t("DoYouWantToGoThroughTheAnalysisAgainAndSeeInsightsBasedOnYourMostRecentReproductionPerformance?")}
              </p>
              {renderQuestionaireButtons}
            </div>
          ) : hnInsightsState.stage === STAGES.ANALYSIS_QUESTIONAIRE ? (
            <Fragment>
              <div className="questionaire__container">
                <h3 className="questionaire__headline">
                  {(t(hnInsightsState.activeQuestion.key) as QuestionText).question}
                </h3>
                <p className="questionaire__info-text">
                  {(t(hnInsightsState.activeQuestion.key) as QuestionText).text}
                </p>
                <Progressbar
                  progress={hnInsightsState.questions.answered.length}
                  steps={
                    hnInsightsState.questions.open.length +
                    hnInsightsState.questions.answered.length
                  }
                />
                <div className="questionaire__answers-list">
                  {hnInsightsState.activeQuestion.answerOptions.map(
                    (el: AnswerElement, index: number) => {
                      const isSelected =
                        hnInsightsState.selectedAnswer.answer.key === el.key;

                      return (
                        <div
                          className="questionaire__answer"
                          key={`answer-${index}`}
                          onClick={() => handleSelectAnswer(el)}
                        >
                          <div
                            className={`questionaire__answer-box ${isSelected ? "questionaire__answer-box--selected" : ""
                              }`}
                          />
                          <span className="questionaire__answer-text">
                            {t(el.key as string)}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="questionaire__actions">
                  <Button
                    onClick={handleGoToPreviousQuestion}
                    label={t("Previous")}
                    variant="secondary"
                    disabled={hnInsightsState.questions.answered.length === 0}
                  />
                  <Button
                    onClick={handleGoToNextQuestion}
                    label={t("Next")}
                    variant="primary"
                    disabled={hnInsightsState.selectedAnswer.answer.key === null || hnInsightsState.selectedAnswer.answer.key === undefined}
                  />
                </div>
              </div>
            </Fragment>
          ) : hnInsightsState.stage === STAGES.INSIGHTS_LIST ? (
            <Fragment>
              <h3 className="questionaire__headline">{t("Analysis complete!")}</h3>
              <p className="questionaire__info-text">
                {t("BasedOnYourCurrentReproductionPerformanceAndYourAnswers,TheseAreTheThreeMostIimportant.")}
                <br />
                {t("YouCanPinTheInsightsToTheDashboardOrYouCanDismissAnInsight,IfYouBelieveItIsIrrelevant.")}
              </p>
              <div className="questionaire__container">
                <div className="insight-Wrapper">
                  <div className="insight">{ListOfInsightsComponent()}</div>
                  <div className="spider">{SpidersComponent}</div>
                </div>
                <Button
                  style={{ float: "right" }}
                  onClick={() => props.close()}
                  label={t("Close")}
                  variant="text"
                />
              </div>
            </Fragment>
          ) : <div />}
        </Fragment>
      )}

    </div>
  );
};

export default Questionaire;
