import React from "react";
import { LineChart as LuiLineChart } from "lattec-ui-library";
import { colours } from "lattec-ui-library/dist/styling/theme";
import {
  iAddData,
  iData,
  vector2,
  axis,
} from "lattec-ui-library/dist/components/Charts/interfaces";
import { SECONDARY_BLUE_SEMI_TRANSPARENT, LIGHT_GRAY_SEMI_TRANSPARENT, PRIMARY_BLUE_SEMI_TRANSPARENT } from "../../styles/ColorCodes"

import iLine from "./interfaces";
import { useTranslation } from "react-i18next";
import { formatDateNational } from "../../utills/dateFormatting";

const LineChart: React.FC<iLine> = (props) => {
  const { t } = useTranslation();
  const [clickedPoint, setClickedPoint] = React.useState<
    { x: number; lines: axis } | undefined
  >(undefined);

  let trace: iData[] = [];
  let bgTrace: undefined | iData = undefined;
  if (props.data.length > 0) {
    const dates = props.data[0].data.map(
      (d) => formatDateNational(new Date(d.date)) ?? "Date string error"
    );
    const x = props.data[0].data.map((d) => Number(d.x));
    const y = props.data[0].data.map((d) => Number(d.y));
    const additionalData: iAddData[] = [
      {
        data: dates,
        label: "Date",
        tooltip: "body",
      },
    ];
    if (props.tooltip) additionalData.push(props.tooltip);

    trace = [
      {
        color: PRIMARY_BLUE_SEMI_TRANSPARENT,
        x,
        y,
        traceName: props.data[0].kpi,
        additionalData,
      },


    
    ];
  }
  if (props.data.length > 1) {
    bgTrace = {
      x: props.data[1].data.map((d) => Number(d.x)),
      y: props.data[1].data.map((d) => Number(d.y)),
      traceName: props.data[1].kpi,
      color:
        props.data[1].id === "Benchmark farms"
          ? LIGHT_GRAY_SEMI_TRANSPARENT //colours.dataViz.Lattec.backgroundGrey
          : SECONDARY_BLUE_SEMI_TRANSPARENT // colours.dataViz.Lattec.lightBlue,
    };
  }

  const handleClick = (val: {
    event: any;
    points: {
      calulatedValue: number;
      closestIndex: number;
      point: vector2;
      rect: DOMRect;
      closestDataPoint: { x: number; y: number };
    }[];
  }) => {
    const index = val.points?.[0]?.closestIndex;
    if (index && props.onClick) {
      setClickedPoint({
        x: val.points[0].closestDataPoint.x,
        lines: "neither",
      });
      props.onClick(new Date(props.data[0].data[index].date));
    }
  };

  return (
    <LuiLineChart
      data={trace}
      bgTrace={bgTrace}
      display="neither"
      tooltip={{
        display: "neither",
      }}

      xLegend=""
      yLegend=""
      containerHeight={props.containerHeight}
      containerWidth={props.containerWidth}
      chartMargins={{
        t: 0,
        l: 0,
        b: 0,
        pad: 0,
        r: 0,
      }}
      dots={clickedPoint ? [clickedPoint] : undefined}
      // @ts-ignore
      onClick={props.onClick != null ? handleClick : undefined}
    />
  );
};

export default LineChart;
