import React, { useEffect } from "react";
import { COLOR_SCREME_1, COLOR_SCREME_1_SEMI_FILL, BARS_COLOR_SCHEME_1 } from "../../styles/ColorCodes";
import {
  Breadcrumbs,
  Grid,
  Icon,
  Typography,
  Widget,
  WidgetContent,
  WidgetHeader,
  muiCore,
  Tooltips,
  Tabs,
  CheckboxGroup,
  Checkbox,
  Button,
} from "lattec-ui-library";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import iProps from "./layoutProps";
import SplitPane from "react-split-pane";
//@ts-ignore
import Pane from "react-split-pane/lib/Pane";
import { GET_KPI_CHART_DATA_REQUEST } from "../../redux/CurrentReproductionPerformance/constants";
import { GET_PERFORMANCE_SCORE_HISTORY_REQUEST } from "../../redux/PerformanceScoreHistory/constants";
import ThumbNail from "../../Charts/PolarChart/thumbnail";
import Cowslist from "../../Layout/CowsList";
import { IFilterState, timePeriods } from "../../redux/Filters/reducer";
import StatusNumber from "../../StatusNumber";
import { DataTypeDisplay } from "../../DataType";
import "./style.scss";
import PlaceholderLoader from "../../PlaceholderLoader";
import { getTrendColorId } from "../../utills/dataFormatting";
import { SELECT_DATA_TME_PERIOD } from "../../redux/Filters/constants";
import { getIndexFromKey } from "../../redux/Filters/reducer";
import Overview12MonthWidget from "../../Cards/OverView12Month/Overview12MonthWidget";
import { SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";

const KpiLayout: React.FC<iProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedFirstChart, setSelectedFirstChart] = React.useState(0);
  const [selectedSecondChart, setSelectedSecondChart] = React.useState(0);

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );

  const navigationState = useSelector((state: any) => state.navigationState);
  const userOptionsState = useSelector((state: any) => state.userOptionsState);

  const currentReproductionPerformanceState = useSelector(
    (state: any) => state.currentReproductionPerformanceState
  );

  useEffect(
    () => {
      navigationState.cowListOpen = false;

      const startDate = new Date(filtersState.today);
      startDate.setDate(
        startDate.getDate() -
        filtersState.anoestrusLengthSelectedTimePeriod.days
      );

      filtersState.lactationFilters.culledChecked =
        filtersState.lactationFilters.l1Checked =
        filtersState.lactationFilters.l2Checked =
        filtersState.lactationFilters.l3Checked =
        true;
      filtersState.lactationFilters.delayedChecked = false;
      if (!currentReproductionPerformanceState.gotData) {
        dispatch({
          type: GET_KPI_CHART_DATA_REQUEST,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      userOptionsState.dataType,
      userOptionsState.isOwnFarm,
      userOptionsState.dataBaseLineReportType,
    ]
  );

  useEffect(() => {
    dispatch({
      type: GET_PERFORMANCE_SCORE_HISTORY_REQUEST,
      payload: { kpi: props.kpi },
    });
  }, [dispatch]);

  const handleSelectTimePeriod = (key: string) => {
    dispatch({
      type: SELECT_DATA_TME_PERIOD,
      payload: { index: getIndexFromKey(key), key: "anoestrusLength" },
    });
  };

  const handleSetUrl = (url: string) => {
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });
  };

  const performanceScoreHistoryState = useSelector(
    (state: any) => state.performanceScoreHistoryState
  );

  const thisKpi = currentReproductionPerformanceState.kpiChartData.find(
    (kpi: any) => kpi.key === props.kpi.toLocaleLowerCase()
  );

  return (
    <Grid direction="row" container>
      <Grid
        item
        xs={12}
        style={{ paddingRight: navigationState.cowListOpen ? "360px" : "0px" }}
      >
        <Grid direction="column" container>
          {/* Bread crumbs */}
          <Breadcrumbs
            breadcrumbs={[
              { label: t("Dashboard"), link: "/dashboard" },
              {
                label: t("HNADashboard"),
                link: "/reproductionperformancerepro",
              },
              { label: props.title },
            ]}
            backToVariant={false}
            onClick={(link) => window.history.pushState(null, "", link)}
          />
          {/* Icon + title + score */}
          <Grid
            direction="row"
            container
            item
            alignItems="center"
            justify="flex-start"
          >
            <Grid item>
              <div style={{ marginBottom: "16px", marginRight: "20px" }}>
                <Icon
                  variant="lattec"
                  lattecName={props.kpi}
                  colors={{ fillColor: "rgb(113, 113, 113)" }}
                  size={32}
                />
              </div>
            </Grid>
            <Grid item>
              <Typography
                variant="Heading 2"
                text={props.title}
                style={{ paddingBottom: "16px", color: "rgb(113, 113, 113)" }}
              />
            </Grid>
            <Grid item style={{ marginLeft: "auto", whiteSpace: "nowrap", }}>
              <Grid container direction="row" justifyContent="center" alignContent="center">
                <Tooltips content={t("GoToLibrary").toString()} position="bottom" style={{ marginTop: "auto" }}>
                  <Button
                    onClick={() => handleSetUrl(props.libraryUrl)}
                    variant="text"
                    size="small"
                    className="infoButton"
                    icon={{
                      variant: "fas",
                      fontAwesomeName: "book",
                      position: "left",
                      size: 24,
                      colors: { fillColor: 'rgb(200, 200, 200)' }
                    }}
                  />
                </Tooltips>
                <Widget
                  style={{
                    height: "100%",
                    paddingBottom: "0px",
                    paddingRight: "10px",
                    display: "inline-block",
                  }}
                >
                  {currentReproductionPerformanceState.gotData ? (
                    <StatusNumber
                      number={thisKpi?.value ?? t("N/A")}
                      unit={""}
                      text={""}
                      colorId={getTrendColorId(
                        thisKpi?.value,
                        thisKpi?.reference,
                      )}
                    >
                      <DataTypeDisplay
                        referenceValudId="current-reproduction-performance-reference-value-bar"
                        colors={BARS_COLOR_SCHEME_1}
                        isOwnFarm={0}
                        trend={thisKpi?.trend}
                        benchmarkComparison={thisKpi?.reference}
                        current={thisKpi?.value}
                        ownComparison={0}
                        containerWidth="15px"
                        containerHeight="40px"
                      />
                    </StatusNumber>
                  ) : (
                    <></>
                  )}
                </Widget>
              </Grid>
            </Grid>
          </Grid>
          <muiCore.Divider
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          {/* Description */}
          <Typography
            variant="Body Default"
            text={props.subHeader}
            style={{ color: "rgb(113, 113, 113)", fontStyle: "italic" }}
          />

          <Grid
            direction="column"
            item
            container
            style={{ paddingTop: "15px" }}
          >
            <Grid
              direction="row"
              item
              container
              justify="space-between"
              alignContent="stretch"
              style={{ paddingBottom: "20px" }}
            >
              <Grid item xs={2} style={{ paddingRight: "10px" }}>
                <Widget style={{ height: "100%", paddingBottom: "10px" }}>
                  <WidgetHeader
                    className="cardHeader"
                    headerlineseprator={true}
                    title={t("ReProKPInavigation")}
                    key={t("ReProKPInavigation").toString()}
                    titleTypographyProps={{ variant: "h6" }}
                  />
                  <WidgetContent className="upperKPIContent">
                    <ThumbNail
                      data={currentReproductionPerformanceState.kpiChartData}
                      colors={COLOR_SCREME_1}
                      fillColors={COLOR_SCREME_1_SEMI_FILL}
                      dataType={0}
                    />
                  </WidgetContent>
                </Widget>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                <Widget style={{ height: "100%" }}>
                  <WidgetHeader
                    className="cardHeader"
                    headerlineseprator
                    title={t("ReproductionFocus")}
                    key={t("ReproductionFocus").toString()}
                    titleTypographyProps={{ variant: "h6" }}
                  />
                  <WidgetContent className="upperKPIContent chartImage">
                    <props.chartImage />
                  </WidgetContent>
                </Widget>
              </Grid>
              <Grid item xs={7} style={{ paddingLeft: "10px" }}>
                <Overview12MonthWidget
                  onClick={handleSelectTimePeriod}
                  filtersState={filtersState}
                  kpi={props.kpi}
                  performanceScoreHistoryState={performanceScoreHistoryState}
                  stepGuide={178781}
                  timePeriods={timePeriods}
                  stripped={true}
                />
              </Grid>
            </Grid>
            <Grid item>
              <muiCore.Paper>
                <SplitPane split="vertical" resizerClassName="Resizer">
                  <Pane minSize="200px" initialSize="75%">
                    <Widget style={{ height: "100%" }} id="resizePaneOne">
                      <WidgetHeader
                        outerId="Chart1"
                        className="cardHeader"
                        headerlineseprator
                        title={props.chartSlot1[selectedFirstChart].title}
                        key="Chart1"
                        actions={[
                          props.chartSlot1.length > 1 ? (
                            <Tabs
                              variant="transparent"
                              items={props.chartSlot1.map((x) => x.title)}
                              value={selectedFirstChart}
                              onChange={(val) => {
                                setSelectedFirstChart(val);
                              }}
                            />
                          ) : undefined,
                        ]}
                      />
                      <WidgetContent>
                        {props.chartSlot1[selectedFirstChart].noData ?
                          <div className="svg-container" style={{ height: "340px" }}>
                            <Typography
                              variant="Heading 2"
                              text={t("NotAvailable")}
                              style={{ paddingBottom: "16px", color: "rgb(113, 113, 113)" }}
                            />
                          </div>
                          : (props.chartSlot1[selectedFirstChart].chart ? (
                            props.chartSlot1[selectedFirstChart].chart["props"]
                              .data.length > 0 ? (
                              props.chartSlot1[selectedFirstChart].chart
                            ) : (
                              <PlaceholderLoader
                                type="KpiOverview"
                                height="340px"
                              />
                            )
                          ) : null)}
                      </WidgetContent>
                    </Widget>
                  </Pane>
                  <Pane minSize="200px">
                    <Widget style={{ height: "100%" }} id="resizePaneTwo">
                      <WidgetHeader
                        outerId="Chart2"
                        className="cardHeader"
                        headerlineseprator
                        title={props.chartSlot2[selectedSecondChart].title}
                        key="Chart2"
                        actions={[
                          props.chartSlot2.length > 1 ? (
                            <Tabs
                              variant="transparent"
                              items={props.chartSlot2.map((x) => x.title)}
                              value={selectedSecondChart}
                              onChange={(val) => {
                                setSelectedSecondChart(val);
                              }}
                            />
                          ) : undefined,
                        ]}
                      />
                      <WidgetContent>
                        {props.chartSlot2[selectedSecondChart].noData ?
                          <div className="svg-container" style={{ height: "340px" }}>
                            <Typography
                              variant="Heading 2"
                              text={t("NotAvailable")}
                              style={{ paddingBottom: "16px", color: "rgb(113, 113, 113)" }}
                            />
                          </div>
                          : (props.chartSlot2[selectedSecondChart].chart ? (
                            props.chartSlot2[selectedSecondChart].chart["props"]
                              .data.length > 0 ? (
                              props.chartSlot2[selectedSecondChart].chart
                            ) : (
                              <PlaceholderLoader
                                type="KpiOverview"
                                height="340px"
                              />
                            )
                          ) : null)}
                      </WidgetContent>
                    </Widget>
                  </Pane>
                </SplitPane>
                <muiCore.Divider style={{ marginBottom: "10px" }} />
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className="kpiFilters"
                >
                  <Grid item>
                    <CheckboxGroup row style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        label={t("LactationNo") + " 1"}
                        checked={filtersState.lactationFilters.l1Checked}
                        onChange={() => props.handleToggleLactationFilter("l1")}
                      />
                      <Checkbox
                        label={t("LactationNo") + " 2"}
                        checked={filtersState.lactationFilters.l2Checked}
                        onChange={() => props.handleToggleLactationFilter("l2")}
                      />
                      <Checkbox
                        label={t("LactationNo") + " 3"}
                        checked={filtersState.lactationFilters.l3Checked}
                        onChange={() => props.handleToggleLactationFilter("l3")}
                      />
                    </CheckboxGroup>
                  </Grid>
                  <Grid item>
                    <CheckboxGroup row>
                      <Checkbox
                        label={t("DisplayCulledCows")}
                        checked={filtersState.lactationFilters.culledChecked}
                        onChange={() =>
                          props.handleToggleLactationFilter("culled")
                        }
                      />
                      <Checkbox
                        label={t("DisplayDelayedSamples")}
                        checked={filtersState.lactationFilters.delayedChecked}
                        onChange={() =>
                          props.handleToggleLactationFilter("delayed")
                        }
                      />
                    </CheckboxGroup>
                  </Grid>
                </Grid>
                {props.handleToggleInseminationFilter !== undefined && (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    className="kpiFilters"
                  >
                    <Grid item>
                      <CheckboxGroup row style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          label={t("InseminationNumber") + " 1"}
                          checked={filtersState.lactationFilters.i1Checked}
                          onChange={() =>
                            props.handleToggleInseminationFilter?.("i1")
                          }
                        />
                        <Checkbox
                          label={t("InseminationNumber") + " 2"}
                          checked={filtersState.lactationFilters.i2Checked}
                          onChange={() =>
                            props.handleToggleInseminationFilter?.("i2")
                          }
                        />
                        <Checkbox
                          label={t("InseminationNumber") + " 3"}
                          checked={filtersState.lactationFilters.i3Checked}
                          onChange={() =>
                            props.handleToggleInseminationFilter?.("i3")
                          }
                        />
                        <Checkbox
                          label={t("InseminationNumber") + " 4+"}
                          checked={filtersState.lactationFilters.i4Checked}
                          onChange={() =>
                            props.handleToggleInseminationFilter?.("i4")
                          }
                        />
                      </CheckboxGroup>
                    </Grid>
                  </Grid>
                )}
              </muiCore.Paper>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
      {navigationState.cowListOpen && (
        <Grid item xs={2}>
          {" "}
          <Cowslist />{" "}
        </Grid>
      )}
    </Grid>
  );
};

export default KpiLayout;
