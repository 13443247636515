import React, { useState } from "react";
import Plotly, { Data } from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import iPolarChart from "./interface";
import { useDispatch, useSelector } from "react-redux";
import { SET_CRP_DB_HOVERED } from "../../redux/Navigation/constants";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getKPILongTitle } from "../../utills/dataFormatting";
import './style.scss'
import { formatDateNational, } from "../../utills/dateFormatting";
import moment from "moment";

const Plot = createPlotlyComponent(Plotly);

const PolarChart: React.FunctionComponent<iPolarChart> = (props) => {
  const navigationState = useSelector((state: any) => state.navigationState);

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const [hoveredPoint, setHoveredPoint] = useState<
    undefined | Readonly<Plotly.PlotMouseEvent>
  >(undefined);
  const [revision, setRevision] = useState(0);
  const handleMouseOver = (hovered: string[]) =>
    dispatch({ type: SET_CRP_DB_HOVERED, payload: hovered });

  const values = props.data.map((point) => point.value);
  values.push(values[0]);
  const theta = props.data.map((point) => point.key);
  theta.push(theta[0]);
  const refValues = props.data.map((point) => (props.dataType === 0 ? point.reference : point.ownFarm));
  refValues.push(refValues[0]);
  const names = ["Farm values", "Reference values"];

  const sizeValues = values.map((val, index) => navigationState.currentReproductionPerformanceDbHovered.includes(theta[index]) ? 11 : 9)
  // const sizeRefValues = values.map((val, index) => 9)

  const fillOpacityValues = navigationState.currentReproductionPerformanceDbHovered?.[0] === '' ? "26" : "99";
  const lineWidthValues = values.map((val, index) => navigationState.currentReproductionPerformanceDbHovered.includes(theta[index]) ? 3 : 0)

  const data: Data[] = [values, refValues].map((val, index) => {
    return {
      type: "scatterpolar",
      mode: "gauge+delta",
      r: index === 1 ? val : val.map((el) => (el == null ? 10 : el)),
      theta,
      fill: "toself",
      // fillcolor: props.fillColors[index],
      fillcolor: props.colors[index] + fillOpacityValues,
      name: names[index],
      marker: {
        symbol: 'circle', //val.map((el) => (el == null ? "circle-open" : "circle")),
        line: { width: lineWidthValues, color: 'white' },
        color: props.colors[index],
        opacity: 1,
        size: sizeValues,
      },
      line: { color: props.colors[index], width: 2 },
      hoverinfo: "none",
    };
  });

  data.reverse();

  function getToolTip() {
    let point = props.data.find(
      //@ts-ignore
      (x) => x.key === hoveredPoint?.points?.[0]?.theta
    );
    if (!point) return;
    const comparePoint = props.dataType === 0 ? Number(point.reference) : Number(point.ownFarm)
    const diff = point.value == null ? null : Number(point.value) - comparePoint;
    const diffCol = diff == null ? "grey" : diff > 0 ? "green" : diff < 0 ? "red" : "grey";

    const today = new Date();
    // at midnight
    today.setHours(0, 0, 0, 0)

    let myFarmHistoricDate = moment(point.scoreDate);
    myFarmHistoricDate.subtract(1, 'year');




    return (
      <div
        id="tooltip-container"
        style={{
          left: `${hoveredPoint?.event.clientX}px`,
          top: `${hoveredPoint?.event.clientY}px`,
          zIndex: 99999,
        }}
      >
        <h4>{t(getKPILongTitle(point.key))}</h4>
        <p>{t("MyFarm") + ": " + (point.value || t("NotAvailable"))}</p>
        {(point.scoreDate && (today.getTime() > new Date(point.scoreDate).getTime())) && <p> {t("LastScoreDate") + ": " + formatDateNational(point.scoreDate)} </p>}

        <br />
        <p>{(props.dataType === 0 ? t("Benchmark farms") + ": " + (point.reference || t("NotAvailable")) : t("MyFarm") + " [" + formatDateNational(myFarmHistoricDate.toDate()) + "]: " + (point.ownFarm || t("NotAvailable")))}</p>
        <p>
          {t("Difference") + ": "}
          <span style={{ color: diffCol }}>{diff || (diff === 0 ? 0 : t("NotAvailable"))}</span>
        </p>

      </div>
    );
  }

  function pointClicked(e: Plotly.PlotMouseEvent) {
    if (!e.points?.[0]?.pointIndex) return;
    // @ts-ignore
    history.push(e.points[0].theta);
  }

  return (
    <div style={{ height: "100%" }}>
      <Plot
        style={{ height: "100%", width: "100%" }}
        data={data}
        className={hoveredPoint ? "polarPlot hovered" : "polarPlot"}
        revision={revision}
        layout={{
          showlegend: false,
          hovermode: "x",
          polar: {
            radialaxis: {
              fixedrange: true,
              range: [0, 100],
              showline: false,
              showticklabels: false,
              ticks: "",
              color: "#dddddd",
              gridwidth: 2,
            },
            angularaxis: {
              fixedrange: true,
              visible: true,
              tickmode: "array",
              color: "#dddddd",
              tickvals: [0, 1, 2, 3, 4, 5, 6, 7],
              ticktext: ["", "", "", "", "", "", ""],
              tickcolor: "#FFFFFFFF",
              rotation: 90,
              direction: "clockwise",
            },
          },
          scene: { dragmode: false, hovermode: "closest" },
          autosize: true,
          margin: { b: 0, l: 0, r: 0, t: 0, pad: 0 },
          dragmode: false
        }}
        config={{
          displayModeBar: false,
          autosizable: true,
          scrollZoom: false,
          responsive: true,
        }}
        onInitialized={(e) => {
          // @ts-ignore
          e.event.target.style.cursor = "default";
        }}
        onHover={(e) => {
          if (!e.points) return;
          if (!e.points.length) return;
          if (e.points[0].pointIndex === undefined) return;
          // @ts-ignore
          e.event.target.style.cursor = "pointer";
          setHoveredPoint(e);
          // @ts-ignore
          handleMouseOver([e.points[0].theta]);
        }}
        onUnhover={(e) => {
          // @ts-ignore
          e.event.target.style.cursor = "default";
          setHoveredPoint(undefined);
          handleMouseOver([""]);
        }}
        onClick={(e) => pointClicked(e)}
        useResizeHandler={true}
      // responsive: true
      />
      {hoveredPoint && getToolTip()}
    </div>
  );
};

export default PolarChart;
