import React from "react";
import { useTranslation } from "react-i18next";
import './style.scss'
import { SetIdLineWitdh, SetIdPolygonScale, HoverKPIChart } from '../helper';

function CrbwpChart() {
  const { t } = useTranslation();

  const ProgesteroneProfile: string[] = ["ProgesteroneProfile", "ProgesteroneProfile1"];

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox="770 450 385 185"
      id="crbwpChart"
    >
      <g id="HNIR_-_HN_Insemination_Rate" className="st0">

        <text transform="matrix(1 0 0 1 -1704.9856 7970.8965)" style={{ display: 'inline', fill: '#C8C8C8', fontFamily: 'Roboto-Bold', fontSize: '16px' }}>Place in reproduction cycle</text>
        <g id="Group_3507" transform="translate(-386 12227)" className="st1">
          <rect id="Rectangle_1507_1_" x="3141" y="-16785.1" className="st2" width="24" height="24" />
          <rect id="Rectangle_1511_1_" x="3141" y="-16549.1" className="st2" width="24" height="24" />
          <rect id="Rectangle_1510_1_" x="3141" y="-16726.1" className="st2" width="24" height="16" />
          <rect id="Rectangle_1509_1_" x="3120" y="-16761.1" className="st2" width="24" height="19" />
        </g>

        <text transform="matrix(1 0 0 1 -1493.8713 8064.6895)" style={{ display: 'inline', fill: '#C8C8C8', fontFamily: 'Roboto-Bold', fontSize: '16px' }}>Place in reproduction cycle</text>
        <g id="Group_3507_1_" transform="translate(-386 12227)" className="st1">
          <rect id="Rectangle_1507_2_" x="3352.1" y="-16691.3" className="st2" width="24" height="24" />
          <rect id="Rectangle_1511_2_" x="3352.1" y="-16455.3" className="st2" width="24" height="24" />
          <rect id="Rectangle_1510_2_" x="3352.1" y="-16632.3" className="st2" width="24" height="16" />
          <rect id="Rectangle_1509_2_" x="3331.1" y="-16667.3" className="st2" width="24" height="19" />
        </g>
        <g className="st1">
          <g>
            <g>
              <g>
                <rect id="Rectangle_1527_18_" x="833.2" y="459.5" className="st3" width="47" height="142" />
                <path id="Path_1631_5_" className="st4" d="M774.2,459.5h59v142h-59V459.5z" />
              </g>
              <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
              <path className="st6" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
        c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
        c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
        c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
        c4-26.2,9.9-26.4,13.9-27.8c6.7-2.3,44.6-3.8,60.4-3.8" />

              <rect x="771.7" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2785 -411.7955)" className="st7" width="5" height="5" />

              <rect x="1143.3" y="599" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8838 -634.0266)" className="st7" width="5" height="5" />
              <g>
                <defs>
                  <rect id="Rectangle_1527_4_" x="774.2" y="459.6" width="106" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_3_">
                  <use xlinkHref="#Rectangle_1527_4_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className="st8" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
          c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
          c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
          c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
          c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
          c4-26.2,9.9-26.4,13.9-27.8c6.9-2.4,46-3.8,61.2-3.8" />
              </g>
              <polyline className="st9" points="1146.6,601.5 774.1,601.5 774.1,463.2" />

              
            </g>
          </g>
        </g>
      </g>
      <g id="CR_-_Cycle_regularity">
        <g>
          <g>
            <g>
              <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
              <path id="ProgesteroneProfile" className="st6" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
        c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
        c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
        c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
        c4-26.2,9.9-26.4,13.9-27.8c6.7-2.3,44.5-3.8,60.3-3.8"
                onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
                onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }} >
                <title>{t("ProgesteroneProfile")}</title>
              </path>
              <g>
                <rect id="Rectangle_1527_15_" x="879.6" y="459.5" className="st4" width="86" height="142"
                  onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart1", true);
                    event.currentTarget.style.opacity = "0.8";
                  }}
                  onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart1");
                    event.currentTarget.style.opacity = "1";
                  }} >
                  <title>{t("CycleLength")}</title>
                </rect>
              </g>
              <g>
                <rect id="Rectangle_1527_9_" x="879.6" y="500.9" className="st3" width="40" height="100.5"
                  onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart2", true);
                    event.currentTarget.style.opacity = "0.8";
                  }}
                  onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart2");
                    event.currentTarget.style.opacity = "1";
                  }} >
                  <title>{t("FollicularPhaseLength")}</title>
                </rect>
              </g>
              <g>
                <g>
                  <path className="st10" d="M895.7,580.6c0.7,0,1.3,0,2,0" />
                  <path
                    id="ProlongedFollicularPhase"
                    className="st11" d="M900.8,580.6c9.2-0.1,19.6-1.6,28.5-16"
                    onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                      SetIdLineWitdh(["ProlongedFollicularPhase"], true);
                      SetIdPolygonScale(["ProlongedFollicularPhaseArrow"], 2);
                    }}
                    onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                      SetIdLineWitdh(["ProlongedFollicularPhase"]);
                      SetIdPolygonScale(["ProlongedFollicularPhaseArrow"]);
                    }} >
                    <title>{t("ProlongedFollicularPhase")}</title>
                  </path>
                  <path className="st10" d="M930,563.3c0.3-0.6,0.7-1.1,1-1.7" />
                  <g>
                    <polygon
                      id="ProlongedFollicularPhaseArrow"
                      className="st12"
                      points="934.8,565 934.1,555.1 925.8,560.6"
                      onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                        SetIdLineWitdh(["ProlongedFollicularPhase"], true);
                        SetIdPolygonScale(["ProlongedFollicularPhaseArrow"], 2);
                      }}
                      onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                        SetIdLineWitdh(["ProlongedFollicularPhase"]);
                        SetIdPolygonScale(["ProlongedFollicularPhaseArrow"]);
                      }} >
                      <title>{t("ProlongedFollicularPhase")}</title>
                    </polygon>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    id="ProlongedLutealPhase"
                    className="st13" d="M941.8,501.2c15.9,0,27.1,0.7,37.7,18.7"
                    onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                      SetIdLineWitdh(["ProlongedLutealPhase"], true);
                      SetIdPolygonScale(["ProlongedLutealPhaseArrow"], 2);
                    }}
                    onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                      SetIdLineWitdh(["ProlongedLutealPhase"]);
                      SetIdPolygonScale(["ProlongedLutealPhaseArrow"]);
                    }} >
                    <title>{t("ProlongedLutealPhase")}</title>
                  </path>
                  <g>
                    <polygon
                      id="ProlongedLutealPhaseArrow"
                      className="st12"
                      points="974.4,521 982.9,526.2 983.1,516.2"
                      onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                        SetIdLineWitdh(["ProlongedLutealPhase"], true);
                        SetIdPolygonScale(["ProlongedLutealPhaseArrow"], 2);
                      }}
                      onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                        SetIdLineWitdh(["ProlongedLutealPhase"]);
                        SetIdPolygonScale(["ProlongedLutealPhaseArrow"]);
                      }} >
                      <title>{t("ProlongedLutealPhase")}</title>
                    </polygon>
                  </g>
                </g>
              </g>
              <g>
                <defs>
                  <rect id="Rectangle_1527_8_" x="879.6" y="459.6" width="86" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_5_">
                  <use xlinkHref="#Rectangle_1527_8_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path
                  id="ProgesteroneProfile1"
                  className="st14" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
          c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
          c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
          c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
          c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
          c4-26.2,9.9-26.4,13.9-27.8c6.9-2.4,46-3.8,61.2-3.8"
                  onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
                  onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }} >
                  <title>{t("ProgesteroneProfile")}</title>
                </path>
              </g>
              <g>
                <g>

                  <rect x="771.7" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2837 -411.808)" className="st7" width="5" height="5" />

                  <rect x="1143.3" y="599" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8786 -634.014)" className="st7" width="5" height="5" />
                  <polyline className="st9" points="1146.6,601.5 774.2,601.5 774.2,463.2 						"
                    onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "6" }}
                    onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "3" }} >
                    <title>{t("ProgesteroneLevel")} / {t("Daysinmilk")}</title>
                  </polyline>

                  <g>
                  <path
        id='Heat1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 600.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 680.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat3'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 760.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='AI1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 690.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

        <path
        id='AI2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 770.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>
        </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="HNIR_-_HN_Insemination_Rate_1_" className="st0">
        <g className="st1">
          <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
          <path className="st6" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
    c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
    h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
    c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
    c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
    c6.7-2.3,44.4-3.8,60.3-3.8" />

          <rect x="771.8" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.3259 -411.8151)" className="st7" width="5" height="5" />
          <g>
            <rect id="Rectangle_1527_10_" x="958" y="459.5" className="st4" width="8" height="142" />
          </g>
          <g>
            <rect id="Rectangle_1527_12_" x="879.5" y="459.5" className="st4" width="8" height="142" />
          </g>
          <g>
            <rect id="Rectangle_1527_2_" x="1036.8" y="459.5" className="st4" width="8" height="142" />
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_11_" x="879.5" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_6_">
              <use xlinkHref="#Rectangle_1527_11_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path className="st15" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
      c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
      h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
      c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
      c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
      c6.9-2.4,46-3.8,61.2-3.8" />
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_13_" x="958" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_17_">
              <use xlinkHref="#Rectangle_1527_13_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path className="st16" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
      c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
      h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
      c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
      c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
      c6.9-2.4,46-3.8,61.2-3.8" />
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_14_" x="1036.8" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_20_">
              <use xlinkHref="#Rectangle_1527_14_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path className="st17" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
      c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
      h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
      c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
      c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
      c6.9-2.4,46-3.8,61.2-3.8" />
          </g>
          <g>
            <g>

              <rect x="1143.2" y="599" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8699 -633.993)" className="st7" width="5" height="5" />
              <polyline className="st9" points="1146.6,601.5 774.1,601.5 774.1,463.2" />
            </g>
          </g>
        </g>
      </g>
      <g id="AIS_-_Insemination_success" className="st0">
        <g className="st1">
          <g>
            <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
            <path className="st6" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
      c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
      c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
      c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
      c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
      c4-26.2,9.9-26.4,13.9-27.8c6.7-2.3,44.5-3.8,60.3-3.8" />
            <g>
              <rect id="Rectangle_1527_7_" x="965.6" y="459.6" className="st4" width="156" height="142" />
            </g>
            <g>
              <defs>
                <rect id="Rectangle_1527_1_" x="965.6" y="459.6" width="156" height="137" />
              </defs>
              <clipPath id="Rectangle_1527_22_">
                <use xlinkHref="#Rectangle_1527_1_" style={{ overflow: 'visible' }} />
              </clipPath>
              <path className="st18" d="M1123.7,476.3c10.3-0.4,20.4-0.7,26.6-0.7" />
              <path className="st18" d="M1034,532.2c1.5,12.3,2.6,24.5,3.7,33.2c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6
        c3.8-6,12-52.4,14.4-67.9c3.5-23,8.5-25.9,12.4-27.3" />
              <g className="st19">
                <g>
                  <path className="st20" d="M1019.4,491.3c3,0,7.8,4.6,9.8,12.6c1.4,5.7,2.6,12.3,3.6,19.3" />
                  <g>
                    <polygon className="st21" points="1027.6,522.4 1033.8,530.2 1037.5,521" />
                  </g>
                </g>
              </g>
              <g className="st19">
                <g>
                  <path className="st20" d="M1087.7,479.8c3,0,7.8,4.6,9.8,12.6c1.4,5.7,2.6,12.3,3.6,19.3" />
                  <g>
                    <polygon className="st21" points="1095.9,510.9 1102.1,518.8 1105.8,509.5" />
                  </g>
                </g>
              </g>
              <path className="st18" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
        c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
        c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7" />
              <g className="st19">
                <g>
                  <line className="st13" x1="1019.2" y1="491.3" x2="1046" y2="491.3" />
                  <g>
                    <polygon className="st12" points="1044.6,496.3 1053.2,491.3 1044.6,486.3" />
                  </g>
                </g>
              </g>
              <g className="st19">
                <g>
                  <path className="st13" d="M1087.4,480c0.6-0.2,1.1-0.3,1.6-0.5c3.3-1.1,13.7-2,25.5-2.7" />
                  <g>
                    <polygon className="st12" points="1113.3,481.9 1121.7,476.4 1112.7,472" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>

              <rect x="771.7" y="460.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2517 -411.8213)" className="st7" width="5" height="5" />

              <rect x="1143.3" y="598.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8466 -634.0273)" className="st7" width="5" height="5" />
              <polyline className="st9" points="1146.6,601.4 774.2,601.4 774.2,463.1" />
            </g>
          </g>
        </g>
      </g>
      <g id="SEA_-_Embryo_Attachment" className="st0">
        <g className="st1">
          <g>
            <g>
              <rect x="773.3" y="459.6" className="st5" width="376" height="161" />
              <path className="st6" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
        h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
        c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
        c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
        c6.7-2.3,44.4-3.8,60.3-3.8" />

              <rect x="771.8" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2756 -411.8359)" className="st7" width="5" height="5" />
              <g>
                <rect id="Rectangle_1527_26_" x="1091.2" y="459.4" className="st4" width="44" height="142" />
              </g>
              <g>
                <defs>
                  <rect id="Rectangle_1527_21_" x="1091.2" y="459.6" width="44" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_23_">
                  <use xlinkHref="#Rectangle_1527_21_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className="st22" d="M1135.2,475.8c5.9-0.2,11.2-0.3,15-0.3" />
                <path className="st23" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
          c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
          s9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
          c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
          c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
          c1.9-0.7,6.4-1.3,12.1-1.8" />
                <g className="st24">
                  <g>
                    <path className="st20" d="M1101.3,477.7c3,0,7.8,4.6,9.8,12.6c1.4,5.7,2.6,12.3,3.6,19.3" />
                    <g>
                      <polygon className="st21" points="1109.4,508.8 1115.6,516.6 1119.3,507.3" />
                    </g>
                  </g>
                </g>
                <g className="st24">
                  <g>
                    <path className="st13" d="M1101.1,477.7c7.7-0.7,17.6-1.2,27-1.6" />
                    <g>
                      <polygon className="st12" points="1126.8,481.2 1135.2,475.8 1126.4,471.2" />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>

                  <rect x="1143.2" y="598.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8196 -634.0139)" className="st7" width="5" height="5" />
                  <polyline className="st9" points="1146.6,601.4 774.1,601.4 774.1,463.1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="PBSS_-_Final_Establishment_of_Pregnancy" className="st0">
        <g className="st1">
          <g>
            <g>
              <path className="st6" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
        h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
        c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
        c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
        c6.7-2.3,44.4-3.8,60.3-3.8" />
              <g>
                <path id="Path_1631_1_" className="st4" d="M1105.3,459.4h44v142h-44V459.4z" />
              </g>
              <rect x="773.3" y="459.6" className="st5" width="376" height="161" />

              <rect x="771.8" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2756 -411.8359)" className="st7" width="5" height="5" />
              <g>
                <defs>
                  <rect id="Rectangle_1527_19_" x="1105.3" y="459.6" width="44" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_24_">
                  <use xlinkHref="#Rectangle_1527_19_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className="st25" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
          c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
          s9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
          c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
          c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
          c3.4-1.2,14.6-2.1,26.9-2.8" />
                <g className="st26">
                  <g>
                    <path className="st20" d="M1116.1,476.6c3,0,7.8,4.6,9.8,12.6c1.4,5.7,2.6,12.3,3.6,19.3" />
                    <g>
                      <polygon className="st21" points="1124.3,507.7 1130.4,515.6 1134.1,506.3" />
                    </g>
                  </g>
                </g>
                <g className="st26">
                  <g>
                    <path className="st13" d="M1115,476.6c9.4-0.5,19.4-0.8,27.1-1" />
                    <g>
                      <polygon className="st12" points="1140.7,480.7 1149.3,475.6 1140.5,470.7" />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>

                  <rect x="1143.2" y="598.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8196 -634.0139)" className="st7" width="5" height="5" />
                  <polyline className="st9" points="1146.6,601.4 774.1,601.4 774.1,463.1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="OD_-_Projected_days_open" className="st0">
        <g className="st1">
          <g>
            <g>
              <g>
                <path id="Path_1631_2_" className="st4" d="M774.5,459.5h270v142h-270V459.5z" />
              </g>
              <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
              <path className="st6" d="M774.2,580.6h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
        c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
        c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
        c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
        c4-26.2,9.9-26.4,13.9-27.8c6.7-2.3,43.6-3.8,59.8-3.8" />

              <rect x="771.8" y="460.5" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.1934 -411.87)" className="st7" width="5" height="5" />
              <g>
                <defs>
                  <rect id="Rectangle_1527_16_" x="774.3" y="459.5" width="270.3" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_25_">
                  <use xlinkHref="#Rectangle_1527_16_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className="st27" d="M774.2,580.6h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
          c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
          c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
          c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
          c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
          c4-26.2,9.9-26.4,13.9-27.8c6.9-2.4,46-3.8,61.2-3.8" />
              </g>
              <g>
                <g>

                  <rect x="1143.2" y="598.8" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.7374 -634.0479)" className="st7" width="5" height="5" />
                  <polyline className="st9" points="1146.6,601.3 774.1,601.3 774.1,463" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Grey_curve" className="st0">
        <g className="st1">
          <g>
            <g>
              <path className="st6" d="M774.2,580.6h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
        c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
        c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
        c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
        c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
        c4-26.2,9.9-26.4,13.9-27.8c6.7-2.3,43.6-3.8,59.8-3.8" />
            </g>
          </g>
        </g>
      </g>
      <g id="Blue_cure" className="st0">
        <g className="st1">
          <path className="st28" d="M774.2,580.6h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
    c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
    c4.2-9.8,9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2
    c5.1,0,9.2,0,12.2-5.6c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6
    c4.5,18.1,6.5,45.8,8.5,61.5c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9
    c4-26.2,9.9-26.4,13.9-27.8c6.9-2.4,46-3.8,61.2-3.8" />
        </g>
      </g>
    </svg>
  )
}

export default CrbwpChart;
