import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST } from "../../redux/ProportionOfDelayedSamples/constants";
import "./ProportionOfDelayedSamples.scss";
import { useTranslation } from "react-i18next";
import { Widget, WidgetHeader, WidgetContent, WidgetActions, Tooltips, Button } from "lattec-ui-library";
import { SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";

const ProportionOfDelayedSamples: React.FC = () => {
  const { t } = useTranslation();

  const proportionOfDelayedSamplesState = useSelector(
    (state: any) => state.proportionOfDelayedSamplesState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // get questions when component is mounted
    if (!proportionOfDelayedSamplesState.gotData) {
      dispatch({
        type: GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST,
        payload: null,
      });
    }

    // tell hook to skip re-render if state hasnt changed
  }, [dispatch]);

  const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const Actions = (
    <WidgetActions>
      <Tooltips content={t("GoToLibrary").toString()} position="bottom">
        <Button
          variant="text"
          onClick={() => handleSetUrl("pds/lvl1/summary")}
          className="infoButton"
          icon={{
            variant: "fas",
            fontAwesomeName: "book",
            size: 18,
          }}
        />
      </Tooltips>
    </WidgetActions>
  )

  return (
    <Widget>
      <WidgetHeader
        headerlineseprator={true}
        title={t("ProportionOfDelayedSamples")}
        actions={Actions} />
      <WidgetContent>
        <div style={{ height: "30px", margin: "18px" }}>
          {proportionOfDelayedSamplesState.data ? (
            <ResponsiveBar
              layout={"horizontal"}
              data={
                proportionOfDelayedSamplesState.gotData
                  ? [
                    {
                      index: " ",
                      onTime: parseInt(
                        proportionOfDelayedSamplesState.data?.GreenPercentage,
                        10
                      ),
                      delayed2448Hours: parseInt(
                        proportionOfDelayedSamplesState.data
                          ?.YellowPercentage,
                        10
                      ),
                      delayed48Hours: parseInt(
                        proportionOfDelayedSamplesState.data?.RedPercentage,
                        10
                      ),
                    },
                  ]
                  : [
                    {
                      index: " ",
                      onTime: 0,
                      delayed2448Hours: 0,
                      delayed48Hours: 0,
                    },
                  ]
              }
              keys={["onTime", "delayed2448Hours", "delayed48Hours"]}
              indexBy="index"
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              colors={["#77AD1C", "#FFD300", "#CC2900"]}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "country",
                legendPosition: "middle",
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "food",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              enableGridY={false}
              enableLabel={false}
              labelTextColor={{ from: "color", modifiers: [["darker", 0]] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              tooltip={({ id, value }) => (
                <div>
                  <strong>
                    {t(id.toString())}: {value}
                  </strong>
                </div>
              )}
            />
          ) : (
            <Loader height={30} />
          )}
        </div>
      </WidgetContent>
    </Widget>

  );
};

export default React.memo(ProportionOfDelayedSamples);
