// @ts-ignore
import Plotly, { Data } from "../../plotly/bundle";
import React, { useState } from "react";
import plotComponentFactory from "react-plotly.js/factory";
import { iBoxPlotChart } from "./interface";
import { useDispatch } from "react-redux";
import { OPEN_COW_LIST } from "../../redux/Navigation/constants";
import {
  HANDLE_COW_FILTER_LIST_DESELECT,
  HANDLE_COW_LIST_NEW_SELECTION,
} from "../../redux/CowsFilterList/constants";
import { BAR_AREA_CLEAR_SELECTION } from "../../redux/BarArea/constants";
import './style.scss'
import { useTranslation } from "react-i18next";
import { formatMonthNational } from "../../utills/dateFormatting"

const ScatterChart: React.FC<iBoxPlotChart> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [wantedSize, setWantedSize] = useState<undefined | { width: number, height: number }>(undefined);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      let result = {
        width: entries[0]?.contentRect.width,
        height: entries[0]?.contentRect.height,
      }
      setWantedSize(result);
    });
    let elem1 = document.getElementById("resizePaneOne");
    elem1 && resizeObserver.observe(elem1);
  }, [])

  if (props.data.length === 0) return null

  let data: Data[] = props.data.map((dataObj: any) => {
    return {
      y: dataObj["OpenDays"],
      customdata: dataObj["OpenDays"],
      boxpoints: "all",
      jitter: 0.3,
      pointpos: -1.8,
      whiskerwidth: 0.2,
      fillcolor: "cls",
      marker: {
        size: 12,
        color: props.colors[dataObj["Month"]],
      },
      line: {
        width: 2,
      },
      type: 'box',
      name: formatMonthNational(dataObj["Year"], dataObj["Month"]),
    }
  })

  let Plot = plotComponentFactory(Plotly);

  return (
    <Plot
      style={{ width: "100%", height: "300px" }}
      className="box-plot"
      onInitialized={(f, g) => {
        g.style.cursor = "crosshair";
      }}
      data={data}
      onClick={(event) => {
        // this will de-select area if any area is selected
        dispatch({ type: OPEN_COW_LIST });

        // if any bar is selected then clear it
        dispatch({ type: BAR_AREA_CLEAR_SELECTION, payload: undefined });

        const month = props.data[event.points[0].curveNumber]["Month"];
        const year = props.data[event.points[0].curveNumber]["Year"];
        const actualY = event.points.length === 1 ? event.points[0].y : null;

        dispatch({
          type: HANDLE_COW_LIST_NEW_SELECTION,
          payload: {
            keys: props.keys,
            path: props.path,
            data: props.cowList,
            delayed: props.delayed,
            selection: [{ month, year, actualY }],
          },
        });
      }}
      onHover={(event) => {
        // @ts-ignore
        event.event.target.style.cursor = "pointer";
      }}
      onUnhover={(event) => {
        // @ts-ignore
        event.event.target.style.cursor = "crosshair";
      }}
      onDeselect={() => {
        dispatch({ type: HANDLE_COW_FILTER_LIST_DESELECT, payload: undefined });
      }}
      onSelected={(event) => {
        if (event && event.points.length > 0) {
          if (!props.cowListOpen) {
            dispatch({ type: OPEN_COW_LIST });
            // if the cow list is not open, just open that, and do not select cow,
            // since selection will be gone when cow list opens
            return;
          }

          // if any bar is selected then clear it
          dispatch({ type: BAR_AREA_CLEAR_SELECTION, payload: undefined });

          const data: any[] = event.points.map((point) => {
            const month = props.data[point.curveNumber]["Month"];
            const year = props.data[point.curveNumber]["Year"];
            const actualY = point.y;

            return { month, year, actualY };
          });

          dispatch({
            type: HANDLE_COW_LIST_NEW_SELECTION,
            payload: {
              keys: props.keys,
              path: props.path,
              data: props.cowList,
              delayed: props.delayed,
              selection: data,
            },
          });
        }
      }}
      useResizeHandler
      layout={{
        hovermode: "closest",
        margin: { t: 0, b:17 },
        font: {family: "Roboto", color: "575757" },
        yaxis: {
          title: props.yLegend,
          autorange: true,
          rangemode: "tozero",
          showgrid: true,
          zeroline: true,
          gridwidth: 1,
          zerolinewidth: 1,
          zerolinecolor: "lightgrey",
        },
        dragmode: "lasso",
        autosize: true,
        showlegend: false,
        annotations: [
          {
            xref: "paper",
            yref: "paper",
            x: 1,
            xanchor: "left",
            y: 0,
            yanchor: "top",
            text: props.xLegend,
            showarrow: false,
            font: { size: 14 }
          },
        ],
      }}
      config={{
        displayModeBar: false,
        responsive: true,
        showTips: true,
        scrollZoom: true,
        autosizable: true,
      }}
    />
  );
};

export default ScatterChart;
