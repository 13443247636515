import { put } from "redux-saga/effects";
import {
  GENERAL_ERROR,
  GENERAL_NOACCESS,
  GENERAL_UNHANDLEDERROR,
} from "../../constants";

export class LattecError {
  constructor(public statusCode: number) { }
}

export function* errorHandling(error: any) {
  if (error instanceof LattecError) {
    let specificError;
    specificError = error as LattecError;
    switch (specificError.statusCode) {
      case 401:
        // window.location.href = "/login";
        console.log("Not authorized");
        yield put({ type: GENERAL_NOACCESS, payload: "Not authorized" });
        break;
      default:
        console.log("An unhandled error occured");
        yield put({
          type: GENERAL_UNHANDLEDERROR,
          payload: "An unhandled error occured",
        });
        break;
    }
  } else {
    console.log("type is NOT LattecError");
    yield put({ type: GENERAL_ERROR, payload: "An error occured!" });
  }
}
