import {
  HANDLE_COW_FILTER_LIST_INITIALIZATION,
  HANDLE_COW_FILTER_SELECT,
  HANDLE_COW_FILTER_LIST,
  HANDLE_COW_FILTER_LIST_DESELECT,
} from "./constants";

export interface IState {
  data: any;
  filteredData: any;
  clearSelection: SVGRectElement | undefined,
  selected: SVGRectElement | undefined,
}

export const initialState: IState = {
  data: [],
  filteredData: [],
  clearSelection: undefined,
  selected: undefined,
};

export const CowsListFilterReducer =
  (state: IState = initialState, action: any) => {
    switch (action.type) {
      case HANDLE_COW_FILTER_LIST_INITIALIZATION:
        return {
          ...state,
          filteredData: action.payload,
          data: action.payload,
          clearSelection: state.selected,
          selected: undefined,
        }

      case HANDLE_COW_FILTER_LIST_DESELECT:
        return {
          ...state,
          filteredData: state.data,
        }

      case HANDLE_COW_FILTER_SELECT:
        return {
          ...state,
          selected: action.payload,
          clearSelection: undefined,
        }

      case HANDLE_COW_FILTER_LIST:
        return {
          ...state,
          filteredData: action.payload,
        };

      default:
        return state;
    }
  };
