import React from "react";
import {
    AISIcon,
    BaselineIcon,
    CowsListIcon,
    CRIcon,
    DashboardIcon,
    DOAIcon,
    HNIRIcon,
    KPIIcon,
    ODIcon,
    PBSSIcon,
    SEAIcon,
    StickConsumptionIcon,
    CrossIcon,
    TriangleIcon,
} from "../Icons";

interface ILAttecIconProps {
    icon: string;
    color?: string;
    size?: string | number;
}
const LattecIcon = (props: ILAttecIconProps) => {
    if (props.icon === "kpi") {
        return <KPIIcon color={props.color} size={props.size} />;
    } else if (props.icon === "dashboard") {
        return <DashboardIcon color={props.color} size={props.size} />;
    } else if (props.icon === "stick-consumption") {
        return <StickConsumptionIcon color={props.color} size={props.size} />;
    } else if (props.icon === "baseline") {
        return <BaselineIcon color={props.color} size={props.size} />;
    } else if (props.icon === "cows-list") {
        return <CowsListIcon color={props.color} size={props.size} />;
    } else if (props.icon === "doa") {
        return <DOAIcon color={props.color} size={props.size} />;
    } else if (props.icon === "cr") {
        return <CRIcon color={props.color} size={props.size} />;
    } else if (props.icon === "ais") {
        return <AISIcon color={props.color} size={props.size} />;
    } else if (props.icon === "hnir") {
        return <HNIRIcon color={props.color} size={props.size} />;
    } else if (props.icon === "pbss") {
        return <PBSSIcon color={props.color} size={props.size} />;
    } else if (props.icon === "sea") {
        return <SEAIcon color={props.color} size={props.size} />;
    } else if (props.icon === "od") {
        return <ODIcon color={props.color} size={props.size} />;
    } else if (props.icon === "cross") {
        return <CrossIcon color={props.color} size={props.size} />;
    } else if (props.icon === "plus") {
        return <CrossIcon rotate={45} color={props.color} size={props.size} />;
    } else if (props.icon === "triangle") {
        return <TriangleIcon color={props.color} size={props.size} />;
    } else {
        return null;
    }
};

export default LattecIcon;
