import React, { useEffect } from "react";
import {
  iStickConsumptionState,
  iStickConsumptionTable,
  iFormatedData,
  iUserOptionsState,
} from "./interface";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CRP_DB_HOVERED,
  SET_LIBRARY_OPEN_URL,
} from "../../redux/Navigation/constants";
import {
  GET_STICK_CONSUMPTION_REQUEST,
  FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
} from "../../redux/StickConsumption/constants";
import "./StickConsumption.scss";
import {
  GET_USER_OPTIONS_REQUEST,
  SET_USER_OPTIONS_REQUEST,
} from "../../redux/UserOptions/constants";
import { Button, Datatable, SkeletonLoader, Tooltips } from "lattec-ui-library";
import { formatTableData, getDataRelation } from "./helpers";

const StickConsumptionTable: React.FC<iStickConsumptionTable> = (props) => {
  const stickConsumptionState: iStickConsumptionState = useSelector(
    (state: any) => state.stickConsumptionState
  );
  const userOptionsState: iUserOptionsState = useSelector(
    (state: any) => state.userOptionsState
  );

  const { t } = useTranslation();

  const selectedElement = React.useRef<HTMLElement | undefined>(undefined);

  const dispatch = useDispatch();

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  useEffect(() => {
    const handleClickOutsideTable = (event: any) => {
      if (
        typeof event.target.className === "string" &&
        !event.target.className.includes("MUIDataTable")
      ) {
        if (selectedElement.current)
          selectedElement.current.style.background = "";
        selectedElement.current = undefined;

        dispatch({ type: SET_CRP_DB_HOVERED, payload: [""] });
      }
      if (!userOptionsState.gotData)
        dispatch({ type: GET_USER_OPTIONS_REQUEST, payload: null });
    };

    if (!userOptionsState.gotData) dispatch({ type: GET_USER_OPTIONS_REQUEST, payload: null });

    if (!stickConsumptionState.tableDataGotData) {
      dispatch({ type: GET_STICK_CONSUMPTION_REQUEST, payload: null });
    }

    document.addEventListener("mousedown", handleClickOutsideTable, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideTable);
    };

    // tell hook to skip re-render if state hasnt changed
  }, [dispatch]);

  const handlePinToDashboard = (kpi: string) => {
    const payload = { ...userOptionsState };
    payload.sticksUsed = kpi;
    dispatch({ type: SET_USER_OPTIONS_REQUEST, payload });
  };

  const handleRowHover = (kpi: string) => {
    const hoveredKpiList = getDataRelation(kpi);
    dispatch({ type: SET_CRP_DB_HOVERED, payload: hoveredKpiList });
  };

  const handleRowClicked = (kpi: string, elem: undefined | HTMLElement) => {
    handleRowHover(kpi);

    if (selectedElement.current) selectedElement.current.style.background = "";
    if (elem) elem.style.backgroundColor = "#E4F3FB";

    selectedElement.current = elem;

    // get the graph data matching the selection
    dispatch({
      type: FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
      payload: {
        kpi: kpi,
        farmGroup: userOptionsState.isOwnFarm,
      },
    });
  };

  const columns = [
    "Pin",
    "StickConsumptionKPI",
    userOptionsState.isOwnFarm === 0 ? "Benchmark farms" : "MyFarmReference",
    "SticksUsed",
    "Benchmark",
    "Difference",
  ].map((name, i) => {
    let label = (
      <div>{name === "Pin" || name === "StickConsumptionKPI" ? "" : name === "Benchmark" ? "" : t(name)}</div>
    );
    if (name === "Difference")
      label = (
        <Tooltips content={t("GoToLibrary").toString()} position="bottom">
          <Button
            variant="text"
            onClick={() => handleSetUrl("stick-consumption/lvl1/summary")}
            className="infoButton"
            icon={{ variant: "fas", fontAwesomeName: "book", size: 18 }}
          />
        </Tooltips>
      );

    return {
      name,
      label,
      options: {
        filter: false,
        sort: false,
      },
    };
  });

  let data: iFormatedData[] = [];
  if (stickConsumptionState.gotData) {
    data = formatTableData(
      stickConsumptionState.tableData,
      userOptionsState,
      t,
      handlePinToDashboard
    );
  }

  return (
    <> {!stickConsumptionState.gotData ? <SkeletonLoader type="article" isStatic={true}></SkeletonLoader>
      : <Datatable
        zebra={true}
        paperBorder={true}
        // @ts-ignore, Columns are needed for Datatable, but for some reason not recognized by linter
        columns={columns}
        data={data}
        options={{
          pagination: false,
          search: false,
          print: false,
          download: false,
          filter: false,
          selectableRows: "none",
          viewColumns: false,
          onRowClick: (arr: any, meta: { dataIndex: number }, e: any) => {
            handleRowClicked(
              stickConsumptionState?.tableData?.[meta.dataIndex]?.KPIName,
              e.target?.closest("tr")
            );
          },
          setRowProps: (row: any, dataIndex: any, rowIndex: number) => {
            return {
              onMouseEnter: () =>
                handleRowHover(
                  stickConsumptionState?.tableData?.[rowIndex]?.KPIName
                ),
              onMouseLeave: () => handleRowHover(""),
            };
          },
        }}
      />}</>
  );

};

export default StickConsumptionTable;
