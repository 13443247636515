import React, { useState } from "react";
import iPolarChart from "./interface";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Plotly, { Data } from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { getKPILongTitle } from "../../utills/dataFormatting";
import { IFilterState, timePeriods } from "../../redux/Filters/reducer";
import { useSelector } from "react-redux";

const Plot = createPlotlyComponent(Plotly);

const PolarThumbnail: React.FunctionComponent<iPolarChart> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [hoveredPoint, setHoveredPoint] = useState<
    undefined | Readonly<Plotly.PlotMouseEvent>
  >(undefined);

  const values = props.data.map((point) => point.value);
  values.push(values[0]);
  const theta = props.data.map((point) => point.key);
  theta.push(theta[0]);
  const refValues = props.data.map((point) => point.reference);
  refValues.push(refValues[0]);
  const names = ["Farm values", "Reference values"];

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );

  function handleNav(e: Readonly<Plotly.PlotMouseEvent>): void {
    // re-set selected periode to 60 days, and today to today
    filtersState.anoestrusLengthSelectedTimePeriod = timePeriods[0]
    filtersState.today = new Date()

    // @ts-ignore do not navigate to undefined
    if (e.points[0]?.theta === undefined) return;
    // @ts-ignore Theta exists but intellisense doesn't know about it
    history.push("/" + e.points[0]?.theta);
  }

  const colorValues = values.map((val, index) => props.colors[0])
  const colorRefValues = values.map((val, index) => props.colors[1])

  const sizeValues = values.map((val, index) => 6)
  const sizeRefValues = values.map((val, index) => 6)

  const data: Data[] = [values, refValues].map((val, index) => {
    return {
      type: "scatterpolar",
      r: index === 1 ? val : val.map((el) => (el == null ? 10 : el)),
      theta,
      fill: "toself",
      name: names[index],
      fillcolor: props.colors[index] + "80",
      marker: {
        symbol: val.map((el) => (el == null ? "circle-open" : "circle")),
        line: { width: 1 },
        color: [colorValues, colorRefValues][index],
        size: [sizeValues, sizeRefValues][index],
      },
      line: { color: "#00000000" },
      hoverinfo: "none", // Hides stock tooltip
    };
  });

  data.reverse();

  function renderTooltip() {
    if (!hoveredPoint) return;

    return (
      <div
        id="tooltip-container-narrow"
        style={{
          border: "2px",
          borderColor: "#888888",
          left: hoveredPoint.event.clientX + 16,
          top: hoveredPoint.event.clientY - 8,
          zIndex: 99999,
        }}
      >
        <h4>
          {
            // @ts-ignore
            t(getKPILongTitle(hoveredPoint.points[0]?.theta))
          }
        </h4>
      </div>
    );
  }

  return (
    <div style={{ marginLeft: "calc((100% - 150px) / 2)" }}>
      <Plot
        data={data}
        layout={{
          width: 150,
          height: 150,
          showlegend: false,
          margin: { b: 2, l: 2, r: 2, t: 2, pad: 2 },
          polar: {
            radialaxis: {
              fixedrange: true,
              range: [0, 100],
              showline: false,
              showticklabels: false,
              ticks: "",
              color: "#dddddd",
              gridwidth: 2,
            },
            angularaxis: {
              fixedrange: true,
              visible: true,
              tickmode: "array",
              color: "#dddddd",
              tickvals: [0, 1, 2, 3, 4, 5, 6, 7],
              ticktext: ["", "", "", "", "", "", ""],
              gridcolor: "#FFFFFF00",
              tickcolor: "#FFFFFF00",
              // @ts-ignore
              //This code is correct, but fails the linting, probably due to issues with the type definition
              rotation: 90,
              direction: "clockwise",
            },
          },
        }}
        onHover={(e) => {
          // @ts-ignore do not change cursor on undefined
          if (e.points[0]?.theta === undefined) return;

          // @ts-ignore
          e.event.target.style.cursor = "pointer";
          setHoveredPoint(e);
        }}
        onUnhover={(e) => {
          // @ts-ignore
          e.event.target.style.cursor = "default";
          setHoveredPoint(undefined);
        }}
        onClick={(e) => handleNav(e)}
        config={{
          displayModeBar: false,
          autosizable: true,
          scrollZoom: false,
          responsive: true,
        }}
      />
      {renderTooltip()}
    </div>
  );
};

export default PolarThumbnail;
