const delavalblue: string = "#103D82";
const delavalcyan: string = "#00A6FF";
// const delavalmediumcyan: string = '#7FD2FF';
// const delavallightcyan: string = '#BFE8FF';
// const delavalblack: string = '#000000';
const delavaldarkgray: string = "#575757";
const delavalgray: string = "#999999";
const delavalmediumgray: string = "#CCCCCC";
const delavallightgray: string = "#E5E5E5";
const delavalwhite: string = "#FFFFFF";
const delavalgreen: string = "#77AD1C";
const delavalyellow: string = "#FFD300";
// const delavalorange: string = '#FA961E';
// const delavaldarkorange: string = '#FF8C00';
const delavalred: string = "#FF3300";

// DeLaval Colors
export const PRIMARY_BLUE: string = "#002D9C"
export const PRIMARY_BLUE_TRANSPARENT: string = "rgba(0,45,156,0.25)"
export const PRIMARY_BLUE_SEMI_TRANSPARENT: string = "rgba(0,45,156,0.15)"
export const LIGHT_GRAY: string = "#808893"
export const LIGHT_GRAY_TRANSPARENT: string = "rgba(128, 136,147, 0.25)"
export const LIGHT_GRAY_SEMI_TRANSPARENT: string = "rgba(128, 136,147, 0.15)"
export const SECONDARY_BLUE: string = "#1192e8"
export const SECONDARY_BLUE_TRANSPARENT: string = "rgba(17, 146, 232, 0.25)"
export const SECONDARY_BLUE_SEMI_TRANSPARENT: string = "rgba(17, 146, 232, 0.35)"


export const REFERENCE_LINE: string = "#EE538B"

export const GROUP_2_COLOR_PALETTE = ["#002D9C", "#FA4D56"];
export const GROUP_3_COLOR_PALETTE = ["#002D9C", "#1192E8", "#FA4D56"];
export const GROUP_3_COLOR_PALETTE_REVERSED = ["#FA4D56", "#1192E8", "#002D9C"];
export const COLOR_SCREME_1: string[] = [PRIMARY_BLUE, LIGHT_GRAY];
export const COLOR_SCREME_1_FILL: string[] = [PRIMARY_BLUE_TRANSPARENT, LIGHT_GRAY_TRANSPARENT];
export const COLOR_SCREME_1_SEMI_FILL: string[] = [PRIMARY_BLUE_SEMI_TRANSPARENT, LIGHT_GRAY_SEMI_TRANSPARENT];
export const COLOR_SCREME_2: string[] = [PRIMARY_BLUE, SECONDARY_BLUE];
export const COLOR_SCREME_2_FILL: string[] = [PRIMARY_BLUE_TRANSPARENT, SECONDARY_BLUE_TRANSPARENT];
export const COLOR_SCREME_2_SEMI_FILL: string[] = [PRIMARY_BLUE_SEMI_TRANSPARENT, SECONDARY_BLUE_SEMI_TRANSPARENT];

export const BARS_COLOR_SCHEME_1 = ["rgba(0,45,156,1)", "rgba(86, 86, 86, 1)"]
export const BARS_COLOR_SCHEME_2 = ["rgba(0,45,156,1)", "rgba(17, 146, 232, 1)"]

export const POLAR_COLOR_SCHEME_1 = ['#002D9C', '#808893']
export const POLAR_COLOR_SCHEME_2 = ['var(--color-datavis-8)', 'var(--color-datavis-8)']


export const lattecchartcolor1: string = delavalblue;
export const lattecchartcolor2: string = delavalcyan;
export const delavallightcyan: string = "#BFE8FF";
export const lattecchartcolor3: string = delavalmediumgray;
export const lattecchartcolor4: string = delavalgreen;

export const lattecbordercolor1: string = delavalwhite;
export const lattecbordercolor2: string = delavalblue;
export const lattecbordercolor3: string = delavaldarkgray;

export const lattecscoregreen: string = delavalgreen;
export const lattecscoreyellow: string = delavalyellow;
export const lattecscorered: string = delavalred;
export const lattecscoreblue: string = delavalblue;

const lattecreferencegreen: string = "#008000";
export const lattecreferenceshadow: string = delavalblue;
export const lattecreferenceline: string = delavaldarkgray;

export const colorPalette2: string[] = [lattecchartcolor1, lattecchartcolor3];
export const colorPalette2Ext: string[] = [
  lattecchartcolor1,
  lattecchartcolor3,
  delavalcyan,
];
export const colorPalette3: string[] = [
  lattecchartcolor1,
  lattecchartcolor1,
  delavalgray,
  lattecchartcolor3,
];
export const colorPaletteRadar: string[] = [
  lattecchartcolor1,
  lattecchartcolor1,
  lattecreferencegreen,
];
export const colorPalette5: string[] = [
  lattecchartcolor1,
  lattecchartcolor2,
  delavalgray,
  delavalgreen,
  delavalred,
];

export const colorPalette2ForRef: string[] = [
  lattecchartcolor1,
  lattecchartcolor1,
];

export const colorPalette3ForRef: string[] = [
  lattecchartcolor1,
  lattecchartcolor1,
  lattecchartcolor3,
];

export const colorPalettePie: string[] = [lattecchartcolor1];

export const chartBackGround: string = delavallightgray;

export const targetPlotOpacity: number = 0.5;
export const refPlotOpacity: number = 0.2;

// COLORS
const orange = "#E88E42";
const darkBlue = "#0062A3";
const midBlue = "#0081D5";
const lightBlue = "#1FA5FF";

export const gray = "#bcc9d1";

// export const defaultColorPalette = [darkBlue, midBlue, lightBlue];
// export const contrastedTwoColorPalette = [darkBlue, orange];
// export const contrastedThreeColorPalette = [orange, darkBlue, lightBlue];
// export const contrastedThreeColorPaletteReversed = [darkBlue, midBlue, orange];

export const colorPalette12: string[] = [
  "#0b2856",
  "#103d82",
  "#2e66be",
  "#3472d5",
  "#92b8f2",
  "#cddef9",
  "#006499",
  "#0091ff",
  "#00a6ff",
  "#75cfff",
  "#b2e4ff",
  "#e4f2fb",
];