import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button as ButtonOld } from "../../Input";
import {
  GET_INSIGHTS_LIST_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
} from "../../redux/HNInsights/constants";
import "./Insights.scss";
import { Widget, WidgetHeader, Icon, WidgetContent, SkeletonLoader } from "lattec-ui-library";
import { InsightText } from "../../redux/HNInsights/types";
import { useHistory } from "react-router-dom";
import HoverGuide from "../../HoverGuide";

const Insights: React.FC = (props) => {
  const hnInsightsState = useSelector((state: any) => state.hnInsightsState);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // get questions when component is mounted
    if (!hnInsightsState.gotData) {
      dispatch({ type: GET_INSIGHTS_LIST_REQUEST, payload: null });
    }
    // tell hook to skip re-render if state hasnt changed
  }, [dispatch]);

  const handleUnpinToDashboard = (id: string) => {
    dispatch({
      type: PIN_INSIGHT_TO_DASHBOARD_REQUEST,
      payload: { id, pin: false },
    });
  };

  return (
    <Widget>
      <WidgetHeader
        headerlineseprator={true}
        onClickHeader={() => history.push("/insights")}
        title={t("ReproductionInsights")}
        icon={{ fontAwesomeName: "lightbulb-on", variant: "fal", position: "left" }}
      />
      <WidgetContent>
        <div className="insights">

          {hnInsightsState.loading ? <SkeletonLoader type="paragraph" isStatic={true}></SkeletonLoader> :
            hnInsightsState.dbInsightsList.length > 0 ? (
              hnInsightsState.dbInsightsList.map((el: any, index: number) => {
                return (
                  <div
                    key={`insight__${index}`}
                    className="insights__element-wrapper"
                  >
                    <div style={{ flex: "10" }}>
                      <span>{index + 1}.</span>
                      <span>{" " + (t(el.key) as InsightText).header}</span>
                    </div>
                    <div>
                      {(t(el.key) as InsightText).icon.map((iconElement: string) => {
                        return (
                          <Fragment key={`key__${iconElement}`}>
                            {iconElement === "Breed" ? (
                              <HoverGuide
                                content={t("Breeding advisor")}
                                direction="right"
                              >
                                <div
                                  className="questionaire__insight-suggestive-icon-wrapper"
                                  key={iconElement}
                                >
                                  <Icon size={25} variant={'lattec'} lattecName="Insemination" colors={{ fillColor: "#C8C8C8" }} />
                                </div>
                              </HoverGuide>
                            ) : iconElement === "Management" ? (
                              <HoverGuide
                                content={t("Management/Housing advisor")}
                                direction="right"
                              >
                                <div
                                  className="questionaire__insight-suggestive-icon-wrapper"
                                  key={iconElement}
                                >
                                  <Icon size={25} variant={'lattec'} lattecName="Herd_operations" colors={{ fillColor: "#C8C8C8" }} />
                                </div>
                              </HoverGuide>
                            ) : iconElement === "Feed" ? (
                              <HoverGuide
                                content={t("Feed advisor")}
                                direction="right"
                              >
                                <div
                                  className="questionaire__insight-suggestive-icon-wrapper"
                                  key={iconElement}
                                >
                                  <Icon size={25} variant={'lattec'} lattecName="Feed" colors={{ fillColor: "#C8C8C8" }} />
                                </div>
                              </HoverGuide>
                            ) : iconElement === "Veterinarian" ||
                              iconElement === "Vet" ? (
                              <HoverGuide
                                content={t("Veterinarian")}
                                direction="right"
                              >
                                <div
                                  className="questionaire__insight-suggestive-icon-wrapper"
                                  key={iconElement}
                                >
                                  <Icon size={25} variant={'lattec'} lattecName="Health_check" colors={{ fillColor: "#C8C8C8" }} />
                                </div>
                              </HoverGuide>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </div>
                    <ButtonOld
                      style={{ zIndex: 0, flex: "1" }}
                      onClick={() => handleUnpinToDashboard(el.id)}
                      onMouseLeave={(e: any) => e.preventDefault()}
                    >
                      <i className="fas fa-times" color="lightgray" />
                    </ButtonOld>
                  </div>
                );
              })
            ) : (
              <div className="insights__fallback">
                <span onClick={() => history.push("/insights")}>
                  {t("NoPinnedInsights")}
                  <i className="far fa-chevron-right" />
                </span>
              </div>
            )}
        </div>
      </WidgetContent>
    </Widget>

  );
};

export default Insights;
