import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE } from "../../redux/Navigation/constants";
import "../DataType.scss";
import { iDataTypeToggle } from "./interface";
import { useTranslation } from "react-i18next";
import { Tabs } from "lattec-ui-library";
import { formatDateNational } from "../../utills/dateFormatting"
import { SET_USER_OPTIONS_REQUEST } from "../../redux/UserOptions/constants";

const DataTypeToggle: React.FC<iDataTypeToggle> = (props) => {
  const { t } = useTranslation();

  const userOptionsState = useSelector((state: any) => state.userOptionsState);
  const currentReproductionPerformanceState = useSelector((state: any) => state.currentReproductionPerformanceState);

  const dispatch = useDispatch();

  const handleToggleDataTypeToggle = (key: number) => {
    const payload = { ...userOptionsState }
    if (props.toogleId === DASHBOARD_TOGGLE) payload.dataType = key
    else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) payload.isOwnFarm = key
    else payload.dataBaseLineReportType = key

    dispatch({ type: SET_USER_OPTIONS_REQUEST, payload });
  }

  let dataType;
  if (props.toogleId === DASHBOARD_TOGGLE) dataType = userOptionsState.dataType;
  else if (props.toogleId === STICK_CONSUMPTION_TOGGLE) dataType = userOptionsState.isOwnFarm;
  else dataType = userOptionsState.dataBaseLineReportType;

  
  return (
    <div>
      <Tabs
        vertical={false}
        items={[t("Benchmark farms"), `${t("MyFarm")} ${currentReproductionPerformanceState.dimDate !== null ? "(" + formatDateNational(currentReproductionPerformanceState.dimDate) + ")" : ""}`]}
        variant="transparent"
        fullWidth={false}
        value={dataType}
        onChange={(value: number) => {
          handleToggleDataTypeToggle(value)
        }}
      />
    </div>
  );
};

export default DataTypeToggle;
