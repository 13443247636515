import { SagaIterator } from "redux-saga";
import { put, takeEvery } from "redux-saga/effects";
import { createComparerFunction } from "../../utills/dataFormatting";
import {
  HANDLE_COW_LIST_NEW_FILTER,
  HANDLE_COW_FILTER_LIST,
  HANDLE_COW_LIST_NEW_SELECTION,
  HANDLE_COW_SHOW_HIDDEN,
} from "./constants";

function filterCr(payload: any) {
  let newList = payload.data;

  if (payload.filters.FPLState || payload.filters.CLState) {
    const comparer: ((arg1: number) => boolean) = createComparerFunction(payload.filters.ranges);
    newList = newList.filter((el: any) => {
      return el.CycleData.some((cycle: any) => {
        return comparer(cycle[payload.keys[0]]) && cycle[payload.keys[1]] === (payload.keys[1] === "CLState" ? payload.filters.CLState : payload.filters.FPLState);
      })
    });

    // modify the hide property, so only the event that is ´selected´ is shown in th cow list
    newList.forEach((element: any) => {
      element.CycleData.forEach((cycle: any) => {
        if (comparer(cycle[payload.keys[0]]) && cycle[payload.keys[1]] === (payload.keys[1] === "CLState" ? payload.filters.CLState : payload.filters.FPLState)) cycle.Hide = false
        else cycle.Hide = true
      });
    });
  }

  return newList;
}

function standardRangeFilter(payload: any) {
  let newList = payload.data;

  if (payload.filters.ranges) {
    const comparer: ((arg1: number) => boolean) = createComparerFunction(payload.filters.ranges);
    newList = newList.filter((el: any) => {
      return comparer(el[payload.keys[0]]);
    });
  }

  return newList;
}

function filterDoa(payload: any) {
  let newList = standardRangeFilter(payload);

  if (payload.filters.DOAState) {
    const DOAState = payload.filters.DOAState === "Anoestrus cows" ? "Anoestrus" : "Cyclic"
    newList = newList.filter((el: any) => {
      return el[payload.keys[1]] === DOAState && (payload.delayed || el["DOADelayValue"] < 3);
    });
  }

  if (payload.filters.FPLState) {
    newList = newList.filter((el: any) => {
      return el[payload.keys[1]] === payload.filters.FPLState && (payload.delayed || el["FLPDelayValue"] < 3);
    });
  }

  return newList;
}

function filterHnir(payload: any) {
  let newList = payload.data;

  if (payload.filters.data && payload.filters.data.x && payload.filters.data.y) {
    newList = newList.filter((el: any) => {
      return el.InseminationData.some((insemination: any) => {
        // take x max 300 and y max 5 into consideration also
        return (insemination.DaysFromCalving == payload.filters.data.x || (payload.filters.data.x == 300 && insemination.DaysFromCalving > 300)) &&
          (insemination.HeatNo == payload.filters.data.y || (payload.filters.data.y == 5 && insemination.HeatNo > 5));
      })
    });
  }

  if (payload.filters.ranges && payload.filters.HNIRState) {
    const comparer: ((arg1: number) => boolean) = createComparerFunction(payload.filters.ranges);
    newList = newList.filter((el: any) => {
      return el.InseminationData.some((insemination: any) => {
        return comparer(insemination[payload.keys[0]]) &&
          insemination[payload.keys[1]] === payload.filters.HNIRState &&
          (payload.delayed || (insemination["FilterSampleDelayValue"] !== null && insemination["FilterSampleDelayValue"] < 3))
      })
    });

    // modify the hide property, so only the event that is ´selected´ is shown in th cow list
    newList.forEach((element: any) => {
      element.InseminationData.forEach((insemination: any) => {
        if (comparer(insemination[payload.keys[0]]) &&
          insemination[payload.keys[1]] === payload.filters.HNIRState &&
          (payload.delayed || (insemination["FilterSampleDelayValue"] !== null && insemination["FilterSampleDelayValue"] < 3))) insemination.Hide = false
        else insemination.Hide = true
      });
    });
  }

  return newList;
}

function filterAis(payload: any) {
  let newList = payload.data;

  const i1 = payload.keys.some((x: String) => x === "i1Checked")
  const i2 = payload.keys.some((x: String) => x === "i2Checked")
  const i3 = payload.keys.some((x: String) => x === "i3Checked")
  const i4 = payload.keys.some((x: String) => x === "i4Checked")

  if (payload.filters.data && payload.filters.data.x !== undefined && payload.filters.data.y !== undefined && payload.keys) {
    newList = newList.filter((el: any) => {
      // since when HoursBetweenHeatAndAI are negative they are mapped to zero on the plot, we use HoursBetweenHeatAndAI not y to find the cow in the list
      if (payload.keys[1] === "HoursBetweenHeatAndAI") return el[payload.keys[0]] == payload.filters.data.y && el[payload.keys[1]] == payload.filters.data.x;
      return el[payload.keys[1]] == payload.filters.data.y && el[payload.keys[0]] == payload.filters.data.x;
    });
  }

  if (payload.filters.ranges && payload.filters.InseminationState) {
    const comparer: ((arg1: number) => boolean) = createComparerFunction(payload.filters.ranges);
    newList = newList.filter((el: any) => {
      return el.AISInseminationData.some((insemination: any) => {
        // trim all space and lowercasehack to handle cows in percentage, as their InseminationState is padded with a space
        return comparer(insemination[payload.keys[0]]) &&
          (insemination[payload.keys[1]].toLowerCase() === payload.filters.InseminationState.toLowerCase().replace(/ /g, '')) &&
          ((i1 && insemination["AINumber"] === 1) ||
            (i2 && insemination["AINumber"] === 2) ||
            (i3 && insemination["AINumber"] === 3) ||
            (i4 && insemination["AINumber"] > 3))
      });
    });

    // modify the hide property, so only the event that is ´selected´ is shown in th cow list
    newList.forEach((element: any) => {
      element.AISInseminationData.forEach((insemination: any) => {
        if (comparer(insemination[payload.keys[0]]) &&
          (insemination[payload.keys[1]].toLowerCase() === payload.filters.InseminationState.toLowerCase().replace(/ /g, '')) &&
          ((i1 && insemination["AINumber"] === 1) ||
            (i2 && insemination["AINumber"] === 2) ||
            (i3 && insemination["AINumber"] === 3) ||
            (i4 && insemination["AINumber"] > 3))) insemination.Hide = false
        else insemination.Hide = true
      });
    });
  }

  return newList;
}

function filterSea(payload: any) {
  let newList = standardRangeFilter(payload);

  if (payload.filters.SEAState) {
    newList = newList.filter((el: any) => {
      return el[payload.keys[1]] === payload.filters.SEAState;
    });
  }

  if (payload.filters.PBSSState) {
    newList = newList.filter((el: any) => {
      return el[payload.keys[1]] === payload.filters.PBSSState;
    });
  }

  return newList;
}

function filterOd(payload: any) {
  let newList = standardRangeFilter(payload);

  if (payload.filters.CompletionStatus && !payload.filters.MAXAINumber) {
    newList = newList.filter((el: any) => {
      return el[payload.keys[1]] === payload.filters.CompletionStatus;
    });
  }

  if (payload.filters.MAXAINumber) {
    newList = newList.filter((el: any) => {
      if (payload.filters.CompletionStatus !== "NotPregnant") {
        return (el.CompletionStatus === payload.filters.CompletionStatus && el[payload.keys[0]] == payload.filters.MAXAINumber);
      }
      else {
        return (el.CompletionStatus === "NotPregnant" && el[payload.keys[0]] == payload.filters.MAXAINumber) || el[payload.keys[0]] > payload.filters.MAXAINumber;
      }
    });
  }

  return newList;
}

function switchPath(action: any) {
  switch (action.payload.path) {
    case "crbwp": return filterCr(action.payload);
    case "doa": return filterDoa(action.payload);
    case "hnir": return filterHnir(action.payload);
    case "ais": return filterAis(action.payload);
    case "sea": return filterSea(action.payload);
    case "od": return filterOd(action.payload);
    default: return [];
  }
}

function* handleCowListNewFilter(action: any): SagaIterator {
  try {
    const filterList = switchPath(action)

    yield put({ type: HANDLE_COW_FILTER_LIST, payload: filterList });
  } catch (error) {
    yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
  }
}

function* handleCowShowHiden(action: any): SagaIterator {
  try {
    // modify the hidden property to show all cows
    if (action.payload.path === "crbwp") {
      action.payload.data.forEach((element: any) => {
        element.CycleData.forEach((cycleData: any) => {
          cycleData.Hide = false
        });
      });
    }

    if (action.payload.path === "hnir") {
      action.payload.data.forEach((element: any) => {
        element.InseminationData.forEach((insemination: any) => {
          insemination.Hide = false
        });
      });
    }

    if (action.payload.path === "ais") {
      action.payload.data.forEach((element: any) => {
        element.AISInseminationData.forEach((insemination: any) => {
          insemination.Hide = false
        });
      });
    }

    yield put({ type: HANDLE_COW_FILTER_LIST, payload: action.payload.data });
  } catch (error) {
    yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
  }
}

function selectionHnir(payload: any) {
  let newList = payload.data;

  newList = newList.filter((cow: any) => {
    return cow.InseminationData.some((insemination: any) => {
      return payload.selection.some((point: any) => {
        return insemination[payload.keys[0]] === point.actualX && insemination[payload.keys[1]] === point.actualY
      });
    })
  })

  // modify the hide property, so only the event that is ´selected´ is shown in th cow list
  newList.forEach((element: any) => {
    element.InseminationData.forEach((insemination: any) => {
      payload.selection.forEach((point: any) => {
        insemination.Hide = true
      })

      payload.selection.forEach((point: any) => {
        if (insemination[payload.keys[0]] === point.actualX && insemination[payload.keys[1]] === point.actualY) insemination.Hide = false
      })
    });
  });

  return newList;
}

function selectionAis(payload: any) {
  let newList = payload.data;

  newList = newList.filter((cow: any) => {
    return payload.selection.some((point: any) => {
      return cow.AISInseminationData.some((insemination: any) => {
        return insemination[payload.keys[0]] === point.actualX && insemination[payload.keys[1]] === point.actualY
      });
    });
  })

  return newList;
}

function selectionOd(payload: any) {
  let newList = payload.data;

  newList = newList.filter((cow: any) => {
    return payload.selection.some((point: any) => {
      var shortDate: Date = new Date(cow.ShortDate);
      return (!point.actualY || point.actualY === cow[payload.keys[0]]) && point.year === shortDate.getFullYear() && point.month === shortDate.getMonth()
    });
  })

  return newList;
}

function switchPathSelection(action: any) {
  switch (action.payload.path) {
    case "hnir": return selectionHnir(action.payload);
    case "ais": return selectionAis(action.payload);
    case "od": return selectionOd(action.payload);
    default: return [];
  }
}

function* handleCowListNewSelection(action: any): SagaIterator {
  try {
    let filterList = switchPathSelection(action)

    yield put({ type: HANDLE_COW_FILTER_LIST, payload: filterList });
  } catch (error) {
    yield put({ type: "HANDLE_FILTER_COW_LIST_ERROR", payload: "An error occured" });
  }
}

export function* watchGetCowsFilterList(): SagaIterator {
  yield takeEvery(HANDLE_COW_LIST_NEW_FILTER, handleCowListNewFilter);
  yield takeEvery(HANDLE_COW_LIST_NEW_SELECTION, handleCowListNewSelection);
  yield takeEvery(HANDLE_COW_SHOW_HIDDEN, handleCowShowHiden);
}
