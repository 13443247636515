import { getXml } from "../utills/xmlBuilder";

export async function postJSON(url: string, params?: any) {
  // @ts-ignore
  const token = (global.orbit ? orbit.auth.jwtToken : "");
  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(params),
  });

  if (response.status === 401) throw new Error("401")
  if (response.status === 500) throw new Error("500")

  if (response.status === 200) return await response.json();
}

export async function postXML(url: string, params?: any, filterName?: any) {
  // @ts-ignore
  const token = (global.orbit ? orbit.auth.jwtToken : "");

  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    }),
    body: getXml(params, filterName),
  });
  if (response.status === 403) {
    //@ts-ignore
    if (global.orbit.env.ENV === 'prod') document.location.href = "https://plus.delaval.com/dashboard"
    //@ts-ignore
    else if (global.orbit.env.ENV === 'stage' || global.orbit.env.ENV === 'test') document.location.href = "https://stage.login.delaval.cloud"
    //@ts-ignore local and dev will fall here
    else document.location.href = "https://dev.login.delaval.cloud"
  }

  if (response.status === 401) throw new Error("401")
  if (response.status === 500) throw new Error("500")

  if (response.status === 200) return await response.json();
}
