import {
  GET_USER_OPTIONS_REQUEST,
  GET_USER_OPTIONS_SUCCESS,
  GET_USER_OPTIONS_FAILURE
} from "./constants";

export interface IState {
  gotData: boolean;
  loading: boolean;
  error: null | string;
  baselineReport: {
    ProportionOfCowsBred: boolean,
    CalvingToFirstAIInterval: boolean,
    FirstToLastAIInterval: boolean,
    AIsPrCow: boolean,
    InseminationRate: boolean,
    AIsPrPregnancy: boolean,
    PregnancyRate: boolean,
    OpenDays: boolean,
  },
  dataType: number,
  isOwnFarm: number,
  dataBaseLineReportType: number,
  sticksUsed: string,
}

export const initialState: IState = {
  gotData: false,
  loading: false,
  error: null,
  baselineReport: {
    ProportionOfCowsBred: true,
    CalvingToFirstAIInterval: false,
    FirstToLastAIInterval: true,
    AIsPrCow: false,
    InseminationRate: true,
    AIsPrPregnancy: false,
    PregnancyRate: true,
    OpenDays: true,
  },
  dataType: 0,
  isOwnFarm: 0,
  dataBaseLineReportType: 1,
  sticksUsed: "SticksUsedPrPregnancy",
};

export const UserOptionsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {

    case GET_USER_OPTIONS_REQUEST:
      return {
        ...state,
        gotData: false,
        loading: true,
        error: null,
      };

    case GET_USER_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        gotData: true,
        baselineReport: action.payload.baselineReport,
        dataType: action.payload.dataType,
        isOwnFarm: action.payload.isOwnFarm,
        dataBaseLineReportType: action.payload.dataBaseLineReportType,
        sticksUsed: action.payload.sticksUsed,
      };

    case GET_USER_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
