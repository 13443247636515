import React, { Fragment, useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper } from "../../Content";
import { useTranslation } from "react-i18next";
import { CurrentReproductionPerformance, } from "../../Cards";
import { BASELINE_REPORT_TOGGLE, SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";
import "./style.scss";
import { Breadcrumbs, Grid, Widget, WidgetHeader, Icon, Typography, WidgetContent, WidgetActions, Button, Tooltips } from 'lattec-ui-library';
import {
  GET_HNINSIGHTS_QUESTIONS_REQUEST,
  GO_TO_PREVIOUS_QUESTION_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
  POST_HNINSIGHTS_ANSWER_REQUEST,
  REPLACE_INSIGHT_REQUEST,
  SET_HNINSIGHTS_STAGE,
  SET_SELECTED_ANSWER,
  STAGES,
} from "../../redux/HNInsights/constants";
import Modal from "../../Modal";
import Progressbar from "../../Progressbar";
import { QuestionText, InsightText, IHNInsightsAnswer, IState, Insight, } from "../../redux/HNInsights/types";
import { initialState } from "../../redux/HNInsights/reducer";
import parse from "html-react-parser";
import HoverGuide from "../../HoverGuide";

const InsightsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_HNINSIGHTS_QUESTIONS_REQUEST });
  }, [dispatch]);

  const hnInsightsState: IState = useSelector((state: IState) => state.hnInsightsState);

  const handlePinToDashboard = (id: string) => {
    let elem = hnInsightsState.insights.find((x) => x.id === id);
    dispatch({
      type: PIN_INSIGHT_TO_DASHBOARD_REQUEST,
      payload: { id, pin: elem?.dashBoard === 0 },
    });
  };

  const handleSetActiveInsight = (id: Insight) => {
    const el = document.getElementById("insight" + id.key);
    if (!el) return;
    if (!el.style) return;

    if (el.style.display === "none") el.style.display = "inline";
    else el.style.display = "none";

    const up = document.getElementById("insight" + id.key + "up");
    if (!up) return;
    if (!up.style) return;

    if (up.style.display === "none") up.style.display = "inline";
    else up.style.display = "none";

    const down = document.getElementById("insight" + id.key + "down");
    if (!down) return;
    if (!down.style) return;

    if (down.style.display === "none") down.style.display = "inline";
    else down.style.display = "none";
  };

  const handleReplaceInsight = (id: string) => dispatch({ type: REPLACE_INSIGHT_REQUEST, payload: { id } });

  const handleSetUrl = (url: string) => dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const Actions = (
    <WidgetActions>
      <Tooltips content={t("GoToLibrary").toString()} position="bottom">
        <Button
          variant="text"
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          className="infoButton"
          icon={{
            variant: "fas",
            fontAwesomeName: "book",
            size: "18",
          }}
        />
      </Tooltips>
    </WidgetActions>
  )

  const handleGetAnalysisQuestions = (numOfQestions: number) => {
    // scroll to top, so the user can see the questionaire modal-dialog
    window.scrollTo(0, 0)
    // fetch all questions
    dispatch({
      type: GET_HNINSIGHTS_QUESTIONS_REQUEST,
      payload: numOfQestions,
    });
  };

  const renderQuestionaireButtons = (
    <>
      <div className="questionaire__actions">
        <div>
          <Button
            onClick={() => handleGetAnalysisQuestions(3)}
            label={t("QuickSession")}
            variant="primary"
          />
        </div>
        <div>
          <Button
            onClick={() => handleGetAnalysisQuestions(0)}
            label={t("FullSession")}
            variant="primary"
          />
        </div>
      </div>
    </>
  );

  const ListOfInsightsComponent = () => {
    return (
      <Grid item>
        {hnInsightsState.insights.map((el, index) => {
          return (
            <div
              key={el.key}
              className={
                "questionaire__insights" +
                (el.dashBoard ? " pinned" : "")
              }
            >
              <div className="questionaire__insight-wrapper">
                <div className="questionaire__insight-headline-wrapper" >
                  <Grid container>
                    <Grid ><HoverGuide content={t("Pin to dashboard")} direction="right">
                      <Button className="infoButton" style={{ position: "absolute", margin: "-40px -54px" }} icon={{ fontAwesomeName: "thumbtack", style: { transform: "rotate(330deg)" }, variant: (el.dashBoard ? "fas" : "fal"), colors: { fillColor: "#0091FF", strokeColor: "#0091FF" } }} variant="text" onClick={() => handlePinToDashboard(el.id)} />
                    </HoverGuide></Grid>
                    <Grid xs={9}> <div className="insight-rank">{index + 1}.{" " + (t(el.key) as InsightText).header}</div></Grid>
                    <Grid xs={2}>
                      <div>
                        {(t(el.key) as InsightText).icon.map((iconElement: string) => {
                          return (
                            <Fragment key={`key__${iconElement}`}>
                              {iconElement === "Breed" ? (
                                <HoverGuide
                                  content={t("Breeding advisor")}
                                  direction="right"
                                >
                                  <div
                                    className="questionaire__insight-suggestive-icon-wrapper"
                                    key={iconElement}
                                  >
                                    <Icon size={35} variant={'lattec'} lattecName="Insemination" colors={{ fillColor: "#C8C8C8" }} />
                                  </div>
                                </HoverGuide>
                              ) : iconElement === "Management" ? (
                                <HoverGuide
                                  content={t("Management/Housing advisor")}
                                  direction="right"
                                >
                                  <div
                                    className="questionaire__insight-suggestive-icon-wrapper"
                                    key={iconElement}
                                  >
                                    <Icon size={35} variant={'lattec'} lattecName="Herd_operations" colors={{ fillColor: "#C8C8C8" }} />
                                  </div>
                                </HoverGuide>
                              ) : iconElement === "Feed" ? (
                                <HoverGuide
                                  content={t("Feed advisor")}
                                  direction="right"
                                >
                                  <div
                                    className="questionaire__insight-suggestive-icon-wrapper"
                                    key={iconElement}
                                  >
                                    <Icon size={35} variant={'lattec'} lattecName="Feed" colors={{ fillColor: "#C8C8C8" }} />
                                  </div>
                                </HoverGuide>
                              ) : iconElement === "Veterinarian" ||
                                iconElement === "Vet" ? (
                                <HoverGuide
                                  content={t("Veterinarian")}
                                  direction="right"
                                >
                                  <div
                                    className="questionaire__insight-suggestive-icon-wrapper"
                                    key={iconElement}
                                  >
                                    <Icon size={35} variant={'lattec'} lattecName="Health_check" colors={{ fillColor: "#C8C8C8" }} />
                                  </div>
                                </HoverGuide>
                              ) : null}
                            </Fragment>
                          );
                        })}

                      </div>
                    </Grid>
                    <Grid xs={1}>  <HoverGuide content={t("Dismiss insight")} direction="right">
                      <Button style={{ margin: 0 }} icon={{ fontAwesomeName: "trash", variant: "fas", colors: { fillColor: "#0091FF", strokeColor: "#0091FF" } }} variant="text" onClick={() => handleReplaceInsight(el.id)} />
                    </HoverGuide>
                    </Grid>
                  </Grid>
                </div>
                <div className="questionaire__insight-bottom">
                  <div className="questionaire__insight-suggestive-icons">
                    <p className="questionaire__insight-body">{(t(el.key) as InsightText).summary}</p>
                    <div id={"insight" + el.key + "down"} style={{ display: "inline" }}>
                      <HoverGuide content={t("Read more")} direction="bottom">
                        <Button icon={{ fontAwesomeName: "chevron-down", variant: "fas" }} variant="text" onClick={() => handleSetActiveInsight(el)} />
                      </HoverGuide>
                    </div>
                    <div id={"insight" + el.key + "up"} style={{ display: "none" }}>
                      <HoverGuide content={t("Read less")} direction="bottom">
                        <Button icon={{ fontAwesomeName: "chevron-up", variant: "fas" }} variant="text" onClick={() => handleSetActiveInsight(el)} />
                      </HoverGuide>
                    </div>
                  </div>
                </div>
                <div id={"insight" + el.key} style={{ display: "none" }}>
                  <div className="questionaire__insight-body-wrapper">
                    <p className="questionaire__insight-body">{(t(el.key) as InsightText).body}</p>
                  </div>
                  <div className="questionaire__detailed-action-list-wrapper">
                    <h4 className="questionaire__detailed-action-list-headline">{t("Possible action points")}</h4>
                    {(t(el.key) as InsightText).actionlist.map((actionListElement: string, index: number) => {
                      return (
                        <div className="questionaire__detailed-action-list-element" key={`action-list-element-${index}`}>
                          <p className="questionaire__insight-body">- {actionListElement}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })
        }
      </Grid >
    )
  }

  const handleGoToNextQuestion = () => {
    // post answer to server
    // if question id, next question should come from that

    // if sub question next question should be hnInsightsState.questions.open[0]
    // else hnInsightsState.questions.open[1]

    const isSubQuestion =
      hnInsightsState.questions.open.length > 0 &&
      hnInsightsState.activeQuestion.questionId !==
      hnInsightsState.questions.open[0].questionId;
    // when moving to next question - we need to also put the sub question into the answered array

    dispatch({
      type: POST_HNINSIGHTS_ANSWER_REQUEST,
      payload: {
        selectedAnswer: hnInsightsState.selectedAnswer,
        nextQuestion: isSubQuestion
          ? hnInsightsState.questions.open[0]
          : hnInsightsState.questions.open[1],
        currentStage: hnInsightsState.stage,
      },
    });
  };

  const handleGoToPreviousQuestion = () => {
    // Get the last item in the answered array
    const answeredQuestion =
      hnInsightsState.questions.answered[
      hnInsightsState.questions.answered.length - 1
      ];

    // set answered question to null
    handleSelectAnswer(initialState.selectedAnswer);

    dispatch({
      type: GO_TO_PREVIOUS_QUESTION_REQUEST,
      payload: answeredQuestion,
    });
  };

  const handleSelectAnswer = (el: any) =>
    dispatch({ type: SET_SELECTED_ANSWER, payload: el });

  const setStage1 = (stage: string) => dispatch({ type: SET_HNINSIGHTS_STAGE, payload: stage })
  return (
    <Fragment>
      <Breadcrumbs
        breadcrumbs={[
          { label: t("Dashboard"), link: "/dashboard" },
          { label: t("HNADashboard"), link: "/reproductionperformancerepro" },
          { label: t("ReproductionInsights") },
        ]}
        backToVariant={false}
        onClick={(link) => window.history.pushState(null, "", link)}
      />
      <Grid direction="row" container item alignItems="center" justify="flex-start" >
        <Grid item >
          <div style={{ marginBottom: '16px', marginRight: '20px' }}>
            <Icon variant="fal" fontAwesomeName="chart-line" size={32} colors={{ fillColor: 'rgb(113, 113, 113)' }} />
          </div>
        </Grid>
        <Grid item >
          <Typography variant="Heading 2" text={t("ReproductionInsights")} style={{ paddingBottom: '16px', color: 'rgb(113, 113, 113)' }} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid>
          <Typography variant="Body Default" text={t("NoteThatTheseInsightsAreBasedOnYourAnswersWithinThisSession.TheInsightsAreMeantAsAGuideTowardsAreasForYouToInvestigateOnYourOwnOrWithYourLocalAdviser.IfInDoubt,AlwaysConsultYourLocalAdviser.DeLavalCannotBeHeldLiableForAnyAdverseEffectsBasedOnThisAnalysis.")} style={{ color: 'rgb(113, 113, 113)', fontStyle: 'italic' }} />
        </Grid>
      </Grid>
      <ContentWrapper>
        <Grid container direction="row">
          <Grid xs={12} lg={6} direction="column">
            <Grid
              spacingRight={2}
              spacingBottom={2}
            >
              <Widget>
                <WidgetHeader
                  headerlineseprator={true}
                  title={t("ReproductionInsights")}
                  icon={{ lattecName: "Current_reproduction_perforamance", variant: "lattec", position: "left" }}
                  actions={Actions}
                />
                <WidgetContent>
                  <div className="questionaire">
                    {hnInsightsState.error || hnInsightsState.stage === null/*|| hnInsightsState.gotData === false*/ ? (
                      <div>{hnInsightsState.error}</div>
                    ) : (
                      <Fragment>
                        {hnInsightsState.stage === STAGES.CONFIG_INTRO || hnInsightsState.stage === STAGES.CONFIG_QUESTIONAIRE ? (hnInsightsState.status === STAGES.CONFIG_QUESTIONS_EXPIRED ? (
                          <div className="questionaire__container">
                            <h3 className="questionaire__headline">{t("ManagementAndFarmSetupQuestionsExpired")}</h3>
                            <p className="questionaire__info-text">
                              {t("ItSeemsToHaveBeenAWhileSinceYouAnsweredYourFarmManagementAndSetupQuestions.")}
                              <br />
                              {t("ToMakeSureThatWeStillPresentTheMostAccurateInsights,Let'sStepThroughTheQuestionsAgain.")}
                            </p>
                            <div className="questionaire__actions__singular">
                              <Button
                                style={{ float: 'right' }}
                                label={t("Ok,Let'sGo")}
                                variant="primary"
                                onClick={() => setStage1(STAGES.CONFIG_QUESTIONAIRE)}
                              />
                            </div>
                          </div>
                        ) : (
                          <Fragment>
                            <div className="questionaire__container">
                              <h3 className="questionaire__headline">{t("BeforeWeBegin")}</h3>
                              <p className="questionaire__info-text">
                                {t("WeNeedToKnowSomeBasicsAboutYourFarmManagementAndSetup.")}
                                <br />
                                <br />
                                {t("ThisHelpsUsGiveYouTheMostAccurateInsights.")}
                                <br />
                                <br />
                                <i>
                                  {t("YouWillOnlyHaveToAnswerTheseQuestionsOnYourFirstVisit-AndAfterThatJustACoupleOfTimesAYear,SoPleaseBeAccurateInYourAnswers.")}
                                </i>
                              </p>
                              <div className="questionaire__actions__singular">
                                <Button
                                  style={{ float: 'right' }}
                                  label={t("Ok,Let'sGo")}
                                  variant="primary"
                                  onClick={() => setStage1(STAGES.CONFIG_QUESTIONAIRE)}
                                />
                              </div>
                            </div>
                          </Fragment>)) : (hnInsightsState.stage === STAGES.INSIGHTS_LIST ?
                            <Fragment>
                              <h3 className="questionaire__headline">{t("Analysis complete!")}</h3>
                              <p className="questionaire__info-text">
                                {t("BasedOnYourCurrentReproductionPerformanceAndYourAnswers,TheseAreTheThreeMostIimportant.")}
                                <br />
                                {t("YouCanPinTheInsightsToTheDashboardOrYouCanDismissAnInsight,IfYouBelieveItIsIrrelevant.")}
                              </p>

                              <div className="questionaire__container">
                                <div className="insight-Wrapper">
                                  <div className="insight-Wrapper">
                                    <Grid xs={12}>
                                      {ListOfInsightsComponent()}
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                            : (hnInsightsState.insights.findIndex(x => x.expired === 1) != -1 ? (
                              <Fragment>
                                <div className="questionaire__container">
                                  <h3 className="questionaire__headline">{t("WelcomeBack!")}</h3>
                                  <p className="questionaire__info-text">{t("YourInsightsFromLastSessionAreNoLongerUp-To-Date.")}</p>
                                  <p className="questionaire__info-text">{t("PleaseGoThroughTheAnalysisAgainToGetInsightsBasedOnYourMostRecentEeproductionPerformance.")}</p>
                                  <div className="insight-Wrapper">
                                  </div>
                                  <br />
                                  {renderQuestionaireButtons}
                                </div>
                              </Fragment>
                            ) : hnInsightsState.insights.length > 0 ? (
                              <Fragment>
                                <div className="questionaire__container">
                                  <h3 className="questionaire__headline">{t("WelcomeBack!")}</h3>
                                  <p className="questionaire__info-text">{t("These are your insights from last time")}</p>
                                  <div className="insight-Wrapper">
                                    <Grid xs={12}>
                                      {ListOfInsightsComponent()}
                                    </Grid>
                                  </div>
                                  <br />
                                  <p className="questionaire__insight-body">
                                    {t("DoYouWantToGoThroughTheAnalysisAgainAndSeeInsightsBasedOnYourMostRecentReproductionPerformance?")}
                                  </p>
                                  {renderQuestionaireButtons}
                                </div>
                              </Fragment>
                            ) :
                              <Fragment>
                                <div className="questionaire__container">
                                  <h3 className="questionaire__headline">
                                    {t("ManagementAndFarmSetupCompleted")}
                                  </h3>
                                  <p className="questionaire__info-text">
                                    {parse(t("Awesome,YouHaveAnsweredYourFarmManagementAndSetupQuestions.NowWeCanProceedToTheQuestionsWhichWillHelpUsTowardsIncreasingYourCows'ReproductionPerformance-BasedOnTheirCurrentPerformance."))}
                                  </p>
                                  <p className="questionaire__insight-body">
                                    {parse(t("YouCanChoseBetweenA<b>QuickSession</b>(~5-10 minutes)OrA<b>FullSession</b>(~20-30 minutes).WithinEachSessionYouWillBeAskedANumberOfQuestionsAboutTheCurrentSituationOfTheCowsOnYourFarm.ForExampleRelatedToBodyCondition,DiseaseOccurrenceEtc."))}
                                    <br />
                                    <br />
                                    {parse(t("InTheQuickSessionYouWillBeAskedASmallNumberOfQuestions,WhileInTheFullSessionYouWillBeAskedALargerNumberOfQuestions."))}
                                    <br />
                                    <br />
                                    <i>
                                      {parse(t("PleaseBeQccurateInYourReplies.TheMoreAccurateYourAnswersAre,TheBetterWeCanQuideYouTowardsWhereToFocusToImprove."))}
                                    </i>
                                  </p>
                                  {renderQuestionaireButtons}
                                </div>
                              </Fragment>
                            ))}
                        <Modal
                          visible={hnInsightsState.stage === STAGES.CONFIG_QUESTIONAIRE || hnInsightsState.stage === STAGES.ANALYSIS_QUESTIONAIRE}
                          close={() => hnInsightsState.stage === STAGES.CONFIG_QUESTIONAIRE ? setStage1(STAGES.CONFIG_INTRO) : setStage1(STAGES.ANALYSIS_INTRO)}
                          headline={t("ReproductionInsights")}
                          width={75}
                        >
                          <Fragment>
                            <div className="questionaire__container">
                              <h3 className="questionaire__headline">
                                {(t(hnInsightsState.activeQuestion.key) as QuestionText).question}
                              </h3>
                              <p className="questionaire__info-text">
                                {(t(hnInsightsState.activeQuestion.key) as QuestionText).text}
                              </p>
                              <Progressbar
                                progress={hnInsightsState.questions.answered.length}
                                steps={
                                  hnInsightsState.questions.open.length +
                                  hnInsightsState.questions.answered.length
                                }
                              />
                              <div className="questionaire__answers-list">
                                {hnInsightsState.activeQuestion.answerOptions.map(
                                  (el: IHNInsightsAnswer, index: number) => {
                                    const isSelected =
                                      hnInsightsState.selectedAnswer.answer.key === el.key;

                                    return (
                                      <div
                                        className="questionaire__answer"
                                        key={`answer-${index}`}
                                        onClick={() => handleSelectAnswer(el)}
                                      >
                                        <div
                                          className={`questionaire__answer-box ${isSelected ? "questionaire__answer-box--selected" : ""
                                            }`}
                                        />
                                        <span className="questionaire__answer-text">
                                          {t(el.key as string)}
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="questionaire__actions">
                                <Button
                                  onClick={handleGoToPreviousQuestion}
                                  label={t("Previous")}
                                  variant="secondary"
                                  disabled={hnInsightsState.questions.answered.length === 0}
                                />
                                <Button
                                  onClick={handleGoToNextQuestion}
                                  label={t("Next")}
                                  variant="primary"
                                  disabled={hnInsightsState.selectedAnswer.answer.key === null || hnInsightsState.selectedAnswer.answer.key === undefined}
                                />
                              </div>
                            </div>
                          </Fragment>
                        </Modal>
                      </Fragment>
                    )}
                  </div>
                </WidgetContent>
              </Widget>
            </Grid>
          </Grid>
          <Grid xs={12} lg={6} direction="column">
            <Grid>
              <Grid
                spacingBottom={2}
              >
                <CurrentReproductionPerformance
                  toggleId={BASELINE_REPORT_TOGGLE}
                  simple={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Fragment>
  );
};

export default InsightsPage;
