import React from "react";
import {
  AIS,
  BaselineReport,
  CRBWP,
  Dashboard,
  DOA,
  HNIR,
  OD,
  PBSS,
  SEA,
  StickConsumption,
  Insights,
} from "./Pages";
import store from "./redux/store";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "./Translations/Translations";
import LayoutRoute from "./Layout/Route";
import { muiCore, theme } from "lattec-ui-library";
import buildInfoJson from '../buildInfo.json';
import packageJson from '../package.json';
import { Clarity } from './utills/Clarity';

export default function App() {
  let lng = "en";

  //@ts-ignore
  const [farmId] = React.useState(window?.orbit?.selectedFarm?.farmId)

  //@ts-ignore
  if (window?.orbit?.settings?.language) {
    //@ts-ignore
    lng = orbit.settings.language
  }

  React.useEffect(() => {
    if (!window.orbit) return;
    window.addEventListener("orbit", () => {
      //@ts-ignore
      const newFarmId = orbit.selectedFarm.farmId

      if (newFarmId !== farmId) {
        window.location.reload();
      }
    })
  }, [])

  i18n.use(initReactI18next).init({
    // we init with resources
    resources: translations,
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    supportedLngs: [
      "bg-BG",
      "cs-CZ",
      "da-DK",
      "de-DE",
      "el-GR",
      "es-ES",
      "et-EE",
      "fi-FI",
      "fr-CA",
      "fr-FR",
      "hu-HU",
      "is-IS",
      "it-IT",
      "ja-JP",
      "ko-KR",
      "lt-LT",
      "lv-LV",
      "nb-NO",
      "nl-NL",
      "pl-PL",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "sl-SI",
      "sr-RS",
      "sv-SE",
      "tr-TR",
      "uk-UA",
      "vi-VN",
      "zh-CN",
      "zh-TW",
      "en"
    ],
    lng,

    load: "currentOnly",

    keySeparator: false, // we use content as keys

    returnObjects: true,

    interpolation: {
      escapeValue: false,
    },
  });

  if (buildInfoJson && packageJson) {
    console.log("App.version " + packageJson.version);
    console.log("Git hash    " + buildInfoJson.gitHash);
    console.log("Build time  " + buildInfoJson.buildTime);
  }


  return (
    <muiCore.ThemeProvider theme={theme}>
      <Clarity />
      <React.Fragment>
        <muiCore.CssBaseline>
          <Provider store={store}>
            <Router basename={"reproductionperformancerepro"}>
              <Switch>
                <LayoutRoute path="/stick-consumption" component={StickConsumption} />
                <LayoutRoute path="/baseline-report" component={BaselineReport} />
                <LayoutRoute path="/insights" component={Insights} />
                <LayoutRoute path="/doa" component={DOA} />
                <LayoutRoute path="/cr" component={CRBWP} />
                <LayoutRoute path="/hnir" component={HNIR} />
                <LayoutRoute path="/ais" component={AIS} />
                <LayoutRoute path="/sea" component={SEA} />
                <LayoutRoute path="/pbss" component={PBSS} />
                <LayoutRoute path="/od" component={OD} />
                <LayoutRoute path="/" component={Dashboard} />
              </Switch>
            </Router>
          </Provider>
        </muiCore.CssBaseline>
      </React.Fragment>
    </muiCore.ThemeProvider>
  );
}
