import { SELECT_DATA_TME_PERIOD, SELECT_LACTATION_FILTERS, SELECT_DATA_TME_START, } from "./constants";

export interface ItimePeriod {
  value: string;
  key: string;
  days: number;
}

export const timePeriods: ItimePeriod[] = [{
  value: "60 days",
  key: "60 days",
  days: 60,
}, {
  value: "90 days",
  key: "90 days",
  days: 90,
}, {
  value: "180 days",
  key: "180 days",
  days: 180,
}, {
  value: "365 days",
  key: "365 days",
  days: 365,
}];

export const getIndexFromKey = (key: string): number => {
  return timePeriods.findIndex(x => x.key === key);
}

export interface IlactationFilters {
  l1Checked: boolean;
  l2Checked: boolean;
  l3Checked: boolean;
  lAllChecked: boolean;
  culledChecked: boolean;
  delayedChecked: boolean;
  i1Checked: boolean;
  i2Checked: boolean;
  i3Checked: boolean;
  i4Checked: boolean;
}

export interface IFilterState {
  anoestrusLengthTimePeriods: ItimePeriod[];
  anoestrusLengthSelectedTimePeriod: ItimePeriod;
  firstLutealPhaseTimePeriods: ItimePeriod[];
  firstLutealPhaseSelectedTimePeriod: ItimePeriod;
  today: Date;
  lactationFilters: IlactationFilters;
};

export const initialState: IFilterState = {
  anoestrusLengthTimePeriods: timePeriods,
  anoestrusLengthSelectedTimePeriod: timePeriods[0],
  firstLutealPhaseTimePeriods: timePeriods,
  firstLutealPhaseSelectedTimePeriod: timePeriods[0],
  today: new Date(),
  lactationFilters: {
    l1Checked: true,
    l2Checked: true,
    l3Checked: true,
    lAllChecked: true,
    culledChecked: true,
    delayedChecked: false,
    i1Checked: true,
    i2Checked: true,
    i3Checked: true,
    i4Checked: true,
  },
};

export const FiltersReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SELECT_DATA_TME_PERIOD: {
      return {
        ...state,
        [`${action.payload.key}SelectedTimePeriod`]: {
          key: state[`${action.payload.key}TimePeriods`][action.payload.index].key,
          value: state[`${action.payload.key}TimePeriods`][action.payload.index].value,
          days: state[`${action.payload.key}TimePeriods`][action.payload.index].days,
        },
      };
    }

    case SELECT_LACTATION_FILTERS: {
      return {
        ...state,
        lactationFilters: {
          ...state.lactationFilters,
          [`${action.payload.key}Checked`]: !state.lactationFilters[`${action.payload.key}Checked`],
        },
      };
    }

    case SELECT_DATA_TME_START: {
      return {
        ...state,
        today: action.payload.today,
        [`${action.payload.key}SelectedTimePeriod`]: {
          key: state[`${action.payload.key}TimePeriods`][action.payload.index].key,
          value: state[`${action.payload.key}TimePeriods`][action.payload.index].value,
          days: state[`${action.payload.key}TimePeriods`][action.payload.index].days,
        },
      }
    }
    default:
      return state;
  }
};
