import React, { Fragment } from "react";
import "../Input.scss";
import { iButton } from "./interface";

const InputButton: React.FC<iButton> = (props) => {
    let classes = "input__button " +
        `${!props.type && !props.children ? "input__button--regular " : ""} ` +
        `${props.type ? ('input__button--' + props.type) + ' ' : ''}` +
        `${props.disabled ? "input__button--disabled " : ""} ` +
        `${props.iconButton ? "input__button--icon-button " : ""} ` +
        `${(props.hovered && props.type === "data-point") ? "input__button-data-point--hovered" : props.hovered ? "input__button--hovered " : ""} ` +
        `${props.invert ? "input__button--invert " : ""} ` +
        `${props.children && (props.invisible === true || props.invisible == null) ? "input__button--invisible " : ""} ` +
        `${props.disableRipple ? "input__button--disableRipple " : ""} ` +
        `${props.variant} `


    return (
        <button
            className={classes}
            onClick={props.onClick}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
            disabled={props.disabled}
            style={props.style}
            id={props.id}
        >
            {props.isLoading ? (
                <div>
                    <i className="fas fa-spinner fa-pulse" /> <span style={{ marginLeft: "9px" }}>Loading</span>
                </div>
            ) : (
                    <Fragment>
                        {props.text ? props.text : props.children}
                    </Fragment>
                )}
        </button>
    );
};

export default InputButton;

// props.isLoading ? (<i className="fas fa-spinner fa-pulse" />) : 