import {
  GET_HNINSIGHTS_QUESTION_FAILURE,
  GET_HNINSIGHTS_QUESTION_REQUEST,
  GET_HNINSIGHTS_QUESTION_SUCCESS,
  GET_HNINSIGHTS_QUESTIONS_FAILURE,
  GET_HNINSIGHTS_QUESTIONS_REQUEST,
  GET_HNINSIGHTS_QUESTIONS_SUCCESS,
  GET_INSIGHTS_LIST_FAILURE,
  GET_INSIGHTS_LIST_REQUEST,
  GET_INSIGHTS_LIST_SUCCESS,
  GO_TO_PREVIOUS_QUESTION_REQUEST,
  GO_TO_PREVIOUS_QUESTION_SUCCESS,
  PIN_INSIGHT_TO_DASHBOARD_FAILURE,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_SUCCESS,
  POST_HNINSIGHTS_ANSWER_FAILURE,
  POST_HNINSIGHTS_ANSWER_REQUEST,
  POST_HNINSIGHTS_ANSWER_SUCCESS,
  REPLACE_INSIGHT_FAILURE,
  REPLACE_INSIGHT_REQUEST,
  REPLACE_INSIGHT_SUCCESS,
  SET_HNINSIGHTS_STAGE_SUCCESS,
  SET_HNINSIGHTS_STATUS,
  SET_SELECTED_ANSWER,
} from "./constants";

export const initialState: any = {
  gotData: false,
  loading: false,
  error: null,
  stage: null,
  insights: [],
  dbInsightsList: [],
  questions: {
    open: [],
    answered: [],
  },
  activeQuestion: {
    questionId: null,
    key: null,
    text: "",
    answerOptions: [],
    parentQuestionId: null,
  },
  currentStep: 0,
  status: null,
  selectedAnswer: {
    answer: {
      answerId: null,
      key: null,
      text: null,
    },
    questionId: null,
  },
};

export const HNInsightsReducer = (state: any = initialState, action: any) => { // (state: IState = initialState, action: IHNInsightsQuestionAction) => {
  switch (action.type) {
    case GET_HNINSIGHTS_QUESTION_REQUEST:
    case POST_HNINSIGHTS_ANSWER_REQUEST:
    case GET_HNINSIGHTS_QUESTIONS_REQUEST:
    case GO_TO_PREVIOUS_QUESTION_REQUEST:
    case PIN_INSIGHT_TO_DASHBOARD_REQUEST:
    case REPLACE_INSIGHT_REQUEST:
    case GET_INSIGHTS_LIST_REQUEST:
      return {
        ...state,
        gotData: false,
        loading: true,
        error: null,
      };

    case GET_HNINSIGHTS_QUESTIONS_SUCCESS:
      return {
        ...state,
        gotData: true,
        loading: false,
        questions: action.payload,
      };

    case GET_HNINSIGHTS_QUESTION_SUCCESS:
      return {
        ...state,
        gotData: true,
        loading: false,
        activeQuestion: {
          questionId: action.payload.questionId,
          key: action.payload.key,
          text: action.payload.text,
          answerOptions: action.payload.answer,
          parentQuestionId: action.payload.parentQuestionId,
        },
      };
    case REPLACE_INSIGHT_SUCCESS:
    case PIN_INSIGHT_TO_DASHBOARD_SUCCESS: {
      return {
        ...state,
        insights: action.payload.parsedInsights,
        dbInsightsList: action.payload.dbInsightsList,
        gotData: true,
        loading: false,
      };
    }

    case POST_HNINSIGHTS_ANSWER_SUCCESS: {
      const isSubQuestion = (state.questions.open.length > 0) && (state.activeQuestion.questionId !== state.questions.open[0].questionId);
      const answeredQuestion = isSubQuestion ? state.activeQuestion : state.questions.open[0];

      const newState = {
        ...state,
        loading: false,
        gotData: true,
        questions: {
          // dont change the open list on sub questions, since they are not in the open list
          open: isSubQuestion ? [
            ...state.questions.open,
          ] : [
            ...state.questions.open.slice(1, state.questions.open.length),
          ],
          // put either a sub question or a parent question into answered
          // that way, we can later go back to it
          answered: answeredQuestion ? [
            ...state.questions.answered,

            { ...answeredQuestion },
          ] : [...state.questions.answered],
        },

        selectedAnswer: initialState.selectedAnswer,
        currentStep: state.currentStep + 1,
      };

      // it might be that the sub question is only seen in the activeQuestionList
      // so if its a sub question throw it into the answered array
      return newState;
    }

    case GO_TO_PREVIOUS_QUESTION_SUCCESS: {
      const newState = {
        ...state,
        gotData: true,
        loading: false,
        currentStep: state.currentStep - 1,
        activeQuestion: {
          questionId: action.payload.questionId,
          key: action.payload.key,
          text: action.payload.text,
          answerOptions: action.payload.answer,
        },
        questions: {
          ...state.questions,
          answered: [
            ...state.questions.answered.slice(0, -1),
          ],
          open: [{
            key: action.payload.key,
            questionId: action.payload.questionId,
            text: action.payload.text,
          },
          ...state.questions.open,
          ],
        },

        // if analytical questions you can only go back in this specific questionaire
        // you can only go back in the current session and not what you answered last week
      };

      return newState;
    }

    case SET_SELECTED_ANSWER: {
      return {
        ...state,
        selectedAnswer: {
          answer: {
            answerId: action.payload.answerId,
            key: action.payload.key,
            text: action.payload.text,
          },
          questionId: action.payload.questionId,
        },
      };
    }

    case GET_INSIGHTS_LIST_SUCCESS: {
      return {
        ...state,
        insights: action.payload,
        dbInsightsList: action.dbInsightsList,
        gotData: true,
        loading: false,
      };
    }

    case SET_HNINSIGHTS_STAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        stage: action.payload,
        currentStep: 0,
      };

    case SET_HNINSIGHTS_STATUS: {
      return {
        ...state,
        status: action.payload
      }
    }

    case POST_HNINSIGHTS_ANSWER_FAILURE:
    case GET_HNINSIGHTS_QUESTION_FAILURE:
    case GET_INSIGHTS_LIST_FAILURE:
    case REPLACE_INSIGHT_FAILURE:
    case PIN_INSIGHT_TO_DASHBOARD_FAILURE:
    case GET_HNINSIGHTS_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
