import { Button, Datatable as Dt, Tooltips, SkeletonLoader } from "lattec-ui-library"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { iDataTable } from "./interface";
import { formatTableData, getDataRelation } from './helpers'
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_OPTIONS_REQUEST } from "../../redux/UserOptions/constants";
import { formatDateNational } from "../../utills/dateFormatting";
import { SET_LIBRARY_OPEN_URL, SET_CRP_DB_HOVERED } from "../../redux/Navigation/constants";

const DataTable: React.FC<iDataTable> = (props) => {
  const { t } = useTranslation();
  const userOptionsState = useSelector((state: any) => state.userOptionsState);
  const baselineReportState = useSelector((state: any) => state.baselineReportState);
  const dispatch = useDispatch();
  const selectedElement = React.useRef<HTMLElement | undefined>(undefined);

  const simpleHeaders = ['KPIName', 'Difference', 'DiffNum'];
  const allHeaders = ['Pin', 'KPIName', 'BaselineStart', 'Today', 'Difference', 'DiffNum'];


  useEffect(() => {
    const handleClickOutsideTable = (event: any) => {
      if (
        typeof event.target.className === "string" &&
        !event.target.className.includes("MUIDataTable")
      ) {
        if (selectedElement.current)
          selectedElement.current.style.background = "";
        selectedElement.current = undefined;

        dispatch({ type: SET_CRP_DB_HOVERED, payload: [""] });
      }
    };

    document.addEventListener("mousedown", handleClickOutsideTable, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideTable);
    };

    // tell hook to skip re-render if state hasnt changed
  }, [dispatch]);

  const handlePinToDashboard = (kpi: string) => {
    const payload = { ...userOptionsState }
    payload.baselineReport[kpi] = !userOptionsState.baselineReport[kpi]
    dispatch({ type: SET_USER_OPTIONS_REQUEST, payload });
  };

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const handleRowHover = (kpi: string) => {
    handleHoverRelation(kpi);
  }

  const handleHoverRelation = (kpi: string) => {
    if (selectedElement.current) return;

    const hoveredKpiList = getDataRelation(kpi);
    dispatch({ type: SET_CRP_DB_HOVERED, payload: hoveredKpiList });
  };

  const handleRowClicked = (kpi: string, elem: undefined | HTMLElement) => {
    handleHoverRelation(kpi);

    if (selectedElement.current) selectedElement.current.style.background = "";
    if (elem) elem.style.backgroundColor = "#E4F3FB";

    selectedElement.current = elem;
  }

  const columns = (props.simple ? simpleHeaders : allHeaders).map((name, index) => {
    let label: string | JSX.Element = <div></div>;
    const header = props.headers[index];
    if (header === "Pin" || header === "_" || header === "KPI" || header === "") {
      label = <div></div>;
    } else if (header === "BaselineStart" && props.isOwnFarm) {
      label = (
        <>{t(header)}
          {baselineReportState.data[0] &&
            <><br /> {formatDateNational(new Date(baselineReportState.data[0].StartingPointDate))} </>}
        </>
      )
    } else if (header === "BaselineStart" && !props.isOwnFarm) {
      label =
        <>{t("Benchmark farms")}</>
    } else if (header === "DiffNum") {
      label = (
        <Tooltips content={t("GoToLibrary").toString()} position="bottom">
          <Button
            variant="text"
            onClick={() => handleSetUrl("baseline/lvl1/summary")}
            className="infoButton"
            icon={{ variant: "fas", fontAwesomeName: "book", size: 18 }}
          />
        </Tooltips>);
    } else {
      label = t(props.headers[index]);
    }
    return {
      name,
      label,
      options: {
        filter: false,
        sort: false,
        customHeadRender: props.headers[index] ? undefined : () => null, // Removes thead row from table
      },
    };
  })

  let data: any[] = [];

  if (props.data.length > 0) {
    data = formatTableData(
      props.data,
      userOptionsState,
      t,
      props.isOwnFarm,
      (kpiName) => handlePinToDashboard(kpiName)
    );
  }

  return (baselineReportState.loading ?
    <SkeletonLoader type="paragraph" isStatic={true}></SkeletonLoader> :
    <Dt
      zebra={true}
      paperBorder={props.paper}
      //@ts-ignore
      columns={columns}

      data={data}
      options={{
        pagination: false,
        search: false,
        print: false,
        download: false,
        filter: false,
        selectableRows: "none",
        viewColumns: false,
        onRowClick: (arr: any, meta: { dataIndex: number }, e: any) => {
          handleRowClicked(
            baselineReportState?.data?.[meta.dataIndex]?.KPIName,
            e.target?.closest("tr")
          );
        },
        setRowProps: (row: any, dataIndex: any, rowIndex: number) => {
          return {
            onMouseEnter: () =>
              handleRowHover(baselineReportState?.data?.[rowIndex]?.KPIName),
            onMouseLeave: () => handleRowHover("")
          };
        },
      }}
    />
  )
}

export default DataTable;