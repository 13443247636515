import React from 'react';
import { iGetStarted } from "./interface"
import { useTranslation } from "react-i18next";
import { muiCore, Widget, WidgetHeader, WidgetContent, Button, Typography, Icon, Grid } from 'lattec-ui-library'

const GetStartedModal: React.FC<iGetStarted> = (props) => {
  const { t } = useTranslation();
  const Dialog = muiCore.Dialog;
  const [currentVideo, setCurrentVideo] = React.useState<null | string>(null);


  const handleCloseModal = () => {
    if (currentVideo) setCurrentVideo(null);
    else props.onClose();
  }

  const renderVideo = () => {
    return (
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${currentVideo}?cc_load_policy=1&autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <>
        <Grid container direction="column">
          <Grid item style={{ paddingTop: '5px' }}>
            <Button
              variant="text"
              icon={{ variant: "lattec", lattecName: "Current_reproduction_perforamance", size: 32, colors: { fillColor: 'rgb(113, 113, 113)' }, position: "left", style: { marginRight: '15px' } }}
              label={t("HowIsMyCurrentReproductionPerformance?")}
              onClick={() => setCurrentVideo("k5O0X8rtmIk")}
            />
          </Grid>
          <Grid item style={{ paddingTop: '5px' }}>
            <Button
              variant="text"
              icon={{ variant: "fal", fontAwesomeName: "lightbulb-on", size: 32, colors: { fillColor: 'rgb(113, 113, 113)' }, position: "left", style: { marginRight: '15px' } }}
              label={t("HowCanIImproveMyReproductionPerformance?")}
              onClick={() => setCurrentVideo('HowCanIImproveMyReproductionPerformance')}
            />
          </Grid>
          <Grid item style={{ paddingTop: '5px' }}>

            <Button
              variant="text"
              icon={{ variant: "lattec", lattecName: "StickConsumption", size: 32, colors: { fillColor: 'rgb(113, 113, 113)' }, position: "left", style: { marginRight: '15px' } }}
              label={t("AmISpendingTooManySticks?")}
              onClick={() => setCurrentVideo('9NbOlbuydiw')}
            />
          </Grid>
          <Grid item style={{ paddingTop: '5px' }}>

            <Button
              variant="text"
              icon={{ variant: "fal", fontAwesomeName: "chart-line", size: 32, colors: { fillColor: 'rgb(113, 113, 113)' }, position: "left", style: { marginRight: '15px' } }}
              label={t("DidMyReproductionPerformanceImproveAfterBuyingHN?")}
              onClick={() => setCurrentVideo('4pIQALMtrhc')}
            />
          </Grid>

          <Grid item style={{ paddingTop: '5px' }}>
            <Button
              variant="text"
              icon={{ variant: "lattec", lattecName: "StickConsumption", size: 32, colors: { fillColor: 'rgb(113, 113, 113)' }, position: "left", style: { marginRight: '15px' } }}
              label={t("I’mNotSureWhereToStart")}
              onClick={() => setCurrentVideo('8Nl1MszDOlw')}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Dialog open={props.open} onClose={handleCloseModal} fullWidth={false} maxWidth={'md'} >
      <Widget>
        <WidgetHeader title={t("GetStartedSubHeader")} action={<Button variant="text" icon={{ variant: 'fal', fontAwesomeName: "times" }} onClick={handleCloseModal} />} />
        <WidgetContent>
          {currentVideo != null ? renderVideo() : renderButtons()}
        </WidgetContent>
      </Widget>
    </Dialog>
  )
}

export default GetStartedModal;