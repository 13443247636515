import React, { useEffect, useRef, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collabsible from "../../Collabsible";
import packageJson from '../../../package.json';

import {
  SET_LIBRARY_OPEN_URL,
  TOGGLE_NOTIFICATION_BAR,
} from "../../redux/Navigation/constants";
import scrollIntoView from "scroll-into-view-if-needed";
import "../Layout.scss";
import DoaChart from "../../Pages/KPIs/KPICharts/DOAChart";
import AisChart from "../../Pages/KPIs/KPICharts/AISChart";
import SeaChart from "../../Pages/KPIs/KPICharts/SEAChart";
import HnirChart from "../../Pages/KPIs/KPICharts/HNIRChart";
import CrbwpChart from "../../Pages/KPIs/KPICharts/CRBWPChart";
import OdChart from "../../Pages/KPIs/KPICharts/ODChart";
import PbssChart from "../../Pages/KPIs/KPICharts/PBSSChart";
import { Icon } from "lattec-ui-library";

import { renderToString } from 'react-dom/server';
import { useTranslation } from "react-i18next";
import './style.scss'

const LibrarySidebar: React.FC = (props) => {
  const itemRefs = {};
  const navigationState = useSelector((state: any) => state.navigationState);
  const targetRef = useRef<HTMLHeadingElement>(null);
  const [editing, setEditing] = React.useState(false);

  const dispatch = useDispatch();

  const getSvgResized = (element: ReactElement): string => {
    return renderToString(element).replace("viewBox=\"770 450 385 185\"", "viewBox=\"720 450 485 165\"")
  }

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });
  const handleCloseCollabsible = () => {
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: "" });
  };


  const { t } = useTranslation();

  const classNameBackground = "layout__notification-bar-background";

  const handleIsOpen = (arr: string[]) => {
    return arr.indexOf(navigationState.libraryOpenUrl) > -1;
  };

  const handleClickOutside = (event: any) => {
    if (event.composedPath()[0].className === classNameBackground) {
      dispatch({ type: TOGGLE_NOTIFICATION_BAR, payload: null });
    }
  };

  useEffect(() => {
    if (
      itemRefs[navigationState.libraryOpenUrl] !== null &&
      itemRefs[navigationState.libraryOpenUrl] !== undefined
    ) {
      if (editing === false) {
        scrollIntoView(itemRefs[navigationState.libraryOpenUrl], {
          behavior: "smooth",
          scrollMode: "always",
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={targetRef} className="layout__notification-bar-wrapper">
      <div className={classNameBackground} />
      <div className="layout__notification-bar">
        <Collabsible
          ref={(el) => (itemRefs["crp/lvl1/summary"] = el)}
          onClose={handleCloseCollabsible}
          open={true}
          hideIcon={true}
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          title={t("CurrentReproductionPerformance")}
          icon={<Icon lattecName="KPI" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}

          level={1}
          version={packageJson.version}
        >
          <div className="libraryText" dangerouslySetInnerHTML={{ __html: t("Library->CurrentReproductionPerformance") }} />

          {/* ANOESTRUS LENGTH KPI */}
          <Collabsible
            ref={(el) => (itemRefs["doa/lvl2/summary"] = el)}
            onClose={handleCloseCollabsible}
            open={handleIsOpen([
              "doa/lvl2/summary",
              "doa/lvl3/al/summary",
              "doa/lvl3/flp/summary",
            ])}
            onClick={() => handleSetUrl("doa/lvl2/summary")}
            title={t("ReProKPI") + ": " + t("AnoestrusLength")}
            level={2}

            icon={<Icon lattecName="DOA" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->CurrentReproductionPerformanceAnoestrusLengthPart1")
                + getSvgResized(DoaChart())
                + t("Library->CurrentReproductionPerformanceAnoestrusLengthPart2")
            }} />
          </Collabsible>

          {/* CYCLE REGULARITY */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen([
              "cr/lvl2/summary",
              "cr/lvl3/cl/summary",
              "cr/lvl3/fpl/summary",
            ])}
            onClick={() => handleSetUrl("cr/lvl2/summary")}
            ref={(el) => (itemRefs["cr/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("CycleRegularity")}
            level={2}

            icon={<Icon lattecName="CR" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->CyclerRegularityPart1")
                + getSvgResized(CrbwpChart())
                + t("Library->CyclerRegularityPart2")
            }} />

          </Collabsible>

          {/* HN INSEMINATION RATE */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen([
              "hnir/lvl2/summary",
              "hnir/lvl3/iodim/summary",
              "hnir/lvl3/havwp/summary",
            ])}
            onClick={() => handleSetUrl("hnir/lvl2/summary")}
            ref={(el) => (itemRefs["hnir/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("HNInseminationRate")}
            level={2}

            icon={<Icon lattecName="HNIR" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->HNInseminationRatePart1")
                + getSvgResized(HnirChart())
                + t("Library->HNInseminationRatePart2")
            }} />

          </Collabsible>

          {/* INSEMINATION SUCCESS */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["ais/lvl2/summary", "ais/lvl3/isodim/summary"])}
            onClick={() => handleSetUrl("ais/lvl2/summary")}
            ref={(el) => (itemRefs["ais/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("InseminationSuccess")}
            level={2}

            icon={<Icon lattecName="AIS" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->InseminationSuccesPart1")
                + getSvgResized(AisChart())
                + t("Library->InseminationSuccesPart2")
            }} />
          </Collabsible>

          {/* EMBRYO ATTACHMENT */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen([
              "sea/lvl2/summary",
              "sea/lvl3/eaaooeed/summary",
            ])}
            onClick={() => handleSetUrl("sea/lvl2/summary")}
            ref={(el) => (itemRefs["sea/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("EmbryoAttachment")}
            level={2}

            icon={<Icon lattecName="SEA" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->EmbryoAttachmentPart1")
                + getSvgResized(SeaChart())
                + t("Library->EmbryoAttachmentPart2")
            }} />
          </Collabsible>

          {/* FINAL ESTABLISHMENT OF PREGNANCY */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen([
              "pbss/lvl2/summary",
              "pbss/lvl3/feopaooea/summary",
            ])}
            onClick={() => handleSetUrl("pbss/lvl2/summary")}
            ref={(el) => (itemRefs["pbss/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("FinalEstablishmentOfPregnancy")}
            level={2}

            icon={<Icon lattecName="PBSS" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->FinalEstablishmentOfPregnancyPart1")
                + getSvgResized(PbssChart())
                + t("Library->FinalEstablishmentOfPregnancyPart2")
            }} />
          </Collabsible>

          {/* PROJECTED DAYS OPEN */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["od/lvl2/summary"])}
            onClick={() => handleSetUrl("od/lvl2/summary")}
            ref={(el) => (itemRefs["od/lvl2/summary"] = el)}
            title={t("ReProKPI") + ": " + t("ProjectedDaysOpen")}
            level={2}

            icon={<Icon lattecName="OD" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->ProjectedDaysOpenPart1")
                + getSvgResized(OdChart())
                + t("Library->ProjectedDaysOpenPart2")
            }} />
          </Collabsible>
        </Collabsible>

        <Collabsible
          ref={(el) => (itemRefs["pds/lvl1/summary"] = el)}
          onClose={handleCloseCollabsible}
          open={true}
          hideIcon={true}
          onClick={() => handleSetUrl("pds/lvl1/summary")}
          title={t("ProportionOfDelayedSamples")}
          icon={<Icon fontAwesomeName="bell-exclamation" variant="fal" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          level={1}
        >
          <div className="libraryText" dangerouslySetInnerHTML={{ __html: t("Library->ProportionOfDelayedSamples") }} />
        </Collabsible>

        {/* Stick consumption */}
        <Collabsible
          ref={(el) => (itemRefs["stick-consumption/lvl1/summary"] = el)}
          onClose={handleCloseCollabsible}
          open={true}
          hideIcon={true}
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          title="Stick consumption"
          icon={<Icon lattecName="StickConsumption" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          level={1}
        >

          <div className="libraryText" dangerouslySetInnerHTML={{
            __html:
              t("Library->StickConsumption")
          }} />

          {/* Averages sticks per pregnancy */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["averages-sticks-per-pregnancy/lvl2/summary"])}
            onClick={() => handleSetUrl("averages-sticks-per-pregnancy/lvl2/summary")}
            ref={(el) => (itemRefs["averages-sticks-per-pregnancy/lvl2/summary"] = el)}
            title={t("SticksUsedPrPregnancy")}
            level={2}
            icon={<Icon lattecName="StickConsumption" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AveragesSticksPerPregnancy")
            }} />
          </Collabsible>

          {/* Average sticks from first to last AI */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["average-sticks-from-first-to-last-AI/lvl2/summary"])}
            onClick={() => handleSetUrl("average-sticks-from-first-to-last-AI/lvl2/summary")}
            ref={(el) => (itemRefs["average-sticks-from-first-to-last-AI/lvl2/summary"] = el)}
            title={t("SticksUsedFromFirstToLastAI")}
            level={2}
            icon={<Icon lattecName="StickConsumption" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AverageSticksFromFirstToLastAI")
            }} />
          </Collabsible>

          {/* Average sticks used on follicular cysts */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["average-sticks-used-on-follicular-cysts/lvl2/summary"])}
            onClick={() => handleSetUrl("average-sticks-used-on-follicular-cysts/lvl2/summary")}
            ref={(el) => (itemRefs["average-sticks-used-on-follicular-cysts/lvl2/summary"] = el)}
            title={t("SticksUsedOnFollicularCysts")}
            level={2}
            icon={<Icon lattecName="StickConsumption" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AverageSticksUsedOnFollicularCysts")
            }} />
          </Collabsible>

          {/* Average sticks used on luteal cysts */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["average-sticks-used-on-luteal-cysts/lvl2/summary"])}
            onClick={() => handleSetUrl("average-sticks-used-on-luteal-cysts/lvl2/summary")}
            ref={(el) => (itemRefs["average-sticks-used-on-luteal-cysts/lvl2/summary"] = el)}
            title={t("SticksUsedOnLutealCysts")}
            level={2}
            icon={<Icon lattecName="StickConsumption" variant="lattec" size="15" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AverageSticksUsedOnLutealCysts")
            }} />
          </Collabsible>
        </Collabsible>

        {/* Baseline data */}
        <Collabsible
          ref={(el) => (itemRefs["baseline/lvl1/summary"] = el)}
          onClose={handleCloseCollabsible}
          open={true}
          hideIcon={true}
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          title={t("BaselineReport")}
          icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          level={1}
        >
          <div className="libraryText" dangerouslySetInnerHTML={{
            __html:
              t("Library->BaselineReport")
          }} />

          {/* PROPORTION OF COWS BRED [%] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["pocb/lvl2/summary"])}
            onClick={() => handleSetUrl("pocb/lvl2/summary")}
            ref={(el) => (itemRefs["pocb/lvl2/summary"] = el)}
            title={t("ProportionOfCowsBred")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->ProportionOfCowsBred[%]")
            }} />

          </Collabsible>

          {/* Calving to first AI interval [days] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["ctfaii/lvl2/summary"])}
            onClick={() => handleSetUrl("ctfaii/lvl2/summary")}
            ref={(el) => (itemRefs["ctfaii/lvl2/summary"] = el)}
            title={t("CalvingToFirstAIInterval")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->CalvingToFirstAIInterval[days]")
            }} />
          </Collabsible>

          {/* First to last AI interval [days] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["ftlaii/lvl2/summary"])}
            onClick={() => handleSetUrl("ftlaii/lvl2/summary")}
            ref={(el) => (itemRefs["ftlaii/lvl2/summary"] = el)}
            title={t("FirstToLastAIInterval")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->FirstToLastAIInterval[days]")
            }} />
          </Collabsible>

          {/* AIs per cow */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["aipc/lvl2/summary"])}
            onClick={() => handleSetUrl("aipc/lvl2/summary")}
            ref={(el) => (itemRefs["aipc/lvl2/summary"] = el)}
            title={t("AIsPrCow")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AIsPerCow")
            }} />
          </Collabsible>

          {/* Insemination Rate [%] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["ir/lvl2/summary"])}
            onClick={() => handleSetUrl("ir/lvl2/summary")}
            ref={(el) => (itemRefs["ir/lvl2/summary"] = el)}
            title={t("InseminationRate")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->InseminationRate[%]")
            }} />
          </Collabsible>

          {/* AIs per pregnancy */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["aipp/lvl2/summary"])}
            onClick={() => handleSetUrl("aipp/lvl2/summary")}
            ref={(el) => (itemRefs["aipp/lvl2/summary"] = el)}
            title={t("AIsPrPregnancy")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >

            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->AIsPerPregnancy")
            }} />
          </Collabsible>

          {/* Pregnancy rate [%] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["pr/lvl2/summary"])}
            onClick={() => handleSetUrl("pr/lvl2/summary")}
            ref={(el) => (itemRefs["pr/lvl2/summary"] = el)}
            title={t("PregnancyRate")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->PregnancyRate[%]")
            }} />
          </Collabsible>

          {/* Days open [days] */}
          <Collabsible
            onClose={handleCloseCollabsible}
            open={handleIsOpen(["do/lvl2/summary"])}
            onClick={() => handleSetUrl("do/lvl2/summary")}
            ref={(el) => (itemRefs["do/lvl2/summary"] = el)}
            title={t("DaysOopenDays")}
            level={2}
            icon={<Icon variant="fal" fontAwesomeName="chart-line" colors={{ fillColor: "#C8C8C8" }} />}
          >
            <div className="libraryText" dangerouslySetInnerHTML={{
              __html:
                t("Library->DaysOpen[days]")
            }} />
          </Collabsible>
        </Collabsible>
      </div>
    </div >
  );
};

export default LibrarySidebar;
