import { BarChart } from "../../../Charts";
import { IFilterState, } from "../../../redux/Filters/reducer";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_COWS_LIST_REQUEST,
  HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
} from "../../../redux/CowsList/constants";
import { SELECT_LACTATION_FILTERS, } from "../../../redux/Filters/constants";
import CrbwpChart from "../KPICharts/CRBWPChart";
import { GROUP_3_COLOR_PALETTE } from "../../../styles/ColorCodes";

const CRBWP: React.FC = () => {
  const { t } = useTranslation();

  const path = "crbwp";

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);

  const navigationState = useSelector((state: any) => state.navigationState);

  const dispatch = useDispatch();
  const keys = ["CycleLength", "State"];
  const filters: any = {};

  useEffect(() => {
    const startDate = new Date(filtersState.today);
    startDate.setDate(
      startDate.getDate() -
      filtersState.anoestrusLengthSelectedTimePeriod.days
    );
    filtersState.lactationFilters.culledChecked =
      filtersState.lactationFilters.l1Checked =
      filtersState.lactationFilters.l2Checked =
      filtersState.lactationFilters.l3Checked =
      true;
    filtersState.lactationFilters.delayedChecked = false;

    // get the cowlist
    dispatch({
      type: GET_COWS_LIST_REQUEST,
      payload: {
        filters,
        keys,
        path,
        startDate: startDate,
        stopDate: filtersState.today,
        lactationFilters: filtersState.lactationFilters,
      },
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]
  );

  const handleToggleLactationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return (
    <KpiLayout
      title={t("CycleRegularity")}
      subHeader={t(
        "EvaluatesHerdVariationInOestrusCyclesBasedOnTheNumberOfDaysBetweenHeatsInNonInseminatedCows."
      )}
      libraryUrl="cr/lvl2/summary"
      kpi="CR"
      chartImage={CrbwpChart}
      handleToggleLactationFilter={handleToggleLactationFilter}
      chartSlot1={[
        {
          title: t("CycleLength"),
          chart: (
            <BarChart
              filterKeys={[
                { key: "CLState", value: "name" },
                { key: "ranges", value: "x" },
              ]}
              filters={["CycleLength", "CLState"]}
              path={path}
              title={t("CycleLength[Days]")}
              tooltip={{
                displayAxis: 'neither',
                title: 'ykey'
              }}
              data={cowsListState.graphP2Data}
              cowList={cowsListState.filteredData}
              cowListOpen={navigationState.cowListOpen}
              keys={["Completed", "Not completed"]}
              indexBy="Bin"
              yLegend={t("NumberOfEvaluatedCycles")}
              xLegend={t("Days")}
              maxValue={cowsListState.graphP2Max}
              colors={GROUP_3_COLOR_PALETTE}
            />
          ),
          noData: cowsListState.data.length === 0,
        },
      ]}
      chartSlot2={[
        {
          title: t("FollicularPhaseLength"),
          chart: (
            <BarChart
              filterKeys={[
                { key: "FPLState", value: "name" },
                { key: "ranges", value: "x" },
              ]}
              filters={["FolPhaseLength", "FPLState"]}
              path={path}
              title={t("FollicularhaseLength[Days]")}
              tooltip={{
                displayAxis: 'neither',
                title: 'ykey'
              }}
              data={cowsListState.graphP3Data}
              cowList={cowsListState.filteredData}
              cowListOpen={navigationState.cowListOpen}
              keys={["Completed", "Not completed"]}
              indexBy="Bin"
              yLegend={t("NumberOfEvaluatedCycles")}
              xLegend={t("Days")}
              maxValue={cowsListState.graphP3Max}
              colors={GROUP_3_COLOR_PALETTE}
            />
          ),
          noData: cowsListState.data.length === 0,
        },
      ]}
    />
  );
};

export default CRBWP;
