import { Button, Tooltips, Widget as LuiWidget, WidgetActions, WidgetContent, WidgetHeader } from 'lattec-ui-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SET_LIBRARY_OPEN_URL } from "../../redux/Navigation/constants";

const Widget: React.FC = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const Actions = (
    <WidgetActions>
      <Tooltips content={t("GoToLibrary").toString()} position="bottom">
        <Button
          variant="text"
          onClick={() => handleSetUrl("baseline/lvl1/summary")}
          className="infoButton"
          icon={{
            variant: "fas",
            fontAwesomeName: "book",
            size: 18,
          }}
        />
      </Tooltips>
    </WidgetActions>
  )

  return (
    <LuiWidget>
      <WidgetHeader
        headerlineseprator={true}
        title={t("BaselineReport")}
        onClickHeader={() => history.push("/baseline-report")}
        icon={{ variant: "fal", fontAwesomeName: "chart-line" }}
        actions={Actions}
      />
      <WidgetContent>
        {props.children}
      </WidgetContent>
    </LuiWidget>
  )
}

export default Widget;