import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_COW_LIST, } from "../../redux/Navigation/constants";
import "../Layout.scss";
import "./CowsList.scss";
import { iCowListElement } from "./interface";
import { useTranslation } from "react-i18next";
import { formatDateNational } from "../../utills/dateFormatting"
import { Button } from "lattec-ui-library";

const LayoutCowList: React.FC = (props) => {
  const { t } = useTranslation();

  const cowsListFilterState = useSelector((state: any) => state.cowsListFilterState);

  const dispatch = useDispatch();

  const currentCowState = (currentCowState: Number): string => {
    switch (currentCowState) {
      case 10:
        return t("Culled");
      case 20:
        return t("ToBeCulled");
      case 30:
        return t("Dry");
      case 40:
        return t("Pregnant");
      case 50:
        return t("PotentiallyPregnant");
      case 60:
        return t("Inseminated");
      case 70:
        return t("NotInseminated");
      case 80:
        return t("Cyclic");
      case 90:
        return t("Anoestrus");
    }
    return t("NoState");
  }

  const CowListElement: React.FC<iCowListElement> = (props) => {
    let aisInseminationNumber = 0;
    return (
      <div className="cows-list__element">
        <div className="cows-list__element-item">
          <p className="cows-list__label"><b>{t("Cow")}</b></p>
          <p className="cows-list__data"><b>{props.el.CowNo}</b></p>
        </div>

        <div className="cows-list__element-item">
          <p className="cows-list__label">{t("LactationNumbers")}</p>
          <p className="cows-list__data">{props.el.LactationNo}</p>
        </div>

        <div className="cows-list__element-item">
          <p className="cows-list__label">{t("CurrentStatus")}</p>
          <p className="cows-list__data">{currentCowState(props.el.CurrentCowState)}</p>
        </div>

        {props.el.DOAState && props.el.CullState && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CullState !== "Not culled" ? props.el.CullState : props.el.DOAState)}</p>
          </div>
        )}

        {props.el.CycleData && props.el.CullState && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CullState)}</p>
          </div>
        )}

        {props.el.InseminationData && props.el.CullState && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CullState !== "Not culled" ? props.el.CullState : props.el.InseminationData[0].HNIRState)}</p>
          </div>
        )}

        {props.el.CurrentInseminationState && props.el.CullState && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CullState !== "Not culled" ? props.el.CullState : props.el.CurrentInseminationState)}</p>
          </div>
        )}

        {props.el.CompletionStatus && props.el.CullState && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CullState !== "Not culled" ? props.el.CullState : props.el.CompletionStatus)}</p>
          </div>
        )}

        {props.el.AnoestrusLength && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("AnoestrusLength")}</p>
            <p className="cows-list__data">{props.el.DOAState === "Anoestrus" ? t("Not completed") : props.el.AnoestrusLength}</p>
          </div>
        )}

        {props.el.HeatNo && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("HeatNumberAfterVWP")}</p>
            <p className="cows-list__data">{props.el.HeatNo}</p>
          </div>
        )}

        {props.el.HNIRState && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("Inseminated")}</p>
            <p className="cows-list__data">{props.el.HNIRState === "Inseminated" ? t("Yes") : t("No")}</p>
          </div>
        )}

        {props.el.AINumber && !props.el.AISInseminationData && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("InseminationNumber")}</p>
            <p className="cows-list__data">{props.el.AINumber}</p>
          </div>
        )}

        {props.el.DaysFromCalving && props.el.SEAInseminationDate && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("DIMOnInsemination")}</p>
            <p className="cows-list__data">{props.el.DaysFromCalving}</p>
          </div>
        )}

        {props.el.SEAInseminationDate && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("InseminationDate")}</p>
            <p className="cows-list__data">{formatDateNational(new Date(props.el.SEAInseminationDate)) || t("Invalid Date")}</p>
          </div>
        )}

        {props.el.InseminationState && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("InseminationRresult")}</p>
            <p className="cows-list__data">{t(props.el.InseminationState)}</p>
          </div>
        )}

        {!props.el.DOAState && props.el.CurrentInseminationState && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("CurrentStatus")}</p>
            <p className="cows-list__data">{t(props.el.CurrentInseminationState)}</p>
          </div>
        )}

        {props.el.OpenDays && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("ProjectedOpenDays")}</p>
            <p className="cows-list__data">{props.el.OpenDays}</p>
          </div>
        )}

        {props.el.MAXAINumber && (
          <div className="cows-list__inner-element-item">
            <p className="cows-list__label">{t("LatestAINumber")}</p>
            <p className="cows-list__data">{props.el.MAXAINumber}</p>
          </div>
        )}

        {props.el.ShortDate && (
          <div className="cows-list__inner-element-item">
            <p className="cows-list__label">{t("LatestInseminationDate")}</p>
            <p className="cows-list__data">{formatDateNational(new Date(props.el.ShortDate)) || t("Invalid Date")}</p>
          </div>
        )}


        {props.el.HoursBetweenHeatAndAI && false && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("HoursBetweenHeatAndAI")}</p>
            <p className="cows-list__data">{props.el.HoursBetweenHeatAndAI}</p>
          </div>
        )}

        {props.el.FPLength && false && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("FollicularPhaseLength")}</p>
            <p className="cows-list__data">{props.el.FPLength}</p>
          </div>
        )}

        {props.el.State && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("State")}</p>
            <p className="cows-list__data">{t(props.el.State)}</p>
          </div>
        )}

        {props.el.SEAState && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("EmbryoAttachment")}</p>
            <p className="cows-list__data">{t(props.el.SEAState)}</p>
          </div>
        )}

        {props.el.PBSSState && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("FinalEstablishment")}</p>
            <p className="cows-list__data">{t(props.el.PBSSState)}</p>
          </div>
        )}

        {props.el.DOADelay !== undefined && props.el.DOAState !== undefined && props.el.DOADelay !== "Not delayed" && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("SampleDelayed")}</p>
            <p className="cows-list__data">{t(props.el.DOADelay)}</p>
          </div>
        )}

        {props.el.FirstLutealPhaseLength && props.el.FPLState && (
          <div className="cows-list__element-item-with-padding">
            <p className="cows-list__label">{t("FirstLutealPhaseLength")}</p>
            <p className="cows-list__data">{props.el.FPLState === "Completed" ? props.el.FirstLutealPhaseLength : t("Not completed")}</p>
          </div>
        )}

        {props.el.FLPDelay && props.el.FPLState !== undefined && props.el.FLPDelay !== "Not delayed" && (
          <div className="cows-list__element-item">
            <p className="cows-list__label">{t("SampleDelayed")}</p>
            <p className="cows-list__data">{t(props.el.FLPDelay)}</p>
          </div>
        )}

        {props.el.InseminationData && props.el.InseminationData.map((el: any, index: number) => {
          return (el.Hide ? <></> :
            <div className="__inner-element">
              <div className="cows-list__element-item-with-padding">
                <p className="cows-list__label">{t("HeatNumberAfterVWP")}</p>
                <p className="cows-list__data">{el.HeatNo}</p>
              </div>
              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{el.HNIRState === "Inseminated" ? t("DIMOnInsemination") : t("DIMOnHeat")}</p>
                <p className="cows-list__data">{el.DaysFromCalving}</p>
              </div>

              {el.HNIRState === "Inseminated" && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("InseminationDate") + " " + props.el.CurrentCowState}</p>
                  <p className="cows-list__data">{formatDateNational(new Date(el.InseminationDate)) || t("Invalid Date")}</p>
                </div>
              )}

              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{t("Inseminated")}</p>
                <p className="cows-list__data">{el.HNIRState === "Inseminated" ? t("Yes") : t("No")}</p>
              </div>
              {el.FilterSampleDelay !== undefined && el.FilterSampleDelay !== "Not delayed" && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("SampleDelayed")}</p>
                  <p className="cows-list__data">{t(el.FilterSampleDelay)}</p>
                </div>
              )}
            </div>
          )
        }
        )}

        {props.el.AISInseminationData && props.el.AISInseminationData.map((el: any, index: number) => {
          // since onty last insemination can lead to pregnancy, we change state from pregnance to aborted af 25 days on earlier pregnancies
          aisInseminationNumber++;
          return (el.Hide ? <></> :
            <div className="__inner-element">
              <div className="cows-list__element-item-with-padding">
                <p className="cows-list__label">{t("InseminationNumber")}</p>
                <p className="cows-list__data">{el.AINumber}</p>
              </div>
              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{t("InseminationRresult")}</p>
                <p className="cows-list__data">{t(aisInseminationNumber > 1 && el.InseminationState === "Pregnant" ? "AbortedAfter25DFAI" : el.InseminationState)}</p>
              </div>
              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{t("InseminationDate")}</p>
                <p className="cows-list__data">{formatDateNational(new Date(el.InseminationDate)) || t("Invalid Date")}</p>
              </div>
              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{t("DIMOnInsemination")}</p>
                <p className="cows-list__data">{el.DaysFromCalving}</p>
              </div>
              <div className="cows-list__inner-element-item">
                <p className="cows-list__label">{t("HoursBetweenHeatAndAI")}</p>
                <p className="cows-list__data">{el.HoursBetweenHeatAndAI}</p>
              </div>
            </div>
          )
        }
        )}

        {props.el.CycleData && props.el.CycleData.map((el: any, index: number) => {
          return (el.Hide ? <></> :
            <div className="__inner-element">
              <div className="cows-list__element-item-with-padding">
                <p className="cows-list__label">{t("CycleOnSet")}</p>
                <p className="cows-list__data">{formatDateNational(new Date(el.CycleOnset)) || t("Invalid Date")}</p>
              </div>
              {el.CLState && el.CLState === "Completed" && el.CycleLength && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("CycleLength")}</p>
                  <p className="cows-list__data">{el.CycleLength}</p>
                </div>
              )}
              {el.CLState && el.CLState !== "Completed" && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("CycleLength")}</p>
                  <p className="cows-list__data">{t(el.CLState)}</p>
                </div>
              )}
              {el.FPLState && el.FPLState === "Completed" && el.FolPhaseLength && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("FollicularPhaseLength")}</p>
                  <p className="cows-list__data">{el.FolPhaseLength}</p>
                </div>
              )}
              {el.FPLState && el.FPLState !== "Completed" && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("FollicularPhaseLength")}</p>
                  <p className="cows-list__data">{t(el.FPLState)}</p>
                </div>
              )}
              {el.FilterSampleDelay !== undefined && el.FilterSampleDelay !== "Not delayed" && (
                <div className="cows-list__inner-element-item">
                  <p className="cows-list__label">{t("SampleDelayed")}</p>
                  <p className="cows-list__data">{t(el.FilterSampleDelay)}</p>
                </div>
              )}
            </div>
          )
        }
        )}
      </div>
    )
  }

  return (
    <div className="layout__cow-list">
      <div className="cows-list__header">
        <Button variant="text" icon={{ variant: 'fas', fontAwesomeName: 'chevron-right' }} onClick={() => dispatch({ type: TOGGLE_COW_LIST })} />
      </div>
      {cowsListFilterState.filteredData.map((el: any, index: number) => {
        return (
          <CowListElement key={`cl-${index}`} el={el} index={index} />
        )
      })}
    </div>
  );
};

export default LayoutCowList;
