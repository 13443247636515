import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { convertToKPISpecificData } from "../../utills/dataFormatting";
import {
  HANDLE_FILTER_COW_LIST_GET_DATA,
  GET_COWS_LIST_FAILURE,
  GET_COWS_LIST_SUCCESS,
  GET_COWS_LIST_REQUEST,
  HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR,
  HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
  HANDLE_FILTER_COW_LIST_GRAPH_P2,
  HANDLE_FILTER_COW_LIST_GRAPH_P3,
  HANDLE_FILTER_COW_LIST_GRAPH_MAX,
  HANDLE_FILTER_COW_LIST_SCATTER_P1,
  HANDLE_FILTER_COW_LIST_SCATTER_P2,
  HANDLE_COW_LIST_REFERENCE,
  HANDLE_FILTER_COW_LIST_COMPLETE,
} from "./constants";
import { HANDLE_COW_FILTER_LIST_INITIALIZATION } from "../CowsFilterList/constants";
import { BAR_AREA_CLEAR_SELECTION } from "../BarArea/constants";
import { postXML } from "../../api/requests";
import {
  getDOAGraphData,
  getCRBWPGraphData,
  getHNIRGraphData,
  getAISGraphData,
  getSEAGraphData,
  getODGraphData,
} from "../../utills/dataFormatting";
import { getBaseUrl } from "../../utills/envHelper";

function* getCowsListSaga(action: any): SagaIterator {
  try {
    // clear previous data, which will enable loaders
    yield put({
      type: HANDLE_FILTER_COW_LIST_GET_DATA,
      payload: null,
    });

    var payload = yield call(
      postXML,
      getBaseUrl() + `/farms/reproduction/crd/${action.payload.path}`,
      {
        startDate: action.payload.startDate,
        stopDate: action.payload.stopDate,
        L1: action.payload.lactationFilters.l1Checked ? 1 : 0,
        L2: action.payload.lactationFilters.l2Checked ? 1 : 0,
        L3P: action.payload.lactationFilters.l3Checked ? 1 : 0,
      }
    );

    const today = new Date();
    if (today.getMinutes() % 2 === 0 && action.payload.path === "crbwp") payload = [];
    const newPayload = convertToKPISpecificData(payload, action.payload.path);
    const filteredPayload = newPayload.filter((cow: any) => {
      return !((cow["DOADelayValue"] > 2 && cow["FLPDelayValue"] > 2) || cow["FilterSampleDelay"] > 2);
    });

    yield put({
      type: GET_COWS_LIST_SUCCESS,
      payload: newPayload,
      filteredPayload: filteredPayload,
    });

    // clear earlier filters set, by setting the filtered list to the new list
    yield put({
      type: HANDLE_COW_FILTER_LIST_INITIALIZATION,
      payload: filteredPayload,
    });
    yield put({
      type: HANDLE_FILTER_COW_LIST_COMPLETE,
      payload: filteredPayload,
    });
    yield put({ type: BAR_AREA_CLEAR_SELECTION, payload: {} });

    var p2reference: any[] = [];
    var p3reference: any[] = [];

    // only doa and cr/crbwp have reference data
    if (action.payload.path === "doa" || action.payload.path === "crbwp") {
      const referencePath = action.payload.path === "doa" ? "doa" : "cr";

      p2reference = yield call(
        postXML,
        getBaseUrl() + `/farms/reproduction/refp2p3/${referencePath}/p2`
      );
      p3reference = yield call(
        postXML,
        getBaseUrl() + `/farms/reproduction/refp2p3/${referencePath}/p3`
      );
    }
    yield put({
      type: HANDLE_COW_LIST_REFERENCE,
      payload: { p2reference, p3reference },
    });

    let graphP2Max: number = 0;
    let graphP3Max: number = 0;
    let scatterP2Max: number = 0;

    if (action.payload.path === "od") {
      const graphData = getODGraphData(newPayload, true, true, true, true);

      if (graphData && graphData.graphP3DataMerged)
        graphData.graphP3DataMerged.forEach((data: any) => {
          if (
            data["NotPregnant"] +
            data["Pregnant"] +
            data["PotentiallyPregnant"] >
            graphP2Max
          )
            graphP2Max =
              data["NotPregnant"] +
              data["Pregnant"] +
              data["PotentiallyPregnant"];
        });
      if (graphData && graphData.graphP3DataMerged)
        graphData.graphP3DataMerged.forEach((data: any) => {
          if (
            data["NotPregnant"] +
            data["Pregnant"] +
            data["PotentiallyPregnant"] >
            graphP3Max
          )
            graphP3Max =
              data["NotPregnant"] +
              data["Pregnant"] +
              data["PotentiallyPregnant"];
        });

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_MAX,
        payload: { graphP2Max, graphP3Max },
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    } else if (action.payload.path === "hnir") {
      const graphData = getHNIRGraphData(
        newPayload,
        true,
        true,
        true,
        true,
        false
      );
      const p1Payload = graphData && graphData.scatterP1;
      const p3Payload = graphData && graphData.graphP3Data;

      if (p3Payload)
        p3Payload.forEach((data: any) => {
          if (data["Inseminated"] + data["NotInseminated"] > graphP3Max)
            graphP3Max = data["Inseminated"] + data["NotInseminated"];
        });

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_MAX,
        payload: { graphP2Max, graphP3Max },
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR,
        payload: p1Payload,
      });
      yield put({ type: HANDLE_FILTER_COW_LIST_GRAPH_P3, payload: p3Payload });
    } else if (action.payload.path === "ais") {
      const graphData = getAISGraphData(newPayload, true, true, true, true, true, true, true, true);

      if (graphData && graphData.graphP2DataMerged)
        graphData.graphP2DataMerged.forEach((data: any) => {
          if (
            data["NotPregnant"] +
            data["Pregnant"] +
            data["PotentiallyPregnant"] >
            graphP2Max
          )
            graphP2Max =
              data["NotPregnant"] +
              data["Pregnant"] +
              data["PotentiallyPregnant"];
        });
      if (graphData && graphData.graphP3DataMerged)
        graphData.graphP3DataMerged.forEach((data: any) => {
          if (
            data["NotPregnant"] +
            data["Pregnant"] +
            data["PotentiallyPregnant"] >
            graphP3Max
          )
            graphP3Max =
              data["NotPregnant"] +
              data["Pregnant"] +
              data["PotentiallyPregnant"];
        });
      if (graphData && graphData.scatterP2)
        graphData.scatterP2.forEach((status: any) => {
          if (status.data) {
            status.data.forEach((cow: any) => {
              if (cow.x > scatterP2Max) scatterP2Max = cow.x;
            });
          }
        });

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_MAX,
        payload: { graphP2Max, graphP3Max, scatterP2Max },
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P1,
        payload: graphData && graphData.scatterP1,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P2,
        payload: graphData && graphData.scatterP2,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    } else {
      let graphData;
      if (action.payload.path === "doa") {
        // when calculating max we must include delayed
        graphData = getDOAGraphData(
          newPayload,
          p2reference,
          p3reference,
          true,
          true,
          true,
          true,
          true
        );
        if (graphData && graphData.graphP2DataMerged)
          graphData.graphP2DataMerged.forEach((data: any) => {
            if (data["Cyclic cows"] + data["Anoestrus cows"] > graphP2Max)
              graphP2Max = data["Cyclic cows"] + data["Anoestrus cows"];
          });
        if (graphData && graphData.graphP3DataMerged)
          graphData.graphP3DataMerged.forEach((data: any) => {
            if (data["Completed"] + data["Not completed"] > graphP3Max)
              graphP3Max = data["Completed"] + data["Not completed"];
          });
        graphData = getDOAGraphData(
          newPayload,
          p2reference,
          p3reference,
          true,
          true,
          true,
          false,
          true
        );
      }
      if (action.payload.path === "sea") {
        graphData = getSEAGraphData(newPayload, true, true, true, true);
        if (graphData && graphData.graphP2DataMerged)
          graphData.graphP2DataMerged.forEach((data: any) => {
            if (data["Early embryo death"] + data["Successful"] > graphP2Max)
              graphP2Max = data["Early embryo death"] + data["Successful"];
          });
        if (graphData && graphData.graphP3DataMerged)
          graphData.graphP3DataMerged.forEach((data: any) => {
            if (data["Early abortion"] + data["Successful"] > graphP3Max)
              graphP3Max = data["Early abortion"] + data["Successful"];
          });
      }
      if (action.payload.path === "crbwp") {
        // when calculating max we must include delayed
        graphData = getCRBWPGraphData(
          newPayload,
          p2reference,
          p3reference,
          true,
          true,
          true,
          true,
          true
        );
        if (graphData && graphData.graphP2DataMerged)
          graphData.graphP2DataMerged.forEach((data: any) => {
            if (data["Completed"] + data["Not completed"] > graphP2Max)
              graphP2Max = data["Completed"] + data["Not completed"];
          });
        if (graphData && graphData.graphP3DataMerged)
          graphData.graphP3DataMerged.forEach((data: any) => {
            if (data["Completed"] + data["Not completed"] > graphP3Max)
              graphP3Max = data["Completed"] + data["Not completed"];
          });
        graphData = getCRBWPGraphData(
          newPayload,
          p2reference,
          p3reference,
          true,
          true,
          true,
          false,
          true
        );
      }
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_MAX,
        payload: { graphP2Max, graphP3Max },
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    }
  } catch (error) {
    console.log(GET_COWS_LIST_FAILURE, error);
    yield put({ type: GET_COWS_LIST_FAILURE, payload: "Unable to get data" });
  }
}

function* handleFilterCowListLactationFilter(action: any): SagaIterator {
  try {
    let newList = action.payload.data;

    // use lactation and culled filter on list
    newList = newList.filter((cow: any) => {
      return (
        ((cow["LactationNo"] === 1 &&
          action.payload.lactationFilters.l1Checked) ||
          (cow["LactationNo"] === 2 &&
            action.payload.lactationFilters.l2Checked) ||
          (cow["LactationNo"] >= 3 &&
            action.payload.lactationFilters.l3Checked)) &&
        (action.payload.lactationFilters.culledChecked ||
          cow["CullState"] === "Not culled")
      );
    });

    // use insemination filters 
    if (!action.payload.lactationFilters.i1Checked ||
      !action.payload.lactationFilters.i2Checked ||
      !action.payload.lactationFilters.i3Checked ||
      !action.payload.lactationFilters.i4Checked) {
      newList = newList.filter((cow: any) => {
        return cow.AISInseminationData.some((insemination: any) => {
          return ((action.payload.lactationFilters.i1Checked && insemination["AINumber"] === 1) ||
            (action.payload.lactationFilters.i2Checked && insemination["AINumber"] === 2) ||
            (action.payload.lactationFilters.i3Checked && insemination["AINumber"] === 3) ||
            (action.payload.lactationFilters.i4Checked && insemination["AINumber"] > 3));
        });
      });
    }

    // apply delayed filter
    if (!action.payload.lactationFilters.delayedChecked) {
      if (action.payload.path === "doa") {
        newList = newList.filter((cow: any) => {
          return !(cow["DOADelayValue"] > 2 && cow["FLPDelayValue"] > 2);
        });
      }

      if (action.payload.path === "hnir") {
        newList = newList.filter((cow: any) => {
          return cow.InseminationData.some((insemination: any) => {
            return (
              insemination["FilterSampleDelayValue"] !== null &&
              insemination["FilterSampleDelayValue"] < 3
            );
          });
        });
      }
    }

    yield put({
      type: HANDLE_COW_FILTER_LIST_INITIALIZATION,
      payload: newList,
    });
    yield put({ type: HANDLE_FILTER_COW_LIST_COMPLETE, payload: newList });
    yield put({ type: BAR_AREA_CLEAR_SELECTION, payload: {} });

    if (action.payload.path === "ais") {
      const graphData = getAISGraphData(
        newList,
        action.payload.lactationFilters.l1Checked,
        action.payload.lactationFilters.l2Checked,
        action.payload.lactationFilters.l3Checked,
        action.payload.lactationFilters.culledChecked,
        action.payload.lactationFilters.i1Checked,
        action.payload.lactationFilters.i2Checked,
        action.payload.lactationFilters.i3Checked,
        action.payload.lactationFilters.i4Checked
      );
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P1,
        payload: graphData && graphData.scatterP1,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P2,
        payload: graphData && graphData.scatterP2,
      });

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    } else if (action.payload.path === "hnir") {
      const graphData = getHNIRGraphData(
        newList,
        action.payload.lactationFilters.l1Checked,
        action.payload.lactationFilters.l2Checked,
        action.payload.lactationFilters.l3Checked,
        action.payload.lactationFilters.culledChecked,
        action.payload.lactationFilters.delayedChecked
      );
      yield put({
        type: HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR,
        payload: graphData && graphData.scatterP1,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3Data,
      });
    } else if (action.payload.path === "crbwp") {
      const graphData = getCRBWPGraphData(
        newList,
        action.payload.p2reference,
        action.payload.p3reference,
        action.payload.lactationFilters.l1Checked,
        action.payload.lactationFilters.l2Checked,
        action.payload.lactationFilters.l3Checked,
        action.payload.lactationFilters.delayedChecked,
        action.payload.lactationFilters.culledChecked
      );

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    } else {
      const graphData =
        action.payload.path === "doa"
          ? getDOAGraphData(
            newList,
            action.payload.p2reference,
            action.payload.p3reference,
            action.payload.lactationFilters.l1Checked,
            action.payload.lactationFilters.l2Checked,
            action.payload.lactationFilters.l3Checked,
            action.payload.lactationFilters.delayedChecked,
            action.payload.lactationFilters.culledChecked
          )
          : action.payload.path === "sea"
            ? getSEAGraphData(
              newList,
              action.payload.lactationFilters.l1Checked,
              action.payload.lactationFilters.l2Checked,
              action.payload.lactationFilters.l3Checked,
              action.payload.lactationFilters.culledChecked
            )
            : getODGraphData(
              newList,
              action.payload.lactationFilters.l1Checked,
              action.payload.lactationFilters.l2Checked,
              action.payload.lactationFilters.l3Checked,
              action.payload.lactationFilters.culledChecked
            );

      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P2,
        payload: graphData && graphData.graphP2DataMerged,
      });
      yield put({
        type: HANDLE_FILTER_COW_LIST_GRAPH_P3,
        payload: graphData && graphData.graphP3DataMerged,
      });
    }
  } catch (error) {
    yield put({
      type: "HANDLE_FILTER_COW_LIST_ERROR",
      payload: "An error occured",
    });
  }
}

export function* watchGetCowsList(): SagaIterator {
  yield takeEvery(GET_COWS_LIST_REQUEST, getCowsListSaga);
  yield takeEvery(HANDLE_FILTER_COW_LIST_LACTATION_FILTER, handleFilterCowListLactationFilter);
}
