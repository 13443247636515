import React from 'react';
import Helmet from 'react-helmet';
import { getEnv } from './envHelper';

export const Clarity = () => {
  
  React.useEffect(() => {
    setTimeout(() => {
      const env = getEnv();
      // @ts-ignore, Typescript doesn't recognize clarity as it's loaded via html below.
      clarity('set', 'env', env);
    }, 1000);
  }, []);
  
  return (
    <Helmet>
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "c5zt9ku23j");
        `}
      </script>
    </Helmet>
  )
}