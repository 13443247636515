import React from "react";
import "../Card.scss";
import { iContent } from "./interface";

const Content: React.FC<iContent> = (props) => {
  return (
    <div className="card__container">
      <div className={`${props.loading ? "card__blurred" : ""} card__content`}>
        {props.children}
      </div>
      <div className={`${!props.loading ? "card__hidden" : ""} card__spinner`}>
        <span style={{ verticalAlign: "middle" }}>
          <i className="fas fa-spinner fa-pulse fa-6x" />
        </span>
      </div>
    </div>
  );
};

export default Content;
