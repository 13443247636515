export const SetTitleLineWitdh = (element: SVGPathElement, bold: boolean = false) => {
  if (element.parentNode) {
    element.parentNode.childNodes.forEach((child) => {
      child.childNodes.forEach((node) => {
        if (node.textContent === element.textContent) (child as SVGPathElement).style.strokeWidth = bold ? "6" : "3";
      })
    })
  }
}

export const SetIdLineWitdh = (elements: string[], bold: boolean = false) => {
  elements.forEach((element) => {
    let svgPath = document.getElementById(element)
    if (svgPath !== null) svgPath.style.strokeWidth = bold ? "6" : "3";
  })
}

export const SetIdRectOpacity = (elements: string[], opacy: boolean = false) => {
  elements.forEach((element) => {
    let svgRect = document.getElementById(element)
    if (svgRect !== null) svgRect.style.opacity = opacy ? "0.8" : "1";
  })
}

export const HoverKPIChart = (className: string, mouseOver: boolean = false) => {
  const element = document.getElementById(className);

  if (element) element.style.backgroundColor = mouseOver ? "#103D82" : "#fff";
}

export const SetIdPolygonScale = (elements: string[], scale: number = 1) => {
  elements.forEach((element) => {
    let svgPath = document.getElementById(element)
    if (svgPath === null) return
    // @ts-ignore
    const centerX = svgPath.getBBox().width / 2 + svgPath.getBBox().x;
    // @ts-ignore
    const centerY = svgPath.getBBox().height / 2 + svgPath.getBBox().y;
    svgPath.setAttribute("transform", "translate(" + (1 - scale) * centerX + ", " + (1 - scale) * centerY + ") scale(" + scale + ")")
  })
}
