import { all } from "redux-saga/effects";
import { watchGetBaselineReportSaga } from "./BaselineReort/saga";

import { watchGetCowsList } from "./CowsList/saga";
import { watchGetCowsFilterList } from "./CowsFilterList/saga";
import { watchGetCurrentReproductionPerformance } from "./CurrentReproductionPerformance/saga";
import { watchHNInsightsSaga } from "./HNInsights/saga";
import { watchGetProportionOfDelayedSamples } from "./ProportionOfDelayedSamples/saga";
import { watchSettingsSaga } from "./Settings/saga";
import { watchGetStickConsumption } from "./StickConsumption/saga";
import { watchGetPerformanceScoreHistorySaga } from "./PerformanceScoreHistory/saga";
import { watchGetUserOptionsSaga } from "./UserOptions/saga"

export default function* rootSaga() {
  yield all([
    watchGetStickConsumption(),
    watchGetCowsList(),
    watchGetCowsFilterList(),
    watchSettingsSaga(),
    watchGetCurrentReproductionPerformance(),
    watchHNInsightsSaga(),
    watchGetProportionOfDelayedSamples(),
    watchGetBaselineReportSaga(),
    watchGetPerformanceScoreHistorySaga(),
    watchGetUserOptionsSaga(),
  ]);
}
