import {
  GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST,
  GET_PROPORTION_OF_DELAYED_SAMPLES_SUCCESS,
  GET_PROPORTION_OF_DELAYED_SAMPLES_FAILURE,
} from "./constants";

interface StickConsumptionDataPoint {

}

export interface State {
  loading: boolean;
  data: StickConsumptionDataPoint[];
  error: string | null;
  gotData: boolean;
}

export const initialState: State = {
  loading: false,
  data: [],
  error: null,
  gotData: false,
};

export const ProportionOfDelayedSamplesReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case GET_PROPORTION_OF_DELAYED_SAMPLES_REQUEST:
      return {
        ...state,
        loading: true,
        gotData: false,
      };
    case GET_PROPORTION_OF_DELAYED_SAMPLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        gotData: true,
        data: action.payload,
      };
    }
    case GET_PROPORTION_OF_DELAYED_SAMPLES_FAILURE:
      return {
        ...state,
        gotData: false,
        loading: false,
        error: action.payload,
      };

    default:
      return state;

  }
};
