import React from "react";
import { useTranslation } from "react-i18next";
import './style.scss'
import { SetIdLineWitdh, HoverKPIChart } from '../helper';

function DoaChart() {
  const { t } = useTranslation();

  const ProgesteroneProfile: string[] = ["ProgesteroneProfile", "ProgesteroneProfile1"];

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox="770 450 385 185"
      id="doaChart"
    >
      <g id='HNIR_-_HN_Insemination_Rate'>
        <g id='Group_3507' transform='translate(-386 12227)'>
          <path
            id='Rectangle_1507_1_'
            d='M3141-16785.1h24v24h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1511_1_'
            d='M3141-16549.1h24v24h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1510_1_'
            d='M3141-16726.1h24v16h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1509_1_'
            d='M3120-16761.1h24v19h-24z'
            className='st0'
          ></path>       
        </g>
        <g id='Group_3507_1_' transform='translate(-386 12227)'>
          <path
            id='Rectangle_1507_2_'
            d='M3352.1-16691.3h24v24h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1511_2_'
            d='M3352.1-16455.3h24v24h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1510_2_'
            d='M3352.1-16632.3h24v16h-24z'
            className='st0'
          ></path>
          <path
            id='Rectangle_1509_2_'
            d='M3331.1-16667.3h24v19h-24z'
            className='st0'
          ></path>
        </g>
        <path
          id='Rectangle_1527_18_'
          d='M833.2 459.5h47v142h-47z'
          className='st1'
          onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
            HoverKPIChart("Chart2", true);
            event.currentTarget.style.opacity = "0.8";
          }}
          onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
            HoverKPIChart("Chart2");
            event.currentTarget.style.opacity = "1";
          }}
        >
          <title>{t("FirstLutealPhase")}</title>
        </path>
        <path
          id='Path_1631_5_'
          d='M774.2 459.5h59v142h-59v-142z'
          className='st2'
          onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
            HoverKPIChart("Chart1", true);
            event.currentTarget.style.opacity = "0.8";
          }}
          onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
            HoverKPIChart("Chart1");
            event.currentTarget.style.opacity = "1";
          }}
        >
          <title>{t("AnoestrusLength")}</title>
        </path>
        <path d='M773.3 459.5h376v161h-376z' className='st3'></path>
        <path
          id="ProgesteroneProfile"
          d='M774.2 580.7h49.1c5.5 0 7.4-.2 11.8-5.1 7.2-8.1 18.1-33.4 22-40.2 4.6-8.1 7.5-10.6 11.3-10.6 4.2 0 7.6 3.8 9 14.9 3.8 31.2 3.4 41 11.8 41h2.8c2.5 0 7.2-1.3 11-12s13.5-42.1 17.7-52c4.2-9.8 9.2-15.5 14.2-15.5h5.3c5.9 0 9.6 4.9 11.3 13.8 1.7 9.1 5.8 43.1 7.3 52.4 1.7 10.3 4.1 13.2 7 13.2 5.1 0 9.2 0 12.2-5.6 4.5-8.2 12.1-50.9 19.4-69.8 3.8-9.8 6.8-14.1 14.8-14.1h7c3 0 7.8 4.6 9.8 12.6 4.5 18.1 6.5 45.8 8.5 61.5 1.6 12.4 5.5 15.4 8.9 15.4 5.2 0 11.3-1.2 14.1-5.6 3.8-6 12-52.4 14.4-67.9 4-26.2 9.9-26.4 13.9-27.8 6.7-2.3 44.6-3.8 60.4-3.8'
          className='st4'
          onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
          onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }}
        >
          <title>{t("ProgesteroneProfile")}</title>
        </path>
        <path
          d='M771.7 460.7h5v5h-5z'
          className='st5'
          transform='rotate(45.001 774.23 463.169)'
        ></path>
        <path
          d='M1143.3 599h5v5h-5z'
          className='st5'
          transform='rotate(45.001 1145.792 601.444)'
        ></path>
        <defs>
          <path id='Rectangle_1527_4_' d='M774.2 459.6h106v137h-106z'></path>
        </defs>
        <clipPath id='Rectangle_1527_3_'>
          <use overflow='visible' xlinkHref='#Rectangle_1527_4_'></use>
        </clipPath>
        <path
          id="ProgesteroneProfile1"
          fill='none'
          stroke='#103d82'
          strokeMiterlimit='10'
          strokeWidth='3'
          d='M774.2 580.7h49.1c5.5 0 7.4-.2 11.8-5.1 7.2-8.1 18.1-33.4 22-40.2 4.6-8.1 7.5-10.6 11.3-10.6 4.2 0 7.6 3.8 9 14.9 3.8 31.2 3.4 41 11.8 41h2.8c2.5 0 7.2-1.3 11-12s13.5-42.1 17.7-52c4.2-9.8 9.2-15.5 14.2-15.5h5.3c5.9 0 9.6 4.9 11.3 13.8 1.7 9.1 5.8 43.1 7.3 52.4 1.7 10.3 4.1 13.2 7 13.2 5.1 0 9.2 0 12.2-5.6 4.5-8.2 12.1-50.9 19.4-69.8 3.8-9.8 6.8-14.1 14.8-14.1h7c3 0 7.8 4.6 9.8 12.6 4.5 18.1 6.5 45.8 8.5 61.5 1.6 12.4 5.5 15.4 8.9 15.4 5.2 0 11.3-1.2 14.1-5.6 3.8-6 12-52.4 14.4-67.9 4-26.2 9.9-26.4 13.9-27.8 6.9-2.4 46-3.8 61.2-3.8'
          clipPath='url(#Rectangle_1527_3_)'

          onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
          onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }} >

          <title>{t("ProgesteroneProfile")}</title>
        </path>
        <path
          d='M1146.6 601.5H774.1V463.2' className='st7'
          onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "6" }}
          onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "3" }} >
          <title>{t("ProgesteroneLevel")} / {t("Daysinmilk")}</title>
        </path>

        <path
        id='Heat1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 600.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 680.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat3'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 760.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='AI1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 690.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

        <path
        id='AI2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 770.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>
      </g>
    </svg >
  );
}

export default DoaChart;
