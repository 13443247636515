import React, { Fragment } from "react";
import "./Modal.scss";
import { iModal } from "./interface";

const Modal: React.FC<iModal> = (props) => {
  return (
    <div className={`modal ${props.visible ? "" : "modal--hidden"}`}>
      <div className="modal__overlay" />
      <div
        className="modal__container"
        style={{
          width: props.width ? `${props.width}%` : "48%",
          ...props.style,
        }}
      >
        <div className="modal__header">
          <h2 className="modal__headline">{props.headline}</h2>
          <button className="modal__close-button" onClick={props.close}>
            X
            {/*<i className="modal__close-icon fas fa-times" color="black" />*/}
          </button>
        </div>
        <div className="modal__content">
          {props.visible && <div>{props.children}</div>}
        </div>
      </div>
    </div>
  );
};

export default Modal;
