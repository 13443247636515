import Plotly, {
  register,
} from 'plotly.js/lib/core';

// Load in the trace types for pie, and choropleth
register([
  require('plotly.js/lib/scatterpolar'),
  require('plotly.js/lib/scatter'),
  require('plotly.js/lib/bar')
]);

export { Data } from 'plotly.js/lib/core';
export default Plotly;