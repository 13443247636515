import React from "react";
import "../Input.scss";
import { iDropdown, iDropdownElem } from "./interface";
import { useTranslation } from "react-i18next";
import { muiCore } from 'lattec-ui-library';

const Dropdown: React.FC<iDropdown> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="input__checkbox-container">
      <muiCore.FormControl fullWidth>
        <muiCore.Select
          value={props?.selected?.key}
          label=""
          onChange={(e) => {props.onChange && props.onChange(Number(e.target.value)); }}
        >
          {props.list.map((el: iDropdownElem) => (
            <muiCore.MenuItem value={el.key} key={el.key + el.value}>{t(el.value)}</muiCore.MenuItem>
          ))}
        </muiCore.Select>
      </muiCore.FormControl>
    </div>
  );
};

export default Dropdown;
