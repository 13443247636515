import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataTypeDisplay } from "../../DataType";
import {
  GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST,
  GET_KPI_CHART_DATA_REQUEST,
} from "../../redux/CurrentReproductionPerformance/constants";
import {
  SET_LIBRARY_OPEN_URL,
  SET_CRP_DB_HOVERED,
  DASHBOARD_TOGGLE,
  STICK_CONSUMPTION_TOGGLE,
} from "../../redux/Navigation/constants";
import {
  COLOR_SCREME_1,
  COLOR_SCREME_2,
  COLOR_SCREME_1_SEMI_FILL,
  COLOR_SCREME_2_SEMI_FILL,
  BARS_COLOR_SCHEME_1,
  BARS_COLOR_SCHEME_2,
  POLAR_COLOR_SCHEME_1
} from "../../styles/ColorCodes";
import StatusNumber from "../../StatusNumber";
import "./CurrentReproductionPerformance.scss";
import LattecIcon from "../../LattecIcon";
import { iCurrentRepPerf } from "./interface";
import PlaceholderLoader from "../../PlaceholderLoader";
import PolarChart from "../../Charts/PolarChart";
import LattecButton from "../../Input/Button";
import {
  Widget,
  WidgetHeader,
  WidgetContent,
  Icon,
  WidgetActions,
  Button,
  Tooltips,
  Grid,
} from "lattec-ui-library";
import { GET_USER_OPTIONS_REQUEST } from "../../redux/UserOptions/constants";
import { getKPILongTitle, getColorId, getTrendColor, iKpiData } from "../../utills/dataFormatting";

const CurrentReproductionPerformance: React.FC<iCurrentRepPerf> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentReproductionPerformanceState = useSelector(
    (state: any) => state.currentReproductionPerformanceState
  );
  const navigationState = useSelector((state: any) => state.navigationState);
  const userOptionsState = useSelector((state: any) => state.userOptionsState);

  const { t } = useTranslation();

  let isOwnFarm: number;
  if (props.toggleId === DASHBOARD_TOGGLE)
    isOwnFarm = userOptionsState.dataType;
  else if (props.toggleId === STICK_CONSUMPTION_TOGGLE)
    isOwnFarm = userOptionsState.isOwnFarm;
  else isOwnFarm = userOptionsState.dataBaseLineReportType;

  useEffect(() => {
    if (!currentReproductionPerformanceState.gotData) {
      dispatch({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST });
    }

    if (!currentReproductionPerformanceState.gotData) {
      dispatch({ type: GET_KPI_CHART_DATA_REQUEST });
    }

    if (!userOptionsState.gotData)
      dispatch({ type: GET_USER_OPTIONS_REQUEST, payload: null });
  }, [dispatch]);

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  const handleMouseOver = (hovered: string[]) =>
    dispatch({ type: SET_CRP_DB_HOVERED, payload: hovered });
  const isOver = (el: string) =>
    navigationState.currentReproductionPerformanceDbHovered.includes(el);

  
  const Actions = (
    <WidgetActions>
      <Tooltips content={t("GoToLibrary").toString()} position="bottom">
        <Button
          variant="text"
          onClick={() => handleSetUrl("crp/lvl1/summary")}
          className="infoButton"
          icon={{
            variant: "fas",
            fontAwesomeName: "book",
            size: "18",
          }}
        />
      </Tooltips>
    </WidgetActions>
  );

  return (
    <Widget>
      <WidgetHeader
        headerlineseprator={true}
        title={t("CurrentReproductionPerformance")}
        icon={{
          lattecName: "Current_reproduction_perforamance",
          variant: "lattec",
          position: "left",
        }}
        actions={Actions}
      />
      <WidgetContent>
        <>
          {!currentReproductionPerformanceState.gotData ? (
            <PlaceholderLoader
              type="CurrentReproductionPerformance"
              height="716px"
            />
          ) : (
            <>
              {!props.simple && (
                <div
                  className="current-reproduction-performance__statistics"
                  id="current-reproduction-performance-statistics"
                >
                  <div className="current-reproduction-performance__number">
                    <LattecButton
                      type="data-point"
                      hovered={props.embedded ? () => null : isOver("doa")}
                      onMouseOver={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver(["doa"])
                      }
                      onMouseLeave={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver([""])
                      }
                      onClick={
                        props.embedded ? () => null : () => history.push(`/doa`)
                      }
                    >
                      <StatusNumber
                        number={
                          currentReproductionPerformanceState.data.CyclicityRate
                        }
                        unit="%"
                        text={t("CyclicAt50DIM")}
                        colorId={getColorId(
                          isOwnFarm,
                          currentReproductionPerformanceState.data
                            .CyclicityRate,
                          currentReproductionPerformanceState.data
                            .CyclicityRateBenchmarkFarms,
                          currentReproductionPerformanceState.data
                            .CyclicityRateOwnFarmOneYearBack
                        )}
                      >
                        <DataTypeDisplay
                          id="current-reproduction-performance-value-value-bar"
                          referenceValudId="current-reproduction-performance-value-reference-bar"
                          colors={
                            isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
                          }
                          isOwnFarm={isOwnFarm}
                          trend={
                            currentReproductionPerformanceState.data
                              .CyclicityRateTrendIndicator
                          }
                          benchmarkComparison={
                            currentReproductionPerformanceState.data
                              .CyclicityRateBenchmarkFarms
                          }
                          current={parseFloat(
                            currentReproductionPerformanceState.data
                              .CyclicityRate
                          )}
                          ownComparison={
                            currentReproductionPerformanceState.data
                              .CyclicityRateOwnFarmOneYearBack
                          }
                          containerWidth="15px"
                          containerHeight="40px"
                        />
                      </StatusNumber>
                    </LattecButton>
                  </div>
                  <div className="current-reproduction-performance__number">
                    <LattecButton
                      type="data-point"
                      hovered={props.embedded ? () => null : isOver("cr")}
                      onMouseOver={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver(["cr"])
                      }
                      onMouseLeave={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver([""])
                      }
                      onClick={
                        props.embedded ? () => null : () => history.push(`/cr`)
                      }
                    >
                      <StatusNumber
                        number={
                          currentReproductionPerformanceState.data
                            .IrregularCycles
                        }
                        unit="%"
                        text={t("RegularCycles")}
                        colorId={getColorId(
                          isOwnFarm,
                          currentReproductionPerformanceState.data
                            .IrregularCycles,
                          currentReproductionPerformanceState.data
                            .IrregularCyclesBenchmarkFarms,
                          currentReproductionPerformanceState.data
                            .IrregularCyclesOwnFarmOneYearBack
                        )}
                      >
                        <DataTypeDisplay
                          referenceValudId="current-reproduction-performance-reference-value-bar"
                          colors={
                            isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
                          }
                          isOwnFarm={isOwnFarm}
                          trend={
                            currentReproductionPerformanceState.data
                              .IrregularCyclesTrendIndicator
                          }
                          benchmarkComparison={
                            currentReproductionPerformanceState.data
                              .IrregularCyclesBenchmarkFarms
                          }
                          current={parseFloat(
                            currentReproductionPerformanceState.data
                              .IrregularCycles
                          )}
                          ownComparison={
                            currentReproductionPerformanceState.data
                              .IrregularCyclesOwnFarmOneYearBack
                          }
                          containerWidth="15px"
                          containerHeight="40px"
                        />
                      </StatusNumber>
                    </LattecButton>
                  </div>
                  <div className="current-reproduction-performance__number">
                    <LattecButton
                      type="data-point"
                      hovered={props.embedded ? () => null : isOver("ais")}
                      onMouseOver={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver(["ais"])
                      }
                      onMouseLeave={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver([""])
                      }
                      onClick={
                        props.embedded ? () => null : () => history.push(`/ais`)
                      }
                    >
                      <StatusNumber
                        number={
                          currentReproductionPerformanceState.data
                            .SuccessOnFirstAI
                        }
                        unit="%"
                        text={t("SuccessOnFirstAI")}
                        colorId={getColorId(
                          isOwnFarm,
                          currentReproductionPerformanceState.data
                            .SuccessOnFirstAI,
                          currentReproductionPerformanceState.data
                            .SuccessOnFirstAIBenchmarkFarms,
                          currentReproductionPerformanceState.data
                            .SuccessOnFirstAIOwnFarmOneYearBack
                        )}
                      >
                        <DataTypeDisplay
                          referenceValudId="current-reproduction-performance-reference-value-bar"
                          colors={
                            isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
                          }
                          isOwnFarm={isOwnFarm}
                          trend={
                            currentReproductionPerformanceState.data
                              .SuccessOnFirstAITrendIndicator
                          }
                          benchmarkComparison={
                            currentReproductionPerformanceState.data
                              .SuccessOnFirstAIBenchmarkFarms
                          }
                          current={parseFloat(
                            currentReproductionPerformanceState.data
                              .SuccessOnFirstAI
                          )}
                          ownComparison={
                            currentReproductionPerformanceState.data
                              .SuccessOnFirstAIOwnFarmOneYearBack
                          }
                          containerWidth="15px"
                          containerHeight="40px"
                        />
                      </StatusNumber>
                    </LattecButton>
                  </div>
                  <div className="current-reproduction-performance__number">
                    <LattecButton
                      type="data-point"
                      hovered={props.embedded ? () => null : isOver("hnir")}
                      onMouseOver={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver(["hnir"])
                      }
                      onMouseLeave={
                        props.embedded
                          ? () => null
                          : () => handleMouseOver([""])
                      }
                      onClick={
                        props.embedded
                          ? () => null
                          : () => history.push(`/hnir`)
                      }
                    >
                      <StatusNumber
                        number={
                          currentReproductionPerformanceState.data
                            .HNInseminationRate
                        }
                        unit="%"
                        text={t("HNInseminationRate")}
                        colorId={getColorId(
                          isOwnFarm,
                          currentReproductionPerformanceState.data
                            .HNInseminationRate,
                          currentReproductionPerformanceState.data
                            .HNInseminationRateBenchmarkFarms,
                          currentReproductionPerformanceState.data
                            .HNInseminationRateOwnFarmOneYearBack
                        )}
                      >
                        <DataTypeDisplay
                          id="current-reproduction-performance-value-value-bar"
                          referenceValudId="current-reproduction-performance-value-reference-bar"
                          colors={
                            isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
                          }
                          isOwnFarm={isOwnFarm}
                          trend={
                            currentReproductionPerformanceState.data
                              .HNInseminationRateTrendIndicator
                          }
                          benchmarkComparison={
                            currentReproductionPerformanceState.data
                              .HNInseminationRateBenchmarkFarms
                          }
                          current={parseFloat(
                            currentReproductionPerformanceState.data
                              .HNInseminationRate
                          )}
                          ownComparison={
                            currentReproductionPerformanceState.data
                              .HNInseminationRateOwnFarmOneYearBack
                          }
                          containerWidth="15px"
                          containerHeight="40px"
                        />
                      </StatusNumber>
                    </LattecButton>
                  </div>
                </div>
              )}
              <div
                className={`current-reproduction-performance__chart-table ${props.embedded
                  ? "current-reproduction-performance__chart-table--embedded"
                  : ""
                  }`}
                id="current-reproduction-performance-chart-and-table"
              >
                <div
                  className="current-reproduction-performance__chart"
                  id="current-reproduction-performance-chart"
                >
                  <PolarChart
                    data={currentReproductionPerformanceState.kpiChartData}
                    colors={isOwnFarm === 0 ? POLAR_COLOR_SCHEME_1 : COLOR_SCREME_2}
                    fillColors={isOwnFarm === 0 ? COLOR_SCREME_1_SEMI_FILL : COLOR_SCREME_2_SEMI_FILL}
                    dataType={isOwnFarm}
                  />
                </div>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                  xs={6}
                >
                  <p style={{ marginLeft: "auto", fontWeight: "bold" }}>{t("Score")}</p>
                  {props.embedded
                    ? currentReproductionPerformanceState.kpiChartData.map(
                      (el: any) => {
                        return (
                          <>
                            <LattecButton
                              key={el.key}
                              hovered={isOver(el.key)}
                              type="table-element"
                            >
                              <div className="current-reproduction-performance__table-element">
                                <div className="current-reproduction-performance__icon">
                                  <LattecIcon
                                    icon={el.key}
                                    color={
                                      isOver(el.key) ? "white" : "#103D82"
                                    }
                                  />
                                </div>
                                <p className="current-reproduction-performance__text">
                                  {t(getKPILongTitle(el.key))}
                                </p>
                              </div>
                              <p className="current-reproduction-performance__text">
                                {el.value ? (
                                  <Fragment>
                                    <span>{el.value}</span>
                                    <Tooltips
                                      content={t("Trend").toString()}
                                      position="bottom"
                                    >
                                      <Icon
                                        variant="fas"
                                        style={{ color: "gray" }}
                                        fontAwesomeName={`${el.trend < 0
                                          ? "arrow-alt-down"
                                          : el.trend > 0
                                            ? "arrow-alt-up"
                                            : "grip-lines"
                                          }`}
                                      />
                                    </Tooltips>
                                  </Fragment>
                                ) : (
                                  <span>{t("NotAvailable")}</span>
                                )}
                              </p>
                            </LattecButton>
                          </>
                        );
                      }
                    )
                    : currentReproductionPerformanceState.kpiChartData.map(
                      (el: iKpiData, index: number) => {
                        return (
                          <Button
                            variant="text"
                            key={el.key}
                            onMouseOver={() => handleMouseOver([el.key])}
                            onMouseLeave={() => handleMouseOver([""])}
                            onClick={() => {
                              history.push(`/${el.key}`);
                            }}
                            style={{
                              backgroundColor: isOver(el.key)
                                ? "#E4EDFC"
                                : "white",
                            }}
                            className={`tableBtn${getTrendColor({...el, isOwnFarm: isOwnFarm != 0})}`}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              className={`styleWrapper ${index === 0 ? "first" : ""
                                }`}
                            >
                              <Icon
                                variant="lattec"
                                // @ts-ignore
                                lattecName={el.key.toUpperCase()}
                                size="36"
                                colors={{ fillColor: "#103D82" }}
                              />
                              <p
                                className="current-reproduction-performance__text"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "auto",
                                }}
                              >
                                {t(getKPILongTitle(el.key))}
                              </p>
                              <p className="current-reproduction-performance__text">
                                {el.value ? (
                                  <Fragment>
                                    <span
                                      className="kpi-value"
                                      style={{ marginRight: "10px" }}
                                    >
                                      {el.value}
                                    </span>
                                    <Tooltips
                                      content={t("Trend").toString()}
                                      position="bottom"
                                    >
                                      <Icon
                                        variant="fas"
                                        style={{ color: "gray" }}
                                        fontAwesomeName={`${el.trend < 0
                                          ? "arrow-alt-down"
                                          : el.trend > 0
                                            ? "arrow-alt-up"
                                            : "grip-lines"
                                          }`}
                                      />
                                    </Tooltips>
                                  </Fragment>
                                ) : (
                                  <span>{t("NotAvailable")}</span>
                                )}
                              </p>
                            </Grid>
                          </Button>
                        );
                      }
                    )}
                </Grid>
              </div>
            </>
          )}
        </>
      </WidgetContent>
    </Widget>
  );
};

export default CurrentReproductionPerformance;
