// const handleTriggerStartTour = (id: number) => {
//     handleToggleProductTourModal(); <--- this can not be there
//     window.Intercom("startTour", id);
// };

// ==================
// TO ALLOW INTERCOM TO BE DEFINED ON WINDOW
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    Intercom: any;
    orbit: any;
  }
}

window.Intercom = window.Intercom || {};

// ==================

export const handleBootIntercom = () => {
  window.Intercom("boot", {
    app_id: "pwo0zue5",
    user_id: window.Intercom("getVisitorId"),
  });
};

export const handleTriggerStartTour = (id: number, cbFunction?: () => void) => {
  if (cbFunction) {
    cbFunction();
  }
  window.Intercom("startTour", id);
};
