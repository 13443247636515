import { BAR_AREA_SELECT, BAR_AREA_CLEAR_SELECTION } from "./constants";

export interface IState {
  selected: SVGRectElement | undefined,
  clearSelection: SVGRectElement | undefined,
}

export const initialState: IState = {
  selected: undefined,
  clearSelection: undefined,
};

export const BarAreaReducer =
  (state: IState = initialState, action: any) => {
    switch (action.type) {
      case BAR_AREA_SELECT:
        return {
          ...state,
          selected: action.payload,
          clearSelection: undefined,
        }

      case BAR_AREA_CLEAR_SELECTION:
        return {
          ...state,
          selected: undefined,
          clearSelection: state.selected,
        }

      default:
        return state;
    }
  };
