import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataTypeToggle } from "../../DataType";
import {
  DASHBOARD_TOGGLE,
  TOGGLE_HN_INSIGHTS,
  TOGGLE_SETTINGS,
} from "../../redux/Navigation/constants";
import "./Dashboard.scss";
import {
  BaselineReport,
  CurrentReproductionPerformance,
  Insights,
  ProportionOfDelayedSamples,
  StickConsumption,
} from "../../Cards";
import Modal from "../../Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  TOGGLE_PRODUCT_TOUR,
  TOGGLE_NOTIFICATION_BAR,
} from "../../redux/Navigation/constants";
import Settings from "../../Settings";
import { Breadcrumbs, Button, Grid, muiCore, Tooltips, Typography, Widget, WidgetHeader, WidgetContent } from "lattec-ui-library";
import Questionaire from "../../Questionaire";
import "./Override.scss";
import { GET_USER_OPTIONS_REQUEST } from "../../redux/UserOptions/constants";
import GetStartedModal from "../../components/GetStartedModal";

const Dashboard = () => {
  const { t } = useTranslation();

  const userOptionsState = useSelector((state: any) => state.userOptionsState);

  const navigationState = useSelector((state: any) => state.navigationState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userOptionsState.gotData) dispatch({ type: GET_USER_OPTIONS_REQUEST, payload: null });
  }, [dispatch]);

  const handleToggleNotificationBar = () =>
    dispatch({ type: TOGGLE_NOTIFICATION_BAR });
  const handleToggleProductTourModal = () =>
    dispatch({ type: TOGGLE_PRODUCT_TOUR });

  const handleToggleHnInsights = () => dispatch({ type: TOGGLE_HN_INSIGHTS });

  const handleToggleSettings = () => dispatch({ type: TOGGLE_SETTINGS });

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs
          breadcrumbs={[
            { label: t("Dashboard"), link: "/dashboard" },
            { label: t("HNADashboard") },
          ]}
          backToVariant={true}
          onClick={(link: any) => window.history.pushState(null, "", link)}
        />
        <div
          style={{ display: "flex", flexDirection: "row", alignSelf: "end" }}
        >
          <Tooltips content={t("OpenLibrary").toString()} position="bottom">
            <Button
              onClick={handleToggleNotificationBar}
              variant="text"
              size="small"
              className="infoButton"
              icon={{
                variant: "fas",
                fontAwesomeName: "books",
                position: "left",
                size: 18,
                colors: { fillColor: 'rgb(200, 200, 200)' }
              }}
            />
          </Tooltips>
          <Button
            onClick={handleToggleProductTourModal}
            variant="secondary"
            icon={{ variant: "fas", fontAwesomeName: "info", position: "left" }} // make solid icon
          >
            {t("GetStarted")}
          </Button>
          <Tooltips content={t("Settings").toString()} position="bottom">
            <Button
              onClick={handleToggleSettings}
              variant="text"
              size="medium"
              icon={{ variant: "fas", colors: { fillColor: 'rgb(200, 200, 200)' }, size: '18', fontAwesomeName: "cog", position: "left" }} // make solid icon
            />
          </Tooltips>
        </div>
      </div>

      <Grid direction="row" container style={{ flexWrap: "nowrap" }} alignItems="center" >
        <Grid item container justify="flex-start" >
          <Typography variant="Heading 2" text={t("dashBoardTitle")} className="header__first-row" style={{ color: 'rgb(113, 113, 113)' }} />
          <sup>
            <Typography variant="Heading 4" text={t("dashBoardTitleSup")} className="header" style={{ color: 'rgb(113, 113, 113)' }} />
          </sup>
        </Grid>
        <Grid item container justify="flex-end" >
          <DataTypeToggle toogleId={DASHBOARD_TOGGLE} />
        </Grid>
      </Grid>
      <muiCore.Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

      <Grid container direction="row">
        <Grid xs={12} lg={4} direction="column">
          <Grid
            spacingRight={2}
            spacingBottom={2}
          >
            <StickConsumption
              link={{ pathname: "/stick-consumption" }}
              stepGuideId={173890}
              toggleId={DASHBOARD_TOGGLE}
            />
          </Grid>
          <Grid
            spacingRight={2}
            spacingBottom={2}
          >
            <Insights />
          </Grid>
          <Grid
            spacingRight={2}
          >
            <BaselineReport
              wrapInWidget={{ pathname: "/baseline-report" }}
              simple={true}
              headers={["", ""]}
              toogleId={DASHBOARD_TOGGLE}
            />
          </Grid>
        </Grid>
        <Grid xs={12} lg={8} direction="column">
          <Grid>
            <Grid
              spacingBottom={2}
            >
              <CurrentReproductionPerformance toggleId={DASHBOARD_TOGGLE} />
            </Grid>
            <Grid>
              <ProportionOfDelayedSamples />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GetStartedModal
        open={navigationState.productTourOpen}
        onClose={handleToggleProductTourModal}
      />


      <muiCore.Modal
        style={{ padding: "10%" }}
        open={navigationState.settingsOpen}
        onClose={handleToggleSettings}
      >
        <Widget>
          <WidgetHeader>{t("Settings")}</WidgetHeader>
          <WidgetContent>
            <Settings />
          </WidgetContent>
        </Widget>
      </muiCore.Modal>


      <Modal
        visible={navigationState.hnInsightsOpen}
        close={handleToggleHnInsights}
        headline={t("ReproductionInsights")}
        width={75}
      >
        <Questionaire close={handleToggleHnInsights} />
      </Modal>
    </Fragment>
  );
};
export default Dashboard; // React.memo(Dashboard);
