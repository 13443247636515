let prodApiUrl = 'https://hnherdsux0302.herdnavigatorlive.com';
let devApiUrl = 'https://hnadvicewebapibeta.herdnavigatorlive.com';

export function getBaseUrl() {
  const env = getEnv();
  if (env === 'prod' ||env === 'stage') return prodApiUrl;
  return devApiUrl;
}

export const getEnv = () => {
  // @ts-ignore
  if (global?.orbit) {
    // @ts-ignore
    switch (global.orbit.env.ENV) {
      case 'prod':
      case 'stage':
      case 'test':
      case 'dev':
        // @ts-ignore
        return global.orbit.env.ENV;
      default:
        return 'prod';
    }
  } else if (window.location.hostname.includes('localhost')) {
    return 'dev';
  } else {
    return 'prod';
  }
  return 'dev';
};