import { DataTypeToggle } from "../../DataType";
import { STICK_CONSUMPTION_TOGGLE } from "../../redux/Navigation/constants";
import { Breadcrumbs, Grid, Icon, Typography } from 'lattec-ui-library';
import React from 'react';
import { useTranslation } from "react-i18next";

const StickConsumptionHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
    <Breadcrumbs
        breadcrumbs={[
          { label: t("Dashboard"), link: "/dashboard" },
          { label: t("HNADashboard"), link: "/reproductionperformancerepro" },
          { label: t("StickConsumption") },
        ]}
        backToVariant={false}
        onClick={(link) => window.history.pushState(null, "", link)}
      />
      <Grid direction="row" container item alignItems="center" justify="flex-start">
        <Grid item >
          <div style={{ marginBottom: '16px', marginRight: '20px' }}>
            <Icon lattecName="StickConsumption" variant="lattec" size={32} colors={{ fillColor: 'rgb(113, 113, 113)' }} />
          </div>
        </Grid>
        <Grid item >
          <Typography variant="Heading 2" text={t("StickConsumption")} style={{ paddingBottom: '16px', color: 'rgb(113, 113, 113)' }} />
        </Grid>

      </Grid>
      <Grid container>
        <Grid>
          <Typography variant="Body Default" text={t("StickConsumptionSubHeader")} style={{ color: 'rgb(113, 113, 113)', fontStyle: 'italic' }} />
        </Grid>
        <Grid item style={{ marginLeft: 'auto' }}>
          <DataTypeToggle toogleId={STICK_CONSUMPTION_TOGGLE} />
        </Grid>
      </Grid>
      </>
  )
}


export default StickConsumptionHeader;