import { BarChart, } from "../../../Charts";
import { IFilterState, } from "../../../redux/Filters/reducer";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_COWS_LIST_REQUEST,
  HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
} from "../../../redux/CowsList/constants";
import { SELECT_LACTATION_FILTERS, } from "../../../redux/Filters/constants";
import AisChart from "../KPICharts/AISChart";
import { GROUP_3_COLOR_PALETTE } from "../../../styles/ColorCodes";

const AIS: React.FC = () => {
  const { t } = useTranslation();

  const path = "ais";

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);

  const dispatch = useDispatch();
  const keys = ["Inseminated", "NotInseminated"];
  const filters: any = {};

  useEffect(
    () => {
      const startDate = new Date(filtersState.today);
      startDate.setDate(
        startDate.getDate() -
        filtersState.anoestrusLengthSelectedTimePeriod.days
      );
      filtersState.lactationFilters.culledChecked =
        filtersState.lactationFilters.l1Checked =
        filtersState.lactationFilters.l2Checked =
        filtersState.lactationFilters.l3Checked =
        filtersState.lactationFilters.i1Checked =
        filtersState.lactationFilters.i2Checked =
        filtersState.lactationFilters.i3Checked =
        filtersState.lactationFilters.i4Checked =
        true;

      dispatch({
        type: GET_COWS_LIST_REQUEST,
        payload: {
          filters,
          keys,
          path,
          startDate: startDate,
          stopDate: filtersState.today,
          lactationFilters: filtersState.lactationFilters,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]
  );

  const handleToggleLactationAndInseminationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return (
    <KpiLayout
      title={t("InseminationSuccess")}
      subHeader={t(
        "EvaluatesTheNumberOfSuccessfulInseminationsBasedOnProgesteroneLevelOn25DaysFromInsemination(DFAI)."
      )}
      libraryUrl="ais/lvl2/summary"
      kpi="AIS"
      chartImage={AisChart}
      handleToggleLactationFilter={handleToggleLactationAndInseminationFilter}
      handleToggleInseminationFilter={handleToggleLactationAndInseminationFilter}
      chartSlot1={[
        {
          title: t("InseminationSuccessOnDIM"),
          chart: (
            <BarChart
              colors={GROUP_3_COLOR_PALETTE}
              filterKeys={[
                { key: "InseminationState", value: "name" },
                { key: "ranges", value: "x" },
              ]}
              filters={["DaysFromCalving",
                "InseminationState",
                filtersState.lactationFilters.i1Checked ? "i1Checked" : "i1UnChecked",
                filtersState.lactationFilters.i2Checked ? "i2Checked" : "i2UnChecked",
                filtersState.lactationFilters.i3Checked ? "i3Checked" : "i3UnChecked",
                filtersState.lactationFilters.i4Checked ? "i4Checked" : "i4UnChecked",
              ]}
              path={path}
              title={t("FirstLutealPhaseLngth[Days]")}
              tooltip={{
                displayAxis: "neither",
                title: 'ykey'
              }}
              data={cowsListState.graphP2Data}
              cowList={cowsListState.filteredData}
              // to trigger deselection of scatter area
              cowListOpen={false}
              keys={["Pregnant", "PotentiallyPregnant", "NotPregnant"]}
              indexBy="Bin"
              yLegend={t("NumberOfInseminations")}
              xLegend={t("DIMOnInsemination")}
              maxValue={cowsListState.graphP2Max}
            />
          ),
        },
        {
          title: t("%InseminationSuccessOnDIM"),
          chart: (
            <BarChart
              colors={GROUP_3_COLOR_PALETTE}
              filterKeys={[
                { key: "InseminationState", value: "name" },
                { key: "ranges", value: "x" },
              ]}
              filters={["DaysFromCalving",
                "InseminationState",
                filtersState.lactationFilters.i1Checked ? "i1Checked" : "i1UnChecked",
                filtersState.lactationFilters.i2Checked ? "i2Checked" : "i2UnChecked",
                filtersState.lactationFilters.i3Checked ? "i3Checked" : "i3UnChecked",
                filtersState.lactationFilters.i4Checked ? "i4Checked" : "i4UnChecked",]}
              path={path}
              title={t("FirstLutealPhaseLngth[Days]")}
              tooltip={{
                displayAxis: "neither",
                title: "ykey",
                postFix: ' [%]'
              }}
              data={cowsListState.graphP2Data}
              cowList={cowsListState.filteredData}
              // to trigger deselection of scatter area
              cowListOpen={false}
              keys={["Pregnant ", "Potentially pregnant ", "Not pregnant "]}
              indexBy="Bin"
              yLegend="% insemination success"
              xLegend={t("DIMOnInsemination")}
              maxValue={100}
              inPercent={true}
            />
          ),
        },
      ]}
      chartSlot2={[
        {
          title: t("TimingOfAI"),
          chart: (
            <BarChart
              colors={GROUP_3_COLOR_PALETTE}
              filterKeys={[
                { key: "InseminationState", value: "name" },
                { key: "ranges", value: "x" },
              ]}
              filters={["HoursBetweenHeatAndAI",
                "InseminationState",
                filtersState.lactationFilters.i1Checked ? "i1Checked" : "i1UnChecked",
                filtersState.lactationFilters.i2Checked ? "i2Checked" : "i2UnChecked",
                filtersState.lactationFilters.i3Checked ? "i3Checked" : "i3UnChecked",
                filtersState.lactationFilters.i4Checked ? "i4Checked" : "i4UnChecked",]}
              path={path}
              title={t("FirstLutealPhaseLngth[Days]")}
              tooltip={{
                displayAxis: "neither",
                title: 'ykey'
              }}
              data={cowsListState.graphP3Data}
              cowList={cowsListState.filteredData}
              // to trigger deselection of scatter area
              cowListOpen={false}
              keys={["Pregnant", "PotentiallyPregnant", "NotPregnant"]}
              indexBy="Bin"
              yLegend={t("NumberOfInseminations")}
              xLegend={t("HoursBetweenHeatAndAI")}
              maxValue={cowsListState.graphP3Max}
            />
          ),
        },
      ]}
    />
  );
};

export default AIS;
