import React from "react";
import "./StatusNumber.scss";
import { iStatusNumber } from "./interface";
import { useTranslation } from "react-i18next";

const StatusNumber: React.FC<iStatusNumber> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={"status-number" + props.className ? (' ' + props.className) : ''}>
      <div className={props.right ? "status-number__wrapper-right" : "status-number__wrapper"} >
        <p className="status-number__number" id={props.id} style={{
          color: (!props.colorId ? "#b69803" : props.colorId < 0 ? "#CC2900" : "#77AD1C")
        }}>{props.number !== undefined ? props.number + "" + (props.unit !== undefined ? props.unit : "") : t("N/A")}</p>
        <div className="status-number__content">
          {props.children}
        </div>
      </div>
      {(props.text ?
        <div className={props.right ? "status-number__wrapper-right" : "status-number__wrapper"} >
          <div>
            <h5 className="status-number__text" >{props.text}</h5>
          </div>
        </div> : <></>)
      }
    </div>
  );
};

export default StatusNumber;
