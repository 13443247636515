import Plotly, { Data } from "../../plotly/bundle";
import React, { useState } from "react";
import plotComponentFactory from "react-plotly.js/factory";
import { iScatterChart } from "./interface";
import { useDispatch } from "react-redux";
import { OPEN_COW_LIST } from "../../redux/Navigation/constants";
import { BAR_AREA_CLEAR_SELECTION } from "../../redux/BarArea/constants";
import { HANDLE_COW_FILTER_LIST_DESELECT, HANDLE_COW_LIST_NEW_SELECTION } from "../../redux/CowsFilterList/constants";
import './style.scss'
import { useTranslation } from "react-i18next";

const ScatterChart: React.FC<iScatterChart> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  //Turns out simply setting a value is enough to allow for the resizing
  const [wantedSize, setWantedSize] = useState<undefined | { width: number, height: number }>(undefined);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      let result = {
        width: entries[0]?.contentRect.width,
        height: entries[0]?.contentRect.height,
      }
      setWantedSize(result);
    });
    let elem1 = document.getElementById("resizePaneOne");
    elem1 && resizeObserver.observe(elem1);
  }, [])


  if (props.data.length === 0) return null;

  let data: Data[] = props.data.map((dataObj, index) => {
    let marker = {
      color: props.colors[index],
      size: 12,
      symbol: dataObj.id === "NotInseminated" || dataObj.id === "NotPregnant" ? 'x-thin-open' : 'circle',
      line: dataObj.id === "NotInseminated" || dataObj.id === "NotPregnant" ? { width: 3 } : undefined
    };
    let newData: Data = {
      x: dataObj.data.map((datas) => datas.x),
      y: dataObj.data.map((datas) => datas.y),
      customdata: dataObj.data.map((datas) => {
        return [datas.actualX, datas.actualY];
      }),
      type: "scatter",
      mode: "markers",
      marker,
      name: dataObj.id,
      text: dataObj.data.map(datas =>
        datas.cowNo + ": " + t(dataObj.id) + "<br>" + t("DIMOnHeat") + ": " + datas.actualX + "<br>" + t("HeatNumber") + ": " + datas.actualY),
      hoverinfo: 'text',
    }
    return newData
  })
  if (props.reverseOrder) data.reverse();

  let Plot = plotComponentFactory(Plotly);

  return (
    <Plot
      style={{ width: "100%", height: "300px" }}
      className="scatter-plot"
      onInitialized={(f, g) => {
        g.style.cursor = "crosshair";
      }}
      data={data}
      onClick={(event) => {
        // this will de-select area if any area is selected
        dispatch({ type: OPEN_COW_LIST });

        // if any bar is selected then clear it
        dispatch({ type: BAR_AREA_CLEAR_SELECTION, payload: undefined });

        // take the last data points actual x and y coordinates
        const customdata: any =
          event.points.length > 0
            ? event.points[event.points.length - 1].customdata
            : null;
        const actualX = customdata ? customdata[0] : null;
        const actualY = customdata ? customdata[1] : null;

        dispatch({
          type: HANDLE_COW_LIST_NEW_SELECTION,
          payload: {
            keys: props.keys,
            path: props.path,
            data: props.cowList,
            delayed: props.delayed,
            selection: [{ actualX, actualY }],
          },
        });
      }}
      onHover={(event) => {
        // @ts-ignore
        event.event.target.style.cursor = "pointer";
      }}
      onUnhover={(event) => {
        // @ts-ignore
        event.event.target.style.cursor = "crosshair";
      }}
      onDeselect={() => {
        dispatch({ type: HANDLE_COW_FILTER_LIST_DESELECT, payload: undefined });
      }}
      onSelected={(event) => {
        if (event && event.points.length > 0) {
          if (!props.cowListOpen) {
            dispatch({ type: OPEN_COW_LIST });
            // if the cow list is not open, just open that, and do not select cow,
            // since selection will be gone when cow list opens
            return;
          }

          // if any bar is selected then clear it
          dispatch({ type: BAR_AREA_CLEAR_SELECTION, payload: undefined });

          const data: any[] = event.points.map((point) => {
            const customdata: any = point.customdata;
            const actualX = customdata ? customdata[0] : null;
            const actualY = customdata ? customdata[1] : null;

            return { actualX, actualY };
          });

          dispatch({
            type: HANDLE_COW_LIST_NEW_SELECTION,
            payload: {
              keys: props.keys,
              path: props.path,
              data: props.cowList,
              delayed: props.delayed,
              selection: data,
            },
          });
        }
      }}
      useResizeHandler
      layout={{
        hovermode: 'closest',
        margin: { t: 10, b: 17 },
        font: { family: "Roboto", color: "575757" },
        xaxis: {
          // title: props.xLegend,
          dtick: 20,
          showgrid: false,
          range: props.maxX !== undefined ? [0, props.maxX] : undefined,
          zeroline: false,
        },
        yaxis: {
          title: props.yLegend,
          dtick: 1,
          range: props.maxY !== undefined ? [0, props.maxY] : undefined,
          zeroline: false,
        },
        dragmode: "lasso",
        autosize: true,
        showlegend: false,
        annotations: [
          {
            xref: "paper",
            yref: "paper",
            x: 0.95,
            xanchor: "left",
            y: 0,
            yanchor: "top",
            text: props.xLegend,
            showarrow: false,
            font: { size: 14 }
          },
        ],
      }}
      config={{
        displayModeBar: false,
        responsive: true,
        showTips: true,
        scrollZoom: true,
        autosizable: true,
      }}
    />
  );
};

export default ScatterChart;
