import { BarChart } from '../../../Charts';
import { IFilterState, } from '../../../redux/Filters/reducer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_LACTATION_FILTER } from '../../../redux/CowsList/constants';
import { SELECT_LACTATION_FILTERS, } from '../../../redux/Filters/constants';
import PBSSChart from "../KPICharts/PBSSChart";
import { GROUP_3_COLOR_PALETTE } from '../../../styles/ColorCodes';

const PBSS: React.FC = () => {
  const path = "sea"; // using same data as sea page so we just reuse
  const { t } = useTranslation();

  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);
  const navigationState = useSelector((state: any) => state.navigationState);
  const [secondaryExpandedState, handleToggleSecondaryExpandedState] =
    useState(false);

  const dispatch = useDispatch();

  const keys = ["DaysFromCalving", "PBSSState"];
  const filters: any = {};

  useEffect(() => {
    const startDate = new Date(filtersState.today);
    startDate.setDate(
      startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days
    );
    filtersState.lactationFilters.culledChecked =
      filtersState.lactationFilters.l1Checked =
      filtersState.lactationFilters.l2Checked =
      filtersState.lactationFilters.l3Checked =
      true;

    // get the cowlist
    dispatch({
      type: GET_COWS_LIST_REQUEST,
      payload: {
        filters,
        keys,
        path,
        startDate,
        stopDate: filtersState.today,
        lactationFilters: filtersState.lactationFilters,
      },
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]);

  const handleToggleLactationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return (
    <KpiLayout
      title={t("FinalEstablishmentOfPregnancy")}
      subHeader={t("EvaluatesTheNumberOfSuccessfulEmbryoAttachments(35DFAI)WhichResultInASuccessfulFinalEstablishmentOfPregnancyBasedOnProgesteroneLevelOn55DFAI(AbsenceOfEarlyAbortion)")}
      libraryUrl="pbss/lvl2/summary"
      kpi='PBSS'
      chartImage={PBSSChart}
      handleToggleLactationFilter={handleToggleLactationFilter}
      chartSlot1={[{
        title: t("FinalEstablishmentOfPregnancyAndOccurrenceOfEarlyAbortion"),
        chart: <BarChart
          filterKeys={[
            { key: "PBSSState", value: "name" },
            { key: "ranges", value: "x" },
          ]}
          filters={["DaysFromCalving", "PBSSState"]}
          path={path}
          title={t("FinalEstablishmentOfPregnancyAndOccurrenceOfEarlyAbortion")}
          tooltip={{
            displayAxis: 'neither',
            title: 'ykey'
          }}
          data={cowsListState.graphP3Data}
          cowList={cowsListState.filteredData}
          cowListOpen={navigationState.cowListOpen}
          keys={["Successful", "Likely successful", "Early abortion"]}
          indexBy="Bin"
          yLegend={t("NumberOfCows")}
          xLegend={t("DIMOnInsemination")}
          colors={GROUP_3_COLOR_PALETTE}
          maxValue={cowsListState.graphP3Max}
        />
      }]}
      chartSlot2={[
        {
          title: t("EmbryoAttachment"),
          chart: <BarChart
            filterKeys={[
              { key: "SEAState", value: "name" },
              { key: "ranges", value: "x" },
            ]}
            filters={["DaysFromCalving", "SEAState"]}
            path={path}
            title={t("EmbryoAttachment")}
            tooltip={{
              displayAxis: 'neither',
              title: 'ykey'
            }}
            data={cowsListState.graphP2Data}
            cowList={cowsListState.filteredData}
            cowListOpen={navigationState.cowListOpen}
            keys={["Successful", "Likely successful", "Early embryo death"]}
            indexBy="Bin"
            yLegend={t("NumberOfCows")}
            xLegend={t("DIMOnInsemination")}
            maxValue={cowsListState.graphP2Max}
            colors={GROUP_3_COLOR_PALETTE}
          />
        }
      ]}
    />
  )
}

export default PBSS