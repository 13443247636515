import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
// @ts-ignore
import { i18nState } from "redux-i18n";
import { BaselineReportReducer } from "./BaselineReort/reducer";
import { CowsListReducer } from "./CowsList/reducer";
import { CowsListFilterReducer } from "./CowsFilterList/reducer";
import { BarAreaReducer } from "./BarArea/reducer";
import { CurrentReproductionPerformanceReducer } from "./CurrentReproductionPerformance/reducer";
import { FiltersReducer } from "./Filters/reducer";
import { HNInsightsReducer } from "./HNInsights/reducer";
import { NavigationReducer } from "./Navigation/reducer";
import { ProportionOfDelayedSamplesReducer } from "./ProportionOfDelayedSamples/reducer";
import { SettingsReducer } from "./Settings/reducer";
import { StickConsumptionReducer } from "./StickConsumption/reducer";
import { PerformanceScoreHistoryReducer } from "./PerformanceScoreHistory/reducer";
import { UserOptionsReducer } from "./UserOptions/reducer";

const reducers = (history: History) =>
  combineReducers({
    i18nState: i18nState as any,
    router: connectRouter(history),
    hnInsightsState: HNInsightsReducer,
    navigationState: NavigationReducer,
    stickConsumptionState: StickConsumptionReducer,
    proportionOfDelayedSamplesState: ProportionOfDelayedSamplesReducer,
    baselineReportState: BaselineReportReducer,
    currentReproductionPerformanceState: CurrentReproductionPerformanceReducer,
    cowsListState: CowsListReducer,
    cowsListFilterState: CowsListFilterReducer,
    barAreaReducerState: BarAreaReducer,
    filtersState: FiltersReducer,
    settingsState: SettingsReducer,
    performanceScoreHistoryState: PerformanceScoreHistoryReducer,
    userOptionsState: UserOptionsReducer,
  });

export default reducers;
