import moment from "moment";

export const subtractYearFromDate = (date: Date): Date => {
  let startdate = moment(date);
  startdate = startdate.subtract(1, "years");

  return startdate.toDate();
}

export const getTimeDifferenceToString = (date: Date, daysDelay?: number) => {
  const delay: number = daysDelay !== undefined ? daysDelay : 0;

  const newDate: Date = new Date(date);
  newDate.setDate(newDate.getDate() + delay);
  return `(${formatDateNational(newDate)})`;
};

export const formatDateToISOString = (date: Date): string => {
  const dateSeparator: string = "-";
  const dd: string =
    date.getDate() < 10
      ? "0" + date.getDate().toString()
      : date.getDate().toString();
  const m: number = date.getMonth() + 1;
  const mm: string = m < 10 ? "0" + m.toString() : m.toString();
  const yyyy: string = date.getFullYear().toString();

  return yyyy.concat(dateSeparator, mm, dateSeparator, dd);
};

export const isValidDate = (date: any) => {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
};

const getFormat = () => {
  let language = "en";
  const orbitWindow: any = window;
  if (
    orbitWindow.orbit &&
    orbitWindow.orbit.settings &&
    orbitWindow.orbit.settings.language
  ) {
    language = orbitWindow.orbit.settings.language;
  }

  if (language === "en") return "en-GB"
  return language
};

export const formatDateNational = (date: Date) => {
  let format = "YYYY/MM/DD";

  debugger;

  if (window.orbit && window.orbit.settings && window.orbit.settings.date_format) {
    const formats = window.orbit.settings.date_format.split("(");
    format = formats[formats.length > 1 ? 1 : 0];
    format = format.replace(")", "");
  }

  // if its not a date, try parsing it as a string with a date
  if (date.getTime === undefined) date = new Date(date)

  if (isNaN(date.getTime())) return null

  const value = moment(date).format(format);
  return value;
};

export const formatMonthNational = (year: number, month: number) => {
  const date: Date = new Date(year, month);
  return new Date(date).toLocaleDateString(getFormat(), {
    year: "numeric",
    month: "short",
  });
};

export const formatNumberNational = (
  n: number,
  minimumFractionDigits: number
) => {
  if (isNaN(n)) return "-";
  return n.toLocaleString(getFormat(), { minimumFractionDigits });
};
