import React from "react";
import { useSelector } from "react-redux";
import { Route as AppRoute } from "react-router-dom";
import NotificationBar from "./LibrarySidebar";

import {
  LayoutContainer,
  LayoutContent,
} from "./index";

const LayoutRoute = ({ component: Component, ...rest }: any) => {
  const navigationState = useSelector((state: any) => state.navigationState);

  return (
    <AppRoute
      {...rest}
      render={(matchProps: any) => (
        <div>
          <LayoutContainer>
            <LayoutContent>
              <Component {...matchProps} />
            </LayoutContent>
            {navigationState.notificationBarOpen && <NotificationBar />}
          </LayoutContainer>
        </div>
      )}
    />
  );
};

export default LayoutRoute;
