import { DataTypeDisplay } from "../../DataType";
import { Button } from "lattec-ui-library";
import React from "react";
import { iTableData, iUserOptionsState } from "./interface";
import {
  BARS_COLOR_SCHEME_1,
  BARS_COLOR_SCHEME_2
} from "../../styles/ColorCodes";
import { TFunction } from "i18next";

const yellow = "#FFD300";
const red = "#FF3300";
const green = "#77AD1C";

const trendColors = { green, yellow, red };
const formatNumber = (value: number) => {
  if (value === 0) return "-";
  if (isNaN(value)) return "";
  return (value > 0 ? "+" : "") + value.toFixed(0);
};

export const formatTableData = (
  tableData: iTableData[],
  userOptionsState: iUserOptionsState,
  t: TFunction,
  handlePinToDashboard: (kpiName: string) => void
) => {
  return tableData.map((dataPoint) => {
    const diffNumber =
      userOptionsState.isOwnFarm === 0
        ? parseFloat(dataPoint.CurrentPointValue) - dataPoint.Expected
        : parseFloat(dataPoint.CurrentPointValue) -
        parseFloat(
          dataPoint.OneYearAgoValue !== "-"
            ? dataPoint.OneYearAgoValue
            : dataPoint.StartingPointValue
        );
    return {
      Pin: (
        <Button
          style={{ padding: 0, margin: 0, minWidth: 16 }}
          icon={{
            fontAwesomeName: "thumbtack",
            variant:
              userOptionsState.sticksUsed === dataPoint.KPIName ? "fas" : "fal",
            colors: { fillColor: "#0091FF", strokeColor: "#0091FF" },
          }}
          variant="text"
          onClick={() => handlePinToDashboard(dataPoint.KPIName)}
        />
      ),
      StickConsumptionKPI: t(dataPoint.KPIName),
      "Benchmark farms": Math.round(dataPoint.Expected),
      MyFarmReference: Math.round(
        dataPoint.OneYearAgoValue !== "-"
          ? Number(dataPoint.OneYearAgoValue)
          : Number(dataPoint.StartingPointValue)
      ),
      SticksUsed: dataPoint.CurrentPointValue,
      Benchmark: (
        <DataTypeDisplay
          currentValueId="baseline-report-current-value-bar"
          referenceValudId="baseline-report-reference-value-bar"
          isOwnFarm={userOptionsState.isOwnFarm}
          layout="horizontal"
          colors={
            userOptionsState.isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
          }
          trend={dataPoint.Trend}
          benchmarkComparison={dataPoint.Expected.toFixed(0)}
          current={parseFloat(dataPoint.CurrentPointValue)}
          minValue={0}
          ownComparison={parseFloat(
            dataPoint.OneYearAgoValue !== "-"
              ? dataPoint.OneYearAgoValue
              : dataPoint.StartingPointValue
          )}
          containerWidth="204px"
          containerHeight="20px"
        />
      ),
      Difference: (
        <div
          style={{
            fontWeight: "bold",
            color:
              diffNumber > 1
                ? trendColors.red
                : diffNumber < -1
                  ? trendColors.green
                  : trendColors.yellow,
          }}
        >
          {formatNumber(diffNumber)}
        </div>
      ),
    };
  });
};

export const getDataRelation = (kpi: string) => {
  let hoveredKpiList = [""];

  switch (kpi) {
    case "ProportionOfCowsBred":
      hoveredKpiList = ["hnir"];
      break;

    case "CalvingToFirstAIInterval":
      hoveredKpiList = ["doa", "hnir"];
      break;

    case "FirstToLastAIInterval":
    case "PregnancyRate":
    case "SticksUsedPrPregnancy":
    case "SticksUsedFromFirstToLastAI":
    case "SticksUsedPrCow":
      hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
      break;

    case "AIsPrCow":
    case "ConceptionRate":
    case "AIsPrPregnancy":
      hoveredKpiList = ["ais", "sea", "pbss"];
      break;

    case "InseminationRate":
      hoveredKpiList = ["doa", "cr", "hnir"];
      break;

    case "OpenDays":
      hoveredKpiList = ["od"];
      break;

    case "SticksUsedOnFollicularCysts":
      hoveredKpiList = ["cr"];
      break;

    case "SticksUsedOnLutealCysts":
      hoveredKpiList = ["doa", "cr"];
      break;
  }

  return hoveredKpiList;
};
