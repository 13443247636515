import React from "react";
import { useTranslation } from "react-i18next";
import './style.scss'
import { SetIdLineWitdh, SetIdPolygonScale, HoverKPIChart } from '../helper';

function SeaChart() {
  const { t } = useTranslation();

  const ProgesteroneProfile: string[] = ["ProgesteroneProfile", "ProgesteroneProfile1"];

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox="770 450 385 185"
      id="seaChart"
    >
      <g id="SEA_-_Embryo_Attachment">
        <g>
          <g>
            <g>
              <rect x="773.3" y="459.6" className="st5" width="376" height="161" />
              <path id="ProgesteroneProfile"
                className="st6" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
					c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
					h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
					c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
					c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
					c6.7-2.3,44.4-3.8,60.3-3.8"
                onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
                onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }} >
                <title>{t("ProgesteroneProfile")}</title>
              </path>

              <rect x="771.8" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.2756 -411.8359)" className="st7" width="5" height="5" />
              <g>
                <rect id="Rectangle_1527_26_" x="1091.2" y="459.4" className="st4" width="44" height="142"
                  onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart1", true);
                    event.currentTarget.style.opacity = "0.8";
                  }}
                  onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                    HoverKPIChart("Chart1");
                    event.currentTarget.style.opacity = "1";
                  }} >
                  <title>{t("PeriodOfEarlyEmbryoDeaths25_35DFAI")}</title>
                </rect>
              </g>
              <g>
                <defs>
                  <rect id="Rectangle_1527_21_" x="1091.2" y="459.6" width="44" height="137" />
                </defs>
                <clipPath id="Rectangle_1527_23_">
                  <use xlinkHref="#Rectangle_1527_21_" style={{ overflow: 'visible' }} />
                </clipPath>
                <path className="st22" d="M1135.2,475.8c5.9-0.2,11.2-0.3,15-0.3" />
                <path id="ProgesteroneProfile1"
                  className="st23" d="M774.2,580.7h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
						c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52
						s9.2-15.5,14.2-15.5h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
						c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
						c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
						c1.9-0.7,6.4-1.3,12.1-1.8"
                  onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
                  onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }} >
                  <title>{t("ProgesteroneProfile")}</title>
                </path>
                <g className="st24">
                  <g>
                    <path
                      id="EmbryoAttachmentDeath"
                      className="st20"
                      d="M1101.3,477.7c3,0,7.8,4.6,9.8,12.6c1.4,5.7,2.6,12.3,3.6,19.3"
                      onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                        SetIdLineWitdh(["EmbryoAttachmentDeath"], true);
                        SetIdPolygonScale(["EmbryoAttachmentDeathArrow"], 2);
                      }}
                      onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                        SetIdLineWitdh(["EmbryoAttachmentDeath"]);
                        SetIdPolygonScale(["EmbryoAttachmentDeathArrow"]);
                      }} >
                      <title>{t("EmbryoAttachmentDeath")}</title>
                    </path>
                    <g>
                      <polygon
                        id="EmbryoAttachmentDeathArrow"
                        className="st21"
                        points="1109.4,508.8 1115.6,516.6 1119.3,507.3"
                        onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                          SetIdLineWitdh(["EmbryoAttachmentDeath"], true);
                          SetIdPolygonScale(["EmbryoAttachmentDeathArrow"], 2);
                        }}
                        onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                          SetIdLineWitdh(["EmbryoAttachmentDeath"]);
                          SetIdPolygonScale(["EmbryoAttachmentDeathArrow"]);
                        }} >
                        <title>{t("EmbryoAttachmentDeath")}</title>
                      </polygon>
                    </g>
                  </g>
                </g>
                <g className="st24">
                  <g>
                    <path
                      id="SuccessfulEmbryoAttachment"
                      className="st13"
                      d="M1101.1,477.7c7.7-0.7,17.6-1.2,27-1.6"
                      onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                        SetIdLineWitdh(["SuccessfulEmbryoAttachment"], true);
                        SetIdPolygonScale(["SuccessfulEmbryoAttachmentArrow"], 2);
                      }}
                      onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                        SetIdLineWitdh(["SuccessfulEmbryoAttachment"]);
                        SetIdPolygonScale(["SuccessfulEmbryoAttachmentArrow"]);
                      }} >
                      <title>{t("SuccessfulEmbryoAttachment")}</title>
                    </path>
                    <g>
                      <polygon
                        id="SuccessfulEmbryoAttachmentArrow"
                        className="st12"
                        points="1126.8,481.2 1135.2,475.8 1126.4,471.2"
                        onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                          SetIdLineWitdh(["SuccessfulEmbryoAttachment"], true);
                          SetIdPolygonScale(["SuccessfulEmbryoAttachmentArrow"], 2);
                        }}
                        onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => {
                          SetIdLineWitdh(["SuccessfulEmbryoAttachment"]);
                          SetIdPolygonScale(["SuccessfulEmbryoAttachmentArrow"]);
                        }} >
                        <title>{t("SuccessfulEmbryoAttachment")}</title>
                      </polygon>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>

                  <rect x="1143.2" y="598.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8196 -634.0139)" className="st7" width="5" height="5" />
                  <polyline className="st9" points="1146.6,601.4 774.1,601.4 774.1,463.1 						"
                    onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "6" }}
                    onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "3" }} >
                    <title>{t("ProgesteroneLevel")} / {t("Daysinmilk")}</title>
                  </polyline>

                  <g>
                  <path
        id='Heat1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 600.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 680.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat3'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 760.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='AI1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 690.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

        <path
        id='AI2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 770.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>
        </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg >
  )
}
export default SeaChart;