import { Tooltips } from "lattec-ui-library";
import React, { useState } from "react";
import "./HoverGuide.scss";
import { iHoverGuide, PositionTypes} from "./interface";
const HoverGuide: React.FC<iHoverGuide> = (props) => {

  let positions = props.direction === undefined ? "top" : props.direction
  let position: PositionTypes = positions as PositionTypes;
   return (

    <div
      className="Tooltip-Wrapper" >
      <Tooltips   content={props.content} position={position} ><>{props.children}</></Tooltips>
    </div >

  );
};

export default HoverGuide;