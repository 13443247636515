import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HANDLE_COW_LIST_NEW_FILTER,
  HANDLE_COW_FILTER_LIST_DESELECT,
  HANDLE_COW_SHOW_HIDDEN,
} from "../../redux/CowsFilterList/constants";
import { BAR_AREA_SELECT } from "../../redux/BarArea/constants";
import { OPEN_COW_LIST } from "../../redux/Navigation/constants";
import { BarChart as LUIBarChart } from "lattec-ui-library";
import { iBarChart, iSelectedBar } from "./interface";
import {
  iAddData,
  iData,
} from "lattec-ui-library/dist/components/Charts/interfaces";
import { PlotMouseEvent } from "plotly.js";
import { useTranslation } from "react-i18next";

const BarChart: React.FC<iBarChart> = (props) => {
  const dispatch = useDispatch();
  const [chartSize, setChartSize] = useState<undefined | { width: number; height: number }>(undefined);
  const chartSizeRef = React.useRef(chartSize);

  const barAreaReducerState:
    | { selected: iSelectedBar | undefined }
    | undefined = useSelector((state: any) => state.barAreaReducerState);
  const myContainer = useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      let result = {
        width: entries[0]?.contentRect.width,
        height: entries[0]?.contentRect.height,
      };

      if (result.width != chartSizeRef.current?.width) {
        setChartSize(result);
        chartSizeRef.current = result;
      }
    });
    let id = myContainer.current?.closest("[id^='resizePane']")?.id;
    if (!id) return;
    let elem = document.getElementById(id);
    elem && resizeObserver.observe(elem);
  }, []);

  const data: iData[] = props.keys.map((key, index) => {
    const additionalData: iAddData[] = [];
    if (props.tooltip.title === "ykey") {
      additionalData.push({
        label:
          t(key) +
          (props.tooltip.postFix != null ? " " + props.tooltip.postFix : ""),
        tooltip: "title",
        data: { display: "y" },
      });
    }
    let trace: iData = {
      x: props.data.map((elem) => elem[props.indexBy]),
      y: props.data.map((elem) => elem[key]),
      color: props.colors?.[index],
      traceName: props.keys[index],
      additionalData,
    };
    return trace;
  });

  let backgroundData: undefined | iData = undefined;
  if (props.data?.filter((elem) => elem["reference"]).length > 0) {
    backgroundData = {
      x: props.data.map((elem) => elem[props.indexBy]),
      y: props.data.map((elem) => elem["reference"]),
    };
  }

  const clicked = (e: Readonly<PlotMouseEvent>) => {
    if (!props.cowListOpen) dispatch({ type: OPEN_COW_LIST });

    let selected = {
      barNo: e.points[e.points.length - 1].pointNumber,
      traceNo: e.points[e.points.length - 1].curveNumber,
      plotName: props.title,
    };

    if (
      barAreaReducerState?.selected == null ||
      selected.barNo !== barAreaReducerState.selected.barNo ||
      selected.traceNo !== barAreaReducerState.selected.traceNo
    ) {
      // new bar selected
      dispatch({ type: BAR_AREA_SELECT, payload: selected });

      const params: any = {};
      for (const fk of props.filterKeys ?? []) {
        if (fk.value === "x") params[fk.key] = e.points[0].x;
        if (fk.value === "name") params[fk.key] = e.points[0].data.name;
      }
      const payload = {
        filters: params,
        keys: props.filters,
        path: props.path,
        data: props.cowList,
        delayed: props.delayed,
      };

      dispatch({ type: HANDLE_COW_LIST_NEW_FILTER, payload, });
    } else {
      // this bar de-selected
      const payload = {
        path: props.path,
        data: props.cowList,
      };
      dispatch({ type: BAR_AREA_SELECT, payload: undefined });
      dispatch({ type: HANDLE_COW_SHOW_HIDDEN, payload, });
      dispatch({ type: HANDLE_COW_FILTER_LIST_DESELECT, payload: undefined, });
    }
  };

  const selected =
    barAreaReducerState?.selected?.plotName === props.title
      ? barAreaReducerState.selected
      : undefined;

  return (
    <div ref={myContainer}>
      <LUIBarChart
        data={data}
        backgroundData={backgroundData}
        selected={selected}
        xLegend={props.xLegend}
        yLegend={props.yLegend}
        tooltip={{
          display: props.tooltip.displayAxis,
        }}
        containerHeight="270px"
        display="gridaxis"
        onClick={clicked}
      />
    </div>
  );
};

export default BarChart;
