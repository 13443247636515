import { SkeletonLoader, theme } from "lattec-ui-library";
import React from "react";
import iPlaceholderLoader from "./interface";
import './style.scss'

const PlaceholderLoader123: React.FC<iPlaceholderLoader> = (props) => {
  let width = props.width || '100%';
  let height = props.height || '100%';
  let myRandId = Math.random().toString(36).substring(3);

  return <div className="svg-container" style={{ paddingBottom: props.pixelHeight, height:props.height }}>

  

    <SkeletonLoader type="spinner" isStatic={true} spinner={{ "color": "grey" }}  ></SkeletonLoader>
    
  </div>
}

export default PlaceholderLoader123;