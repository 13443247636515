import React, { useEffect, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST } from "../../redux/StickConsumption/constants";
import StatusNumber from "../../StatusNumber";
import { DataTypeDisplay } from "../../DataType";
import {
  DASHBOARD_TOGGLE,
  STICK_CONSUMPTION_TOGGLE,
  SET_LIBRARY_OPEN_URL,
} from "../../redux/Navigation/constants";
import {
  BARS_COLOR_SCHEME_1,
  BARS_COLOR_SCHEME_2
} from "../../styles/ColorCodes";
import "./StickConsumption.scss";
import { iStickConsumption } from "./interface";
import Loader from "../../PlaceholderLoader";
import {
  Widget,
  WidgetHeader,
  WidgetActions,
  WidgetContent,
  Button,
  Tooltips,
} from "lattec-ui-library";
import { GET_USER_OPTIONS_REQUEST } from "../../redux/UserOptions/constants";
import { LineChart } from "../../Charts";
import { getColorId } from "../../utills/dataFormatting";

const StickConsumption: React.FC<iStickConsumption> = (props) => {
  const stickConsumptionState = useSelector(
    (state: any) => state.stickConsumptionState
  );
  const userOptionsState = useSelector((state: any) => state.userOptionsState);

  const history = useHistory();
  const { t } = useTranslation();

  const [step, handleGoToStep] = useState(0);

  let isOwnFarm: number;
  if (props.toggleId === DASHBOARD_TOGGLE) isOwnFarm = userOptionsState.dataType;
  else if (props.toggleId === STICK_CONSUMPTION_TOGGLE)
    isOwnFarm = userOptionsState.isOwnFarm;
  else isOwnFarm = userOptionsState.dataBaseLineReportType;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userOptionsState.gotData) {
      dispatch({ type: GET_USER_OPTIONS_REQUEST, payload: null });
    }

    const thisKpi = userOptionsState.gotData ? userOptionsState.sticksUsed : "SticksUsedPrPregnancy"
    if (!stickConsumptionState.gotData || stickConsumptionState.data[0].kpi !== thisKpi) {
      dispatch({
        type: FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
        payload: {
          kpi: thisKpi,
        },
      });
    }
  }, [dispatch]);

  const yearDataIsEmpty =
    stickConsumptionState.gotData &&
    stickConsumptionState.data[0].data.length === 0;

  const referenceIsEmpty =
    stickConsumptionState.gotData &&
    stickConsumptionState.data[1].data.length === 0;

  const lineData =
    isOwnFarm === 0
      ? [
        { ...stickConsumptionState.data[0] },
        { ...stickConsumptionState.data[1] },
      ]
      : [
        { ...stickConsumptionState.data[0] },
        { ...stickConsumptionState.data[2] },
      ];

  var statusNumber = stickConsumptionState.StickCount;
  var statusNumberText = t("SticksPregnancyTrend");
  var referenceBenchmarkFarm =
    stickConsumptionState.StickCountReferenceBenchmarkFarm;

  if (stickConsumptionState.data[0] && stickConsumptionState.tableData) {
    const sticksUsedPrCow = stickConsumptionState.tableData.filter(
      (x: any) => x.KPIName === stickConsumptionState.data[0].kpi
    )[0];
    statusNumber = sticksUsedPrCow
      ? sticksUsedPrCow.CurrentPointValue
      : statusNumber;

    statusNumberText =
      stickConsumptionState.data[0].kpi === undefined
        ? ""
        : t(stickConsumptionState.data[0].kpi);
  }

  const handleSetUrl = (url: string) =>
    dispatch({ type: SET_LIBRARY_OPEN_URL, payload: url });

  return (
    <Fragment>
      <Widget style={{ height: "100%" }}>
        <WidgetHeader
          headerlineseprator={true}
          title={statusNumberText}
          onClickHeader={
            props.disableOnClickHeader
              ? undefined
              : () => history.push("/stick-consumption")
          }
          icon={{
            lattecName: "StickConsumption",
            variant: "lattec",
            position: "left",
          }}
          actions={
            <WidgetActions>
              <Tooltips content={t("GoToLibrary").toString()} position="bottom">
                <Button
                  variant="text"
                  onClick={() => handleSetUrl("stick-consumption/lvl1/summary")}
                  className="infoButton"
                  icon={{ variant: "fas", fontAwesomeName: "book", size: 18 }}
                />
              </Tooltips>
            </WidgetActions>
          }
        />
        <WidgetContent disablePadding={true} style={{ height: "100%" }}>
          <Fragment>
            {stickConsumptionState.loading ||
              (yearDataIsEmpty && referenceIsEmpty) ? (
              <Loader height={159} type="StickConsumption" />
            ) : stickConsumptionState.error ? ( // Error in data
              <span>{stickConsumptionState.error}</span>
            ) : stickConsumptionState.gotData === false ? ( // data arrays are empty
              <span>{t("NotAvailable")}</span>
            ) : stickConsumptionState.gotData ? ( // we got data and its not empty
              <Fragment>
                <div>
                  <StatusNumber
                    number={statusNumber}
                    text=""
                    colorId={-1 * getColorId(
                      isOwnFarm,
                      statusNumber,
                      referenceBenchmarkFarm,
                      stickConsumptionState.StickCountReferenceOwnFarm,
                    )}
                  >
                    <DataTypeDisplay
                      arrowId="sticks-consumption-reference-arrow"
                      id="sticks-consumption-reference-bars"
                      isOwnFarm={isOwnFarm}
                      trend={stickConsumptionState.TrendIndicator}
                      benchmarkComparison={referenceBenchmarkFarm}
                      current={parseFloat(statusNumber)}
                      ownComparison={
                        stickConsumptionState.StickCountReferenceOwnFarm
                      }
                      reverseTrend={true}
                      colors={isOwnFarm === 0 ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2}
                      containerWidth="15px"
                      containerHeight="40px"
                    />
                  </StatusNumber>
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    minHeight: "100px",
                  }}
                >
                  <div
                    id="sticks-consumption-chart"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <LineChart
                      data={lineData}
                      containerHeight={props.link ? "100px" : "215px"}
                      containerWidth="100%"
                      chartMargins={{ t: 0, l: 0, b: 0, pad: 0, r: 0 }}
                      onClick={undefined}
                      tooltip={{
                        label: t(lineData[0].kpi),
                        tooltip: "title",
                        data: { display: "y" },
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ) : null}
          </Fragment>
        </WidgetContent>
      </Widget>
    </Fragment>
  );
};

export default React.memo(StickConsumption);
