import { BarChart } from '../../../Charts';
import { IFilterState, } from '../../../redux/Filters/reducer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import KpiLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { GET_COWS_LIST_REQUEST, HANDLE_FILTER_COW_LIST_LACTATION_FILTER } from '../../../redux/CowsList/constants';
import { SELECT_LACTATION_FILTERS, } from '../../../redux/Filters/constants';
import DoaChart from "../KPICharts/DOAChart";
import { GROUP_3_COLOR_PALETTE } from '../../../styles/ColorCodes';

const DOA: React.FC = () => {
  const { t } = useTranslation();
  const filtersState: IFilterState = useSelector(
    (state: any) => state.filtersState
  );
  const cowsListState = useSelector((state: any) => state.cowsListState);
  const navigationState = useSelector((state: any) => state.navigationState);

  const dispatch = useDispatch();
  const path = 'doa';
  const keys = ["AnoestrusLength", "DOAState"];
  const filters: any = {};

  useEffect(() => {
    const startDate = new Date(filtersState.today);
    startDate.setDate(
      startDate.getDate() - filtersState.anoestrusLengthSelectedTimePeriod.days
    );

    filtersState.lactationFilters.culledChecked =
      filtersState.lactationFilters.l1Checked =
      filtersState.lactationFilters.l2Checked =
      filtersState.lactationFilters.l3Checked =
      true;
    filtersState.lactationFilters.delayedChecked = false;

    // get the cowlist
    dispatch({
      type: GET_COWS_LIST_REQUEST,
      payload: {
        filters,
        keys,
        path,
        startDate: startDate,
        stopDate: filtersState.today,
        lactationFilters: filtersState.lactationFilters,
      },
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersState.anoestrusLengthSelectedTimePeriod,
      filtersState.today,
    ]);

  const handleToggleLactationFilter = (key: string) => {
    dispatch({ type: SELECT_LACTATION_FILTERS, payload: { key } });
    const newFilter = filtersState.lactationFilters;
    newFilter[`${key}Checked`] = !newFilter[`${key}Checked`];
    dispatch({
      type: HANDLE_FILTER_COW_LIST_LACTATION_FILTER,
      payload: {
        filters,
        keys,
        path,
        data: cowsListState.data,
        lactationFilters: newFilter,
        p2reference: cowsListState.p2reference,
        p3reference: cowsListState.p3reference,
      },
    });
  };

  return <KpiLayout
    title={t("AnoestrusLength")}
    subHeader={t("EvaluatesHerdVariationInAnoestrusLengthBasedOnTheNumberOfDaysFromCalvingToTheFirstIncreaseInProgesterone.")}
    libraryUrl="doa/lvl2/summary"
    kpi='DOA'
    chartImage={DoaChart}
    handleToggleLactationFilter={handleToggleLactationFilter}
    chartSlot1={[{
      title: t("AnoestrusLength"),
      chart: <BarChart
        filterKeys={[
          { key: "DOAState", value: "name" },
          { key: "ranges", value: "x" },
        ]}
        colors={GROUP_3_COLOR_PALETTE}
        filters={["AnoestrusLength", "DOAState"]}
        path={path}
        title={t("AnoestrusLength[Days]")}
        tooltip={{
          displayAxis: 'neither',
          title: 'ykey'
        }}
        data={cowsListState.graphP2Data}
        cowList={cowsListState.filteredData}
        cowListOpen={navigationState.cowListOpen}
        keys={["Cyclic cows", "Anoestrus cows"]}
        indexBy="Ranges"
        yLegend={t("NumberOfCows")}
        xLegend={t("Days")}
        maxValue={cowsListState.graphP2Max}
        delayed={filtersState.lactationFilters.delayedChecked}
      />
    }]}
    chartSlot2={[
      {
        title: t("FirstLutealPhaseLength"),
        chart: < BarChart
          filterKeys={[
            { key: "FPLState", value: "name" },
            { key: "ranges", value: "x" },
          ]}
          filters={["FirstLutealPhaseLength", "FPLState"]}
          path={path}
          title={t("FirstLutealPhaseLngth[Days]")}
          tooltip={{
            displayAxis: 'neither',
            title: 'ykey'
          }}
          data={cowsListState.graphP3Data}
          cowList={cowsListState.filteredData}
          cowListOpen={navigationState.cowListOpen}
          colors={GROUP_3_COLOR_PALETTE}
          keys={["Completed", "Not completed"]}
          indexBy="Bin"
          yLegend={t("NumberOfCows")}
          xLegend={t("Days")}
          maxValue={cowsListState.graphP3Max}
          delayed={filtersState.lactationFilters.delayedChecked}
        />
      }
    ]}
  />
}

export default DOA;