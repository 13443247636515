import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { postJSON } from "../../api/requests";
import {
  GET_HNINSIGHTS_QUESTION_FAILURE,
  GET_HNINSIGHTS_QUESTION_REQUEST,
  GET_HNINSIGHTS_QUESTION_SUCCESS,
  GET_HNINSIGHTS_QUESTIONS_FAILURE,
  GET_HNINSIGHTS_QUESTIONS_REQUEST,
  GET_HNINSIGHTS_QUESTIONS_SUCCESS,
  POST_HNINSIGHTS_ANSWER_FAILURE,
  POST_HNINSIGHTS_ANSWER_REQUEST,
  POST_HNINSIGHTS_ANSWER_SUCCESS,
  SET_HNINSIGHTS_STAGE,
  SET_HNINSIGHTS_STAGE_SUCCESS,
  GET_INSIGHTS_LIST_REQUEST,
  GET_INSIGHTS_LIST_SUCCESS,
  GET_INSIGHTS_LIST_FAILURE,
  GET_ANALYSIS_QUESTIONS_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_SUCCESS,
  GET_CONFIGURATION_QUESTIONS_REQUEST,
  PIN_INSIGHT_TO_DASHBOARD_FAILURE,
  SET_SELECTED_ANSWER,
  GO_TO_PREVIOUS_QUESTION_REQUEST,
  GO_TO_PREVIOUS_QUESTION_SUCCESS,
  GO_TO_PREVIOUS_QUESTION_FAILURE,
  REPLACE_INSIGHT_REQUEST,
  REPLACE_INSIGHT_SUCCESS,
  REPLACE_INSIGHT_FAILURE,
  SET_HNINSIGHTS_STATUS,
  STAGES,
} from "./constants";
import { baseUrl } from "../../api/Common/RestUrlBuilder";
// import { HnInsightsConfigurationQuestionRequestPayload } from "./types";
import { Insight } from "./types";

import { getOrbitFarmGuid, getUsername } from "../../utills/authorization";
import { getBaseUrl } from "../../utills/envHelper";

function* replaceInsight(action: any): SagaIterator {
  try {
    const insights: Insight[] = yield call(
      postJSON,
      getBaseUrl() + "/farms/insights/updateinsight",
      {
        OrbitFarmId: getOrbitFarmGuid(),
        UserId: getUsername(),
        InsightId: action.payload.id,
        DashBoard: false,
        Status: false,
      }
    );

    const parsedInsights = insights.map(
      (el: Insight, index: number): Insight => {
        return {
          dashBoard: el.dashBoard,
          id: el.id,
          key: el.key,
          rank: el.rank,
          expired: el.expired,
        };
      }
    );

    const dbInsightsList = parsedInsights.filter(
      (el: Insight, index: number) => el.dashBoard === 1
    );

    yield put({
      type: REPLACE_INSIGHT_SUCCESS,
      payload: { parsedInsights, dbInsightsList },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: REPLACE_INSIGHT_FAILURE,
      payload: "Unable to pin to dashboard",
    });
  }
}

function* pinInsightToDashboard(action: any): SagaIterator {
  try {
    const insights = yield call(
      postJSON,
      getBaseUrl() + "/farms/insights/updateinsight",
      {
        OrbitFarmId: getOrbitFarmGuid(),
        UserId: getUsername(),
        InsightId: action.payload.id,
        Status: true,
        DashBoard: action.payload.pin,
      }
    );

    const parsedInsights = insights.map(
      (el: Insight, index: number): Insight => {
        return {
          dashBoard: el.dashBoard,
          id: el.id,
          key: el.key,
          rank: el.rank,
          expired: el.expired,
        };
      }
    );

    const dbInsightsList = parsedInsights.filter(
      (el: Insight, index: number) => el.dashBoard === 1
    );

    yield put({
      type: PIN_INSIGHT_TO_DASHBOARD_SUCCESS,
      payload: { parsedInsights, dbInsightsList },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: PIN_INSIGHT_TO_DASHBOARD_FAILURE,
      payload: "Unable to pin to dashboard",
    });
  }
}

function* handleGetConfigurationQuestions(action: any): SagaIterator {
  // ask for configuration questions
  const configurationQuestions = yield call(
    postJSON,
    getBaseUrl() + "/farms/insights/getconfigurationquestions",
    {
      OrbitFarmId: getOrbitFarmGuid(),
      UserId: getUsername(),
    }
  );

  switch (configurationQuestions.status) {
    case "open":
    case "expired":

      if (configurationQuestions.status === "expired") {
        yield put({ type: SET_HNINSIGHTS_STATUS, payload: "config_questions_expired" });
      }

      if (configurationQuestions.open.length > 0) {
        yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: configurationQuestions.open[0]?.questionId } }));
      }

      yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: configurationQuestions });

      // set stage to intro (for component rendering purpose)
      yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });
      break;

    case "closeToExpire":
    case "done":

      // if payload is undefined it is because we have not defined how many questions we wish to answer
      if (action.payload === undefined) {
        yield call(() => getInsightsSaga({ type: GET_INSIGHTS_LIST_REQUEST, payload: null }));
        yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_INTRO });
        // yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: configurationQuestions.open[0]?.questionId }}));
        // set stage to intro (for component rendering purpose)
        // yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });
      } else {
        yield call(() => handleGetAnalysisQuestions({ type: GET_ANALYSIS_QUESTIONS_REQUEST, payload: action.payload }));
      }


      break;

    default:
      yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: "An error occured" });
      break;

  }
}

function* handleGetAnalysisQuestions(action: any): SagaIterator {
  const analysisQuestions = yield call(postJSON, getBaseUrl() + "/farms/insights/startquestionaire", {
    OrbitFarmId: getOrbitFarmGuid(),
    UserId: getUsername(),
    NumberOfQuestions: action.payload,
  }
  );

  switch (analysisQuestions.status) {
    case "open":
      // Fetch a single analysis question  
      yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: analysisQuestions.open[0].questionId } }));

      // set questions object
      yield put({ type: GET_HNINSIGHTS_QUESTIONS_SUCCESS, payload: analysisQuestions });

      // change stage to analysis questonaire
      yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_QUESTIONAIRE });
      break;
    case "done":
      // set stage to analysis finish
      yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.ANALYSIS_FINISH });
      break;
    case "openConfigurationQuestion":
      // Fetch configuration questions
      yield call(() => handleGetConfigurationQuestions({ type: GET_CONFIGURATION_QUESTIONS_REQUEST, payload: action.payload }))

      // set stage to intro 
      yield put({ type: SET_HNINSIGHTS_STAGE, payload: STAGES.CONFIG_INTRO });
      break;
    default:
      console.error("An error occured within analysisQuestions.status at the stage is not a valid case")
      // This is where we dont know what to do, so we just assume an error has occured
      yield put({ type: GET_HNINSIGHTS_QUESTIONS_FAILURE, payload: "An error occured" });
      break;
  }
}

function* getHNInsightsQuestionsSaga(action: any): SagaIterator {
  const hasAnalysisQuestionPayload = action.payload !== undefined && !isNaN(action.payload)

  if (hasAnalysisQuestionPayload) {
    yield call(() => handleGetAnalysisQuestions({ type: GET_ANALYSIS_QUESTIONS_REQUEST, payload: action.payload }));
  } else {
    yield call(() => handleGetConfigurationQuestions({ type: GET_CONFIGURATION_QUESTIONS_REQUEST, payload: action.payload }))
  }
}

function* getPreviousAnswer(action: any): SagaIterator {
  // We do this to find out if a question has been answered previously
  const prevAnswer = yield call(
    postJSON,
    getBaseUrl() + "/farms/insights/getquestionanswer",
    {
      OrbitFarmId: getOrbitFarmGuid(),
      QuestionId: action.payload.questionId,
      UserId: getUsername(),
    }
  );
  
  if (prevAnswer.status === "ok") {
    yield put({ type: SET_SELECTED_ANSWER, payload: prevAnswer });
  } else if (prevAnswer.status === "not_answered") {
    yield put({ type: "QUESTIONS_NOT_ANSWERED_YET", payload: "Questions not answered yet" });
  } else {
    console.error(`error in getPreviousAnswer`, prevAnswer.status);
    yield put({ type: "GET_SELECTED_ANSWER_FAILURE", payload: "Unable to fetch HN Insights previous answer" });
  }
}

function* getHNInsightsQuestionSaga(action: any): SagaIterator {
  const question = yield call(
    postJSON,
    getBaseUrl() + "/farms/insights/getquestion",
    {
      OrbitFarmId: getOrbitFarmGuid(),
      QuestionId: action.payload.questionId,
      UserId: getUsername(),
    }
  );

  if (question.status === "ok") {

    // We do this to find out if a question has been answered previously
    yield call(() => getPreviousAnswer({ type: "SET_PREVIOUS_ANSWER", payload: { questionId: action.payload.questionId } }))

    // Set the active question
    yield put({ type: GET_HNINSIGHTS_QUESTION_SUCCESS, payload: question });
  } else {
    console.error(`error: question.status 2`, question.status);
    yield put({ type: GET_HNINSIGHTS_QUESTION_FAILURE, payload: "Unable to fetch HN Insights question" });
  }
}

export function* setHNInsightsStageSaga(action: any): SagaIterator {
  yield put({ type: SET_HNINSIGHTS_STAGE_SUCCESS, payload: action.payload });
}

export function* getInsightsSaga(action: any): SagaIterator {
  try {
    // get list of insights
    const insights = yield call(
      postJSON,
      getBaseUrl() + "/farms/insights/getinsights",
      {
        OrbitFarmId: getOrbitFarmGuid(),
      }
    );

    if (insights && insights.length > 0) {
      const parsedInsights = insights.map(
        (el: Insight, index: number): Insight => {
          return {
            dashBoard: el.dashBoard,
            id: el.id,
            key: el.key,
            rank: el.rank,
            expired: el.expired,
          };
        }
      );

      const dbInsightsList = parsedInsights.filter(
        (el: Insight, index: number) => el.dashBoard === 1
      );

      yield put({
        type: GET_INSIGHTS_LIST_SUCCESS,
        payload: parsedInsights,
        dbInsightsList,
      });
    } else if (insights === null || insights.length === 0) {
      yield put({
        type: GET_INSIGHTS_LIST_SUCCESS,
        payload: [],
        dbInsightsList: [],
      });
    } else {
      yield put({
        type: GET_INSIGHTS_LIST_FAILURE,
        payload: "Unable to get your list of insights",
      });
    }
  } catch (error) {
    // ms sql generates null as json, redux frame-work throws error on that, left the null check above incase framework changes
    if (error == "SyntaxError: Unexpected end of JSON input")
      yield put({
        type: GET_INSIGHTS_LIST_SUCCESS,
        payload: [],
        dbInsightsList: [],
      });
    else {
      console.log(error, GET_INSIGHTS_LIST_FAILURE);
      yield put({
        type: GET_INSIGHTS_LIST_FAILURE,
        payload: "Unable to get your list of insights 2",
      });
    }
  }
}

export function* goToPreviousQuestionSaga(action: any): SagaIterator {
  try {
    // make sure to ignore previous question when going back
    // we only want to set previous answer if it has answer id
    const question = yield call(
      postJSON,
      getBaseUrl() + "/farms/insights/getquestion",
      {
        OrbitFarmId: getOrbitFarmGuid(),
        QuestionId: action.payload.questionId,
        UserId: getUsername(),
      }
    );

    if (question.status === "ok") {

      const prevAnswer = yield call(
        postJSON,
        getBaseUrl() + "/farms/insights/getquestionanswer",
        {
          OrbitFarmId: getOrbitFarmGuid(),
          QuestionId: action.payload.questionId,
          UserId: getUsername(),
        }
      );

      if (prevAnswer.status === "ok") {
        yield put({ type: SET_SELECTED_ANSWER, payload: prevAnswer });
      }

      yield put({ type: GET_HNINSIGHTS_QUESTION_SUCCESS, payload: question });
      // }

      yield put({ type: GO_TO_PREVIOUS_QUESTION_SUCCESS, payload: question });
    }
  } catch (error) {
    yield put({
      type: GO_TO_PREVIOUS_QUESTION_FAILURE,
      payload: "Unable to go to previous question",
    });
  }
}

function* postHNInsightsAnswerSaga(action: any): SagaIterator {
  try {
    // if not selectedAnswer id we need to get it from prevAnswered
    if (action.payload.selectedAnswer.answer.answerId) {
      // Post answer to the server
      const answer = yield call(
        postJSON,
        getBaseUrl() + "/farms/insights/setanswer",
        {
          OrbitFarmId: getOrbitFarmGuid(),
          AnswerId: action.payload.selectedAnswer.answer.answerId,
          UserId: getUsername(),
        }
      );

      // If that went well
      if (answer.status === "ok" || answer.status === "done") {

        // add changes to redux state
        if (action.payload.selectedAnswer.answer.answerId) {
          // Post answer to the server
          const answer = yield call(
            postJSON,
            `${baseUrl}/farms/insights/setanswer`,
            {
              OrbitFarmId: getOrbitFarmGuid(),
              AnswerId: action.payload.selectedAnswer.answer.answerId,
              UserId: getUsername(),
            }
          );

          // If that went well
          if (answer.status === "ok" || answer.status === "done") {
            // add changes to redux state
            yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });

            /*
                if there was no question id in the selected answer
                and there were no next question, we assume that the
                configure questions are done.
            */

            // if not next question
            if (!action.payload.nextQuestion) {
              if (action.payload.currentStage === STAGES.ANALYSIS_QUESTIONAIRE) {
                yield put({
                  type: SET_HNINSIGHTS_STAGE,
                  payload: STAGES.ANALYSIS_FINISH,
                });

                yield call(() =>
                  getInsightsSaga({
                    type: GET_INSIGHTS_LIST_REQUEST,
                    payload: null,
                  })
                );

                yield put({
                  type: SET_HNINSIGHTS_STAGE,
                  payload: STAGES.INSIGHTS_LIST,
                });
              } else {
                if (action.payload.currentStage === STAGES.CONFIG_QUESTIONAIRE) {
                  // End of configuration questions
                  yield put({
                    type: SET_HNINSIGHTS_STAGE,
                    payload: STAGES.CONFIG_FINISH,
                  });
                }
                else {
                  yield call(() =>
                    getHNInsightsQuestionSaga({
                      type: GET_HNINSIGHTS_QUESTION_REQUEST,
                      payload: {
                        questionId: action.payload.nextQuestion.questionId,
                      },
                    })
                  );
                }
              }
            } else {
              // If we are here it means that there are a next question and we want to fetch it
              yield call(() =>
                getHNInsightsQuestionSaga({
                  type: GET_HNINSIGHTS_QUESTION_REQUEST,
                  payload: {
                    questionId: action.payload.nextQuestion.questionId,
                  },
                })
              );
            }
            // if we get a question id
          } else {
            yield put({
              type: POST_HNINSIGHTS_ANSWER_FAILURE,
              payload: "Unable to post HN Insights answer",
            });
          }
        }
      } else {
        yield put({
          type: POST_HNINSIGHTS_ANSWER_FAILURE,
          payload: "unhandled questionaire case 1",
        });
      }
    } else if (action.payload.selectedAnswer.questionId) {
      yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });
      yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: action.payload.selectedAnswer.questionId } }));
      // this is hit, when the user has not selected an answer
      // since we disable the button if nothing is selected, we can assume that a value must come from
      // previously selected, meaning that no new answer was given and thus we dont need to post anything to the server
    } else if (action.payload.nextQuestion.questionId) {
      // Make sure open and answered are manipulated correctly when we hit this option
      yield put({ type: POST_HNINSIGHTS_ANSWER_SUCCESS, payload: null });
      yield call(() => getHNInsightsQuestionSaga({ type: GET_HNINSIGHTS_QUESTION_REQUEST, payload: { questionId: action.payload.nextQuestion.questionId } }));
    } else {
      // if we are here, there are no more questions
      if (action.payload.currentStage === STAGES.ANALYSIS_QUESTIONAIRE) {
        yield call(() =>
          getInsightsSaga({
            type: GET_INSIGHTS_LIST_REQUEST,
            payload: null,
          })
        );
        yield put({
          type: SET_HNINSIGHTS_STAGE,
          payload: STAGES.INSIGHTS_LIST,
        });
      } else {
        yield put({
          type: POST_HNINSIGHTS_ANSWER_FAILURE,
          payload: "unhandled questionaire case 2",
        });
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: POST_HNINSIGHTS_ANSWER_FAILURE,
      payload: "Unable to post HN Insights answer 2",
    });
  }
}

export function* watchHNInsightsSaga(): SagaIterator {
  yield takeEvery(GET_HNINSIGHTS_QUESTIONS_REQUEST, getHNInsightsQuestionsSaga);
  yield takeEvery(GET_INSIGHTS_LIST_REQUEST, getInsightsSaga);
  yield takeEvery(GET_HNINSIGHTS_QUESTION_REQUEST, getHNInsightsQuestionSaga);
  yield takeEvery(POST_HNINSIGHTS_ANSWER_REQUEST, postHNInsightsAnswerSaga);
  yield takeEvery(SET_HNINSIGHTS_STAGE, setHNInsightsStageSaga);
  yield takeEvery(GO_TO_PREVIOUS_QUESTION_REQUEST, goToPreviousQuestionSaga);
  yield takeEvery(PIN_INSIGHT_TO_DASHBOARD_REQUEST, pinInsightToDashboard);
  yield takeEvery(REPLACE_INSIGHT_REQUEST, replaceInsight);
}
