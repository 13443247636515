import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IBasicPayload } from "../../actions";
import { subtractYearFromDate } from "../../utills/dateFormatting"

import { postXML } from "../../api/requests";
import {
  GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE,
  GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST,
  GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS,
  GET_KPI_CHART_DATA_FAILURE,
  GET_KPI_CHART_DATA_REQUEST,
  GET_KPI_CHART_DATA_SUCCESS,
} from "./constants";
import { getBaseUrl } from '../../utills/envHelper';

function* getCurrentReproductionPerformanceSaga(action: Action<IBasicPayload>): SagaIterator {
  try {
    const payload = yield call(postXML, getBaseUrl() + "/farms/reproduction/dashboard/currentreproductionperformance");

    yield put({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_SUCCESS, payload: payload.length > 0 ? payload[0] : [] });
  } catch (error) {
    console.log(GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE, error);
    yield put({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_FAILURE, payload: "Unable to get data" });
  }
}

function* getKPIChartData(action: Action<any>): SagaIterator {
  try {

    const payload = yield call(postXML, getBaseUrl() + "/farms/reproduction/performancescoreext");

    let newPayload: any[] = [];
    let dimDate: Date | null = null;

    const parse = (value: any): string | null => {
      if (!value) return null;
      if (isNaN(Number(value.toString()))) return null;
      return parseFloat(value).toFixed(0)
    }

    if (payload.length > 0) {
      dimDate = subtractYearFromDate(payload[0].DimDate)
      newPayload = [{
        key: "doa",
        value: parse(payload[0].DOAScore),
        reference: parse(payload[0].DOARefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].DOARefScoreOwnFarm),
        trend: payload[0].DOATrend,
        scoreDate: payload[0].DOAScoreDate,
      }, {
        key: "cr",
        value: parse(payload[0].CRScore),
        reference: parse(payload[0].CRRefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].CRRefScoreOwnFarm),
        trend: payload[0].CRTrend,
        scoreDate: payload[0].CRScoreDate,
      }, {
        key: "hnir",
        value: parse(payload[0].HNIRScore),
        reference: parse(payload[0].HNIRRefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].HNIRRefScoreOwnFarm),
        trend: payload[0].HNIRTrend,
        scoreDate: payload[0].HNIRScoreDate,
      }, {
        key: "ais",
        value: parse(payload[0].AISScore),
        reference: parse(payload[0].AISRefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].AISRefScoreOwnFarm),
        trend: payload[0].AISTrend,
        scoreDate: payload[0].AISScoreDate,
      }, {
        key: "sea",
        value: parse(payload[0].SEAScore),
        reference: parse(payload[0].SEARefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].SEARefScoreOwnFarm),
        trend: payload[0].SEATrend,
        scoreDate: payload[0].SEAScoreDate,
      }, {
        key: "pbss",
        value: parse(payload[0].PBSSScore),
        reference: parse(payload[0].PBSSRefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].PBSSRefScoreOwnFarm),
        trend: payload[0].PBSSTrend,
        scoreDate: payload[0].PBSSScoreDate,
      }, {
        key: "od",
        value: parse(payload[0].ODScore),
        reference: parse(payload[0].ODRefScoreBenchmarkFarms),
        ownFarm: parse(payload[0].ODRefScoreOwnFarm),
        trend: payload[0].ODTrend,
        scoreDate: payload[0].ODScoreDate,
      }];
    }

    yield put({ type: GET_KPI_CHART_DATA_SUCCESS, payload: newPayload, dimDate, });
  } catch (error) {
    console.log(GET_KPI_CHART_DATA_FAILURE, error);
    yield put({ type: GET_KPI_CHART_DATA_FAILURE, payload: "Unable to get data" });
  }
}

export function* watchGetCurrentReproductionPerformance(): SagaIterator {
  yield takeEvery(GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST, getCurrentReproductionPerformanceSaga);
  yield takeEvery(GET_KPI_CHART_DATA_REQUEST, getKPIChartData);
}
