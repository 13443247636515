import React, { forwardRef, isValidElement } from "react";
import { Button } from "lattec-ui-library";
import iCollapsible from './interface'
import "./Collabsible.scss";
import HoverGuide from "../HoverGuide";
import { useTranslation } from "react-i18next";

const Collabsible = forwardRef((props: iCollapsible, ref: any) => {
  const { t } = useTranslation();

  const isReactElement = isValidElement(props.icon)

  return (
    <div className="collabsible" ref={ref} style={{ backgroundColor: props.open && props.level > 1 ? "whitesmoke" : "" }}>
      <div className="header">
        <Button variant="text" className="infoButton" onClick={props.open ? props.onClose : props.onClick}>
          <div className="button-content">
            {isReactElement ? props.icon : <i className={`${props.icon}`} />}
            <p>{props.title}</p>
          </div>
        </Button>
        <div className="button-group">
          {props.button && <HoverGuide content={t("GoToChart")}>{props.button}</HoverGuide>}


          {!props.hideIcon && (
            <HoverGuide content={props.open ? t("CollapsMenu") : t("OpenMenu")} >

              <Button variant="text" className="infoButton" icon={{ variant: "fal", size: "18", fontAwesomeName: (props.open ? 'times' : 'angle-down') }} onClick={props.open ? props.onClose : props.onClick}>
                <div className="open-close">
                  {props.open ? (<i className="fas fa-times" />) : (<i className={"fas fa-angle-down"} />)}
                </div>
              </Button>
            </HoverGuide>
          )}
        </div>
        {props.version && (<div className="libraryText"><h5>{props.version}</h5></div>)}
      </div>
      {props.open && (
        <div
          style={{ padding: `0 ${props.level * 9}px` }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
});

export default Collabsible;
