export const getUsername = () => (window.orbit ? window.orbit.auth.email : "")

export const getOrbitFarmGuid = () => {
  try {
    // @ts-ignore
    return (global.orbit ? orbit.selectedFarm.farmId : "");
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    return null;
  }
}
