import React from "react";
import { useTranslation } from "react-i18next";
import './style.scss'
import { SetIdLineWitdh, SetIdRectOpacity, HoverKPIChart } from '../helper';

function HnirChart() {
  const { t } = useTranslation();

  const ProgesteroneProfile: string[] = ["ProgesteroneProfile", "ProgesteroneProfile1", "ProgesteroneProfile2", "ProgesteroneProfile3"];
  const Heat: string[] = ["Rectangle_1527_10_", "Rectangle_1527_12_", "Rectangle_1527_2_"];

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox="770 450 385 185"
      id="hnirChart"
    >
      <g id="HNIR_-_HN_Insemination_Rate_1_">
        <g>
          <rect x="773.3" y="459.5" className="st5" width="376" height="161" />
          <path id="ProgesteroneProfile" className="st6" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
			c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
			h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
			c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
			c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
			c6.7-2.3,44.4-3.8,60.3-3.8"
            onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
            onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }}>
            <title>{t("ProgesteroneProfile")}</title>


            <path
        id='Heat1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 600.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 680.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat3'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 760.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='AI1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 690.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

        <path
        id='AI2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 770.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

          </path>
          <rect x="771.8" y="460.7" transform="matrix(0.7071 0.7071 -0.7071 0.7071 554.3259 -411.8151)" className="st7" width="5" height="5" />
          <g>
            <rect id="Rectangle_1527_10_" x="958" y="459.5" className="st4" width="8" height="142"
              onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1", true);
                SetIdRectOpacity(Heat, true);
              }}
              onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1");
                SetIdRectOpacity(Heat);
              }} >
              <title>{t("Heat")}</title>
            </rect>
          </g>
          <g>
            <rect id="Rectangle_1527_12_" x="879.5" y="459.5" className="st4" width="8" height="142"
              onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1", true);
                SetIdRectOpacity(Heat, true);
              }}
              onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1");
                SetIdRectOpacity(Heat);
              }} >
              <title>{t("Heat")}</title>
            </rect>
          </g>
          <g>
            <rect id="Rectangle_1527_2_" x="1036.8" y="459.5" className="st4" width="8" height="142"
              onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1", true);
                SetIdRectOpacity(Heat, true);
              }}
              onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                HoverKPIChart("Chart1");
                SetIdRectOpacity(Heat);
              }} >
              <title>{t("Heat")}</title>
            </rect>
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_11_" x="879.5" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_6_">
              <use xlinkHref="#Rectangle_1527_11_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path id="ProgesteroneProfile1" className="st15" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
				c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
				h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
				c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
				c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
				c6.9-2.4,46-3.8,61.2-3.8"
              onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
              onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }}>
              <title>{t("ProgesteroneProfile")}</title>
            </path>
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_13_" x="958" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_17_">
              <use xlinkHref="#Rectangle_1527_13_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path id="ProgesteroneProfile2" className="st16" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
				c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
				h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
				c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
				c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
				c6.9-2.4,46-3.8,61.2-3.8"
              onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
              onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }}>
              <title>{t("ProgesteroneProfile")}</title>
            </path>
          </g>
          <g>
            <defs>
              <rect id="Rectangle_1527_14_" x="1036.8" y="459.7" width="8" height="137" />
            </defs>
            <clipPath id="Rectangle_1527_20_">
              <use xlinkHref="#Rectangle_1527_14_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path id="ProgesteroneProfile3" className="st17" d="M774.2,580.8h49.1c5.5,0,7.4-0.2,11.8-5.1c7.2-8.1,18.1-33.4,22-40.2c4.6-8.1,7.5-10.6,11.3-10.6
				c4.2,0,7.6,3.8,9,14.9c3.8,31.2,3.4,41,11.8,41c1.4,0,0.3,0,2.8,0s7.2-1.3,11-12c3.8-10.7,13.5-42.1,17.7-52s9.2-15.5,14.2-15.5
				h5.3c5.9,0,9.6,4.9,11.3,13.8c1.7,9.1,5.8,43.1,7.3,52.4c1.7,10.3,4.1,13.2,7,13.2c5.1,0,9.2,0,12.2-5.6
				c4.5-8.2,12.1-50.9,19.4-69.8c3.8-9.8,6.8-14.1,14.8-14.1h7c3,0,7.8,4.6,9.8,12.6c4.5,18.1,6.5,45.8,8.5,61.5
				c1.6,12.4,5.5,15.4,8.9,15.4c5.2,0,11.3-1.2,14.1-5.6c3.8-6,12-52.4,14.4-67.9c4-26.2,9.9-26.4,13.9-27.8
				c6.9-2.4,46-3.8,61.2-3.8"
              onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile, true) }}
              onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { SetIdLineWitdh(ProgesteroneProfile) }}>
              <title>{t("ProgesteroneProfile")}</title>
            </path> 
          </g>
          <g>
            <g>
              <rect x="1143.2" y="599" transform="matrix(0.7071 0.7071 -0.7071 0.7071 760.8699 -633.993)" className="st7" width="5" height="5" />
              <polyline className="st9" points="1146.6,601.5 774.1,601.5 774.1,463.2 				"
                onMouseOver={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "6" }}
                onMouseOut={(event: React.MouseEvent<SVGPolylineElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "3" }} >
                <title>{t("ProgesteroneLevel")} / {t("Daysinmilk")}</title>
              </polyline>

              <g>
                  <path
        id='Heat1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 600.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 680.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='Heat3'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st30'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 760.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Heat")}</title>
        </path>

        <path
        id='AI1'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 690.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>

        <path
        id='AI2'
        d='m 940.91983,624.60654 -23.03747,-0.1731 -23.03747,-0.1731 11.66865,-19.86449 11.66864,-19.86448 11.36883,20.03759 z'
        className='st31'
        transform='matrix(0.30358164 -0.00205339 0.00188209 0.2637006 770.4901 453.77678)'
        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "33" }}
        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => { event.currentTarget.style.strokeWidth = "11" }} >
        <title>{t("Insemination")}</title>
        </path>
        </g>
            </g>
          </g>
        </g>
      </g>

    </svg>
  )
}

export default HnirChart;