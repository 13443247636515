import React from "react";
import OverView12Month from "./";
import {
  Widget,
  WidgetContent,
  WidgetHeader,
  RadioGroup,
  WidgetActions,
} from "lattec-ui-library";
import { iOverviewWidget } from "./interface";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatDateNational } from "../../utills/dateFormatting";

const Overview12MonthWidget: React.FC<iOverviewWidget> = (props) => {
  const { t } = useTranslation();

  let title = t("12MonthOverviewChart");
  const date = moment(props.filtersState.today);
  
  if (date.isSame(new Date(), "day") == false) {
    title = `${title} - ${formatDateNational(new Date(props.filtersState.today))}`;
  }

  return (
    <Widget style={{ height: "100%" }}>
      <WidgetHeader
        className="cardHeader"
        headerlineseprator
        title={title}
        key={t("12MonthOverviewChart").toString()}
        actions={[
          <WidgetActions>
            <RadioGroup
              checked={
                props.filtersState.anoestrusLengthSelectedTimePeriod.value
              }
              default=""
              row
              onChange={(e) => {
                props.onClick(e.target.value);
              }}
              radios={[
                {
                  value: props.timePeriods[0].key,
                  label: t(props.timePeriods[0].value),
                  labelClassName: "radioLabel",
                  gizmoClassName: "radioGizmo",
                  componentClassName: "radioComponent",
                  key: props.timePeriods[0].key
                },
                {
                  value: props.timePeriods[1].key,
                  label: t(props.timePeriods[1].value),
                  labelClassName: "radioLabel",
                  gizmoClassName: "radioGizmo",
                  componentClassName: "radioComponent",
                  key: props.timePeriods[1].key
                },
                {
                  value: props.timePeriods[2].key,
                  label: t(props.timePeriods[2].value),
                  labelClassName: "radioLabel",
                  gizmoClassName: "radioGizmo",
                  componentClassName: "radioComponent",
                  key: props.timePeriods[2].key
                },
                {
                  value: props.timePeriods[3].key,
                  label: t(props.timePeriods[3].value),
                  labelClassName: "radioLabel",
                  gizmoClassName: "radioGizmo",
                  componentClassName: "radioComponent",
                  key: props.timePeriods[3].key
                },
              ]}
            />
          </WidgetActions>,
        ]}
      />
      <WidgetContent className="upperKPIContent evalGraph">
        <OverView12Month
          performanceScore={props.performanceScoreHistoryState}
          stepGuide={178781}
          kpi={props.kpi.toLocaleLowerCase()}
          stripped={true}
        />
      </WidgetContent>
    </Widget>
  );
};

export default Overview12MonthWidget;
