import React from 'react';
import { TFunction } from "i18next";
import { Button } from 'lattec-ui-library'
import { iData } from './interface';
import { DataTypeDisplay } from '../../DataType';

import { BARS_COLOR_SCHEME_1, BARS_COLOR_SCHEME_2 } from "../../styles/ColorCodes";
import { formatNumberNational } from '../../utills/dateFormatting';

const formatNumber = (value: number) => {
  if (value === 0 || isNaN(value)) return "-";
  return (value > 0 ? "+" : "") + formatNumberNational(value, 1);
};

const fontColor = (KPIName: string, value: number) => {
  const yellow = "#FFD300";
  const red = "#FF3300";
  const green = "#77AD1C";

  if (value.toFixed(1) === "0.0") return yellow;
  if (
    KPIName === "ProportionOfCowsBred" ||
    KPIName === "InseminationRate" ||
    KPIName === "PregnancyRate"
  ) {
    if (isNaN(value)) {
      return "black";
    } else if (value > 0) return green;
    else return red;
  }
  if (isNaN(value)) {
    return "black";
  } else if (value > 0) return red;
  else return green;
};

export const formatTableData = (
  tableData: iData[],
  userOptionsState: any,
  t: TFunction,
  isOwnFarm: 0 | 1,
  handlePinToDashboard: (kpiName: string) => void
) => {
  return tableData.map((dataPoint) => {
    return {
      Pin: (
        <Button
          style={{ padding: 0, margin: 0, minWidth: 16 }}
          icon={{
            fontAwesomeName: "thumbtack",
            variant: userOptionsState.baselineReport[dataPoint.KPIName] ? "fas" : "fal",
            colors: { fillColor: "#0091FF", strokeColor: "#0091FF" },
          }}
          variant="text"
          onClick={() => handlePinToDashboard(dataPoint.KPIName)}
        />
      ),
      KPIName: t(dataPoint.KPIName),
      Difference: <DataTypeDisplay
        currentValueId="baseline-report-current-value-bar"
        referenceValudId="baseline-report-reference-value-bar"
        isOwnFarm={isOwnFarm}
        layout="horizontal"
        colors={
          isOwnFarm === 0
            ? BARS_COLOR_SCHEME_1 : BARS_COLOR_SCHEME_2
        }
        trend={dataPoint.Trend}
        benchmarkComparison={dataPoint.BenchmarkValue}
        current={parseFloat(dataPoint.CurrentPointValue)}
        ownComparison={parseFloat(
          dataPoint.StartingPointValue
        )}
        containerWidth="204px"
        containerHeight="15px"
      />,
      DiffNum: (<span
        className="table__label"
        style={{
          fontWeight: "bold",
          color: fontColor(
            dataPoint.KPIName,
            isOwnFarm === 0
              ? parseFloat(dataPoint.CurrentPointValue) -
              parseFloat(dataPoint.BenchmarkValue)
              : parseFloat(dataPoint.CurrentPointValue) -
              parseFloat(dataPoint.StartingPointValue)
          ),
        }}
      >
        {formatNumber(
          isOwnFarm === 0
            ? parseFloat(dataPoint.CurrentPointValue) -
            parseFloat(dataPoint.BenchmarkValue)
            : parseFloat(dataPoint.CurrentPointValue) -
            parseFloat(dataPoint.StartingPointValue)
        )}
      </span>),
      Today: parseFloat(dataPoint.CurrentPointValue),
      BaselineStart: isOwnFarm === 0 ? parseFloat(dataPoint.BenchmarkValue) : parseFloat(dataPoint.StartingPointValue),
    }
  });
};

export const getDataRelation = (kpi: string) => {
  let hoveredKpiList = [""];

  switch (kpi) {
    case "ProportionOfCowsBred":
      hoveredKpiList = ["hnir"];
      break;

    case "CalvingToFirstAIInterval":
      hoveredKpiList = ["doa", "hnir"];
      break;

    case "FirstToLastAIInterval":
      hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
      break;

    case "AIsPrCow":
      hoveredKpiList = ["ais", "sea", "pbss"];
      break;

    case "InseminationRate":
      hoveredKpiList = ["doa", "crbwp", "hnir"];
      break;

    case "ConceptionRate":
    case "AIsPrPregnancy":
      hoveredKpiList = ["ais", "sea", "pbss"];
      break;

    case "PregnancyRate":
      hoveredKpiList = ["hnir", "ais", "sea", "pbss"];
      break;

    case "OpenDays":
      hoveredKpiList = ["od"];
      break;
  }
  return hoveredKpiList;
}