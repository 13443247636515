import React, { useState } from "react";
import * as OldButton from "../../Input/Button";
import HoverGuide from "../../HoverGuide";
import "../Card.scss";
import { iGuide } from "./interface";
import { useTranslation } from "react-i18next";
import { Button } from "lattec-ui-library";
import './Style.scss';

const CardGuide: React.FC<iGuide> = (props) => {
  const { t } = useTranslation();

  // @ts-ignore //FIXME: why would this be set but never used?
  const [hovered, handleSetHover] = useState(false);
  // const targetRef = useRef(null) as any;

  return (
    <>
      <div className="card__guide">
        {props.onOpenLib && (
          <HoverGuide content={t("GoToLibrary")}>
            <Button
              variant="text"
              icon={{ variant: "fas", fontAwesomeName: "book" }}
              className="card_btn"
              onClick={props.onOpenLib}
            />
          </HoverGuide>
        )}

        {props.openStepGuide && (
          <HoverGuide content={t("HowToReadThis")}>
            <Button
              variant="text"
              icon={{ variant: "fas", fontAwesomeName: "info-circle" }}
              className="card_btn"
              onClick={props.openStepGuide}
            />
          </HoverGuide>
        )}

        {props.toggleExpand && (
          <HoverGuide
            content={props.isExpanded ? t("ShrinkChart") : t("ExpandChart")}
          >
            <OldButton.default onClick={props.toggleExpand}>
              <span className="fa-stack card__icon card__icon__scale">
                <i className="fal fa-expand fa-stack-1x" />
                {props.isExpanded === true ? (
                  <i className="fa fa-compress-alt fa-stack-1x arrows" />
                ) : (
                  <i className="fa fa-expand-alt fa-stack-1x arrows" />
                )}
              </span>
            </OldButton.default>
          </HoverGuide>
        )}
      </div>
    </>
  );
};

export default CardGuide;
