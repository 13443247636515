export const GET_COWS_LIST_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_COWS_LIST_REQUEST";
export const GET_COWS_LIST_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_COWS_LIST_SUCCESS";
export const GET_COWS_LIST_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_COWS_LIST_FAILURE";
export const HANDLE_FILTER_COW_LIST_GET_DATA = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_GET_DATA";
export const HANDLE_FILTER_COW_LIST_COMPLETE = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_COMPLETE";
export const HANDLE_FILTER_COW_LIST_GRAPH_P2 = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_GRAPH_P2";
export const HANDLE_FILTER_COW_LIST_GRAPH_P3 = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_GRAPH_P3";
export const HANDLE_FILTER_COW_LIST_GRAPH_MAX = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_GRAPH_MAX";
export const HANDLE_FILTER_COW_LIST_SCATTER_P1 = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_SCATTER_P1";
export const HANDLE_FILTER_COW_LIST_SCATTER_P2 = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_SCATTER_P2";
export const HANDLE_FILTER_COW_LIST_LACTATION_FILTER = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_LACTATION_FILTER";
export const HANDLE_COW_LIST_REFERENCE = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_COW_LIST_REFERENCE";
export const HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR = "@@ddw.herdnet.sandbox.hnadvice/HANDLE_FILTER_COW_LIST_SCATTER_P1_HNIR"
