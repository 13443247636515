import React, { useEffect } from 'react'
import { iBaselineReport, iData } from './interface';
import Widget from './Widget'
import DataTable from './Content'
import { useDispatch, useSelector } from 'react-redux';
import { GET_SIMPLE_BASELINE_REPORT_REQUEST, GET_ADVANCED_BASELINE_REPORT_REQUEST } from "../../redux/BaselineReort/constants";
import { DASHBOARD_TOGGLE, STICK_CONSUMPTION_TOGGLE } from '../../redux/Navigation/constants';
import { SkeletonLoader } from 'lattec-ui-library';

const BaselineReport: React.FC<iBaselineReport> = (props) => {
  const baselineReportState = useSelector((state: any) => state.baselineReportState);
  const userOptionsState = useSelector((state: any) => state.userOptionsState);
  const dispatch = useDispatch();

  const data: iData[] = baselineReportState.data.filter((el: any, index: number) => {
    return !(props.simple && !userOptionsState.baselineReport[el.KPIName]);
  })

  let isOwnFarm: 0 | 1;
  if (props.toogleId === DASHBOARD_TOGGLE) isOwnFarm = userOptionsState.dataType;
  else if (props.toogleId === STICK_CONSUMPTION_TOGGLE)
    isOwnFarm = userOptionsState.isOwnFarm;
  else isOwnFarm = userOptionsState.dataBaseLineReportType;

  useEffect(() => {
    if (!baselineReportState.gotData) {
      dispatch({ type: GET_SIMPLE_BASELINE_REPORT_REQUEST, payload: null });
      dispatch({ type: GET_ADVANCED_BASELINE_REPORT_REQUEST, payload: null });
    }
  }, [dispatch, props.simple]);

  const render = () => {
    if (baselineReportState.loading) {
      if (props.wrapInWidget) {
        return <Widget><SkeletonLoader type="article" isStatic={true}></SkeletonLoader></Widget>
      }
      return <SkeletonLoader type="article" isStatic={true}></SkeletonLoader>;
    }

    const content =
      <DataTable paper={true} headers={props.headers} data={data} isOwnFarm={isOwnFarm} simple={props.simple} />
    if (props.wrapInWidget) return <Widget>  {content} </Widget>
    return content
  }

  return render();
}

export default BaselineReport;