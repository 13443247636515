import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import Tooltip from "../../Tooltip";
import "../DataType.scss";
import { iDataTypeDisplay } from "./interface";
import { useTranslation } from "react-i18next";
import { formatNumberNational } from "../../utills/dateFormatting";
import { Icon, Tooltips, BarChart } from "lattec-ui-library";

const DataTypeDisplay: React.FC<iDataTypeDisplay> = (props) => {
  const { t } = useTranslation();

  let maxVAlue =
    props.isOwnFarm === 1
      ? (props.ownComparison || 0) * 1.1
      : props.benchmarkComparison * 1.1;
  maxVAlue = maxVAlue < props.current + 10 ? props.current * 1.1 : maxVAlue;
  maxVAlue = maxVAlue || 0;

  let primX;
  let primY;

  let secX;
  let secY;

  if (props.layout === "horizontal") {
    primX = [props.current || 0];
    primY = ["MyFarm"];
    secX = [
      (props.isOwnFarm === 1
        ? props.ownComparison
        : props.benchmarkComparison) || 0,
    ];
    secY = [props.isOwnFarm === 1 ? "MyFarmReference" : "Benchmark farms"];
  } else {
    primX = ["MyFarm"];
    primY = [props.current || 0];
    secX = [props.isOwnFarm === 1 ? "MyFarmReference" : "Benchmark farms"];
    secY = [
      (props.isOwnFarm === 1
        ? props.ownComparison
        : props.benchmarkComparison) || 0,
    ];
  }
  const chartMargins = {
    t: props.layout === "horizontal" ? 1 : 0,
    b: props.layout === "horizontal" ? 1 : 0,
    l: props.layout === "horizontal" ? 0 : 1,
    r: props.layout === "horizontal" ? 0 : 1,
    pad: 0,
  };

  return (
    <div className="data-type__wrapper" id={props.id}>
      {props.layout === "horizontal" || props.trend === undefined ? (
        <></>
      ) : (
        <div style={{ top: 0, height: "40px", verticalAlign: "top" }}>
          <Tooltips content={t("Trend").toString()} position="bottom">
            <Icon
              variant="fas"
              style={{ color: "gray" }}
              fontAwesomeName={`${
                props.trend < 0
                  ? "arrow-alt-down"
                  : props.trend > 0
                  ? "arrow-alt-up"
                  : "grip-lines"
              }`}
            />{" "}
          </Tooltips>
          &nbsp;&nbsp;
        </div>
      )}
      <div
        className={`data-type__benchmark-sticks-wrapper ${
          props.layout === "horizontal"
            ? "data-type__benchmark-sticks-wrapper--horizontal"
            : props.layout === "vertical"
            ? "data-type__benchmark-sticks-wrapper--vertical"
            : ""
        }`}
      >
        <div className="data-type__benchmark-stick" id={props.currentValueId}>
          <BarChart
            orientation={props.layout || "vertical"}
            data={[
              {
                x: primX,
                //@ts-ignore
                y: primY,
                color: props.colors[0],
              
              },
            ]}
            
            marker={{
              line: { width: 2, color: 'white' },
            }}
            display="neither"
            xLegend=""
            yLegend=""
            containerWidth={props.containerWidth}
            containerHeight={props.containerHeight}
            chartMargins={chartMargins}
            maxX={props.layout === "horizontal" ? maxVAlue : undefined}
            maxY={props.layout === "horizontal" ? undefined : maxVAlue}
            paperColor="rgba(0,0,0,0)"
            tooltip={{
              display: "neither",
              title:
                t("MyFarm") +
                ": " +
                formatNumberNational(props.current || 0, 1),
            }}
            barWidth={3}
          />
        </div>

        {/* benchmark */}
        <div className="data-type__benchmark-stick" id={props.referenceValudId}>
          <BarChart
            orientation={props.layout || "vertical"}
            data={[
              {
                x: secX,
                y: secY,
                color: props.colors[1],
              },
            ]}
            display="neither"
            xLegend=""
            yLegend=""
            containerWidth={props.containerWidth}
            containerHeight={props.containerHeight}
            chartMargins={chartMargins}
            maxX={props.layout === "horizontal" ? maxVAlue : undefined}
            maxY={props.layout === "horizontal" ? undefined : maxVAlue}
            paperColor="rgba(0,0,0,0)"
            tooltip={{
              display: "neither",
              title:
                t(
                  props.isOwnFarm === 1 ? "MyFarmReference" : "Benchmark farms"
                ) +
                ": " +
                formatNumberNational(
                  (props.isOwnFarm === 1
                    ? props.ownComparison
                    : props.benchmarkComparison) || 0,
                  1
                ),
            }}
            barWidth={3}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(DataTypeDisplay);
