import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeadline,
  CardGuide,
  CardContent,
} from "../../Card";
import { handleTriggerStartTour } from "../../utills/intercom";
import StatusNumber from "../../StatusNumber";
import { DataTypeDisplay } from "../../DataType";
import { BARS_COLOR_SCHEME_1 } from "../../styles/ColorCodes";
import { iOverView12Month } from "./interface";
import PlaceholderLoader from "../../PlaceholderLoader";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { formatDateNational } from "../../utills/dateFormatting";
import { LineChart } from "../../Charts";
import { SELECT_DATA_TME_START } from "../../redux/Filters/constants";
import { Typography, } from "lattec-ui-library";

const OverView12Month: React.FC<iOverView12Month> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentReproductionPerformanceState = useSelector(
    (state: any) => state.currentReproductionPerformanceState
  );

  const handleSetStartDate = (date: Date) => {
    if (date) {
      dispatch({
        type: SELECT_DATA_TME_START,
        payload: { today: date, index: 0, key: "anoestrusLength" },
      });
    }
  };

  const benchmarkComparison =
    currentReproductionPerformanceState.gotData &&
      currentReproductionPerformanceState.kpiChartData.find(
        (element: any) => element.key === props.kpi
      )
      ? currentReproductionPerformanceState.kpiChartData.find(
        (element: any) => element.key === props.kpi
      ).reference
      : 0;

  function getWithCard() {
    return (
      <Card className="months12">
        <CardHeader>
          <CardHeadline text={t("OverviewOfThePast12Months")} />
          <CardGuide
            openStepGuide={() => handleTriggerStartTour(props.stepGuide)}
          />
        </CardHeader>
        {props.performanceScore.data === null ||
          props.performanceScore.data.length === 0 ? (
          <PlaceholderLoader type="KpiOverview" height="194" />
        ) : (
          <CardContent>
            <div className="StatusNumbers">
              <StatusNumber
                className="status-number"
                id="overview-number"
                number={props.performanceScore.scoreValue}
                text={
                  t("LastScoreDate") +
                  ": " +
                  formatDateNational(new Date(props.performanceScore.scoreDate))
                }
              >
                <DataTypeDisplay
                  id="overview-reference-bars"
                  benchmarkComparison={benchmarkComparison}
                  current={
                    props.performanceScore.gotData &&
                    props.performanceScore.scoreValue
                  }
                  colors={BARS_COLOR_SCHEME_1}
                  containerWidth="204px"
                  containerHeight="20px"
                />
              </StatusNumber>
            </div>
            {getContent()}
          </CardContent>
        )}
      </Card>
    );
  }

  function getContent() {
    return (
      <div>
        {props.performanceScore.data === null ||
          props.performanceScore.data.length === 0 ? (
          <PlaceholderLoader type="KpiOverview" height="236" />
        ) : (
          <div id="overview-chart">
            {props?.performanceScore?.data[0]?.data?.length > 0 ?
              <LineChart
                data={
                  props.performanceScore.data ? props.performanceScore.data : []
                }
                onClick={(date: Date) => {
                  handleSetStartDate(date);
                }}
                containerHeight="150px"
                containerWidth="100%"
                chartMargins={{ t: 0, l: 0, b: 0, pad: 0, r: 0 }}
                tooltip={{
                  label: t("Score"),
                  tooltip: "title",
                  data: { display: "y" },
                }}
              /> :
              <div className="svg-container" style={{ height: "120px" }}>
                <Typography
                  variant="Heading 2"
                  text={t("NotAvailable")}
                  style={{ paddingBottom: "16px", color: "rgb(113, 113, 113)" }}
                />
              </div>
            }
          </div>
        )}
      </div>
    );
  }

  if (props.stripped) return getContent();
  return getWithCard();
};

export default OverView12Month;
