import React, { useEffect } from "react";
import { Dropdown, Button as OldButton } from "../Input";
import { SELECT_SETTINGS_DROPDOWN_VALUE, UPDATE_TEXT_VALUE, SET_NEW_SETTINGS_REQUEST } from "../redux/Settings/constants";
import { initialSettings } from "../redux/Settings/reducer";
import { useSelector, useDispatch } from "react-redux";
import { GET_SETTINGS_REQUEST } from "../redux/Settings/constants";
import "./Settings.scss";
import { useTranslation } from "react-i18next";
import { GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST, GET_KPI_CHART_DATA_REQUEST, } from "../redux/CurrentReproductionPerformance/constants";
import { GET_SIMPLE_BASELINE_REPORT_REQUEST, GET_ADVANCED_BASELINE_REPORT_REQUEST } from "../redux/BaselineReort/constants";
import { GET_STICK_CONSUMPTION_REQUEST, } from "../redux/StickConsumption/constants";
import { Button } from "lattec-ui-library";
import { initialDimBenchmarkFilterGeographicalRegionIdList } from "../redux/Settings/reducer";

const Settings: React.FC = (props) => {
  const { t } = useTranslation();

  const settingsState = useSelector((state: any) => state.settingsState);
  const userOptionsState = useSelector((state: any) => state.userOptionsState);

  // remove un-wanted item from list (to be diplayed, since backend still uses full list)
  const reducedBenchmarkFilterGeographicalRegionList = settingsState.DimBenchmarkFilterGeographicalRegionList.filter((o: any) => o.key !== 1);
  // map the un-wanted value, "Region" to "Own country"
  const benchmarkFilterGeographicalRegionIdSelectedValue = settingsState.DimBenchmarkFilterGeographicalRegionIdSelected.value ===
    initialDimBenchmarkFilterGeographicalRegionIdList[0].value ?
    initialDimBenchmarkFilterGeographicalRegionIdList[0] :
    initialDimBenchmarkFilterGeographicalRegionIdList[2];

  const dispatch = useDispatch();

  const selectDropdownValue = (val: number, key: string) => {
    dispatch({ type: SELECT_SETTINGS_DROPDOWN_VALUE, payload: { index: val, key } });
  }
  const validValue = (e: any) => {
    return e > 49 && e < 101
  }
  const handleUpdateTextField = (e: any, key: string) => {
    e.target.style.background = validValue(e.target.value) ? "white" : "#FF6666";
    dispatch({ type: UPDATE_TEXT_VALUE, payload: { value: e.target.value, key, validData: validValue(e.target.value) } });
  }

  const handleUpdateSettings = () => {
    dispatch({ type: SET_NEW_SETTINGS_REQUEST, payload: settingsState.settings })

    // if we update the settings, some score ect. could be re-calculated so we fetch these data again
    var delay = (function () {
      var timer: any = 0;
      return function (callback: any, ms: number) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();
    delay(function () {
      dispatch({ type: GET_CURRENT_REPRUDCTION_PERFORMANCE_REQUEST });
      dispatch({ type: GET_KPI_CHART_DATA_REQUEST, payload: null });
      dispatch({ type: GET_SIMPLE_BASELINE_REPORT_REQUEST, payload: null });
      dispatch({ type: GET_ADVANCED_BASELINE_REPORT_REQUEST, payload: null });
      dispatch({ type: GET_STICK_CONSUMPTION_REQUEST, payload: null });
    }, 10000);
  }
  const handleResetSettings = () => dispatch({ type: SET_NEW_SETTINGS_REQUEST, payload: initialSettings });

  useEffect(() => {
    // get questions when component is mounted
    if (!settingsState.gotData) {
      dispatch({ type: GET_SETTINGS_REQUEST, payload: null });
    }

    // tell hook to skip re-render if state hasnt changed
  }, [dispatch]);

  return (
    <div className="settings">
      <div className="settings__wrapper">
        <div className="settings__inner">
          <div className="settings__section">
            <h2 className="settings__section-headline">{t("VoluntaryWaitingPeriod")}</h2>
          </div>
          <div className="settings__section" >
            <label className="settings__label">{t("1stLactation")} (50-100) :</label>
            <input
              className="settings__input"
              type="text"
              placeholder="Ex. 55"
              value={settingsState.settings.VoluntaryWaitingPeriodL1}
              onChange={(e: any) => handleUpdateTextField(e, "VoluntaryWaitingPeriodL1")}
            />
          </div>
          <div className="settings__section">
            <label className="settings__label">{t("2ndLactation")} (50-100) :</label>
            <input
              className="settings__input"
              type="text"
              placeholder="Ex. 55"
              value={settingsState.settings.VoluntaryWaitingPeriodL2P}
              onChange={(e: any) => handleUpdateTextField(e, "VoluntaryWaitingPeriodL2P")}
            />
          </div>
        </div>

        <div className="settings__inner">
          <div className="settings__section">
            <h2 className="settings__section-headline">{t("BenchmarkSettings")}</h2>
          </div>
          <div className="settings__section">
            <label className="settings__label">{t("BaselineReportBasis")}:</label>
            <Dropdown
              onChange={(e: any) => selectDropdownValue(e, "DimZeroMeasurementBasis")}
              selected={settingsState.DimZeroMeasurementBasisIdSelected}
              list={settingsState.DimZeroMeasurementBasisList}
              style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
            />
          </div>
          <div className="settings__section">
            <label className="settings__label">{t("GeographicalBasis")}:</label>
            <Dropdown
              onChange={(e: any) => selectDropdownValue(e, "DimBenchmarkFilterGeographicalRegion")}
              selected={benchmarkFilterGeographicalRegionIdSelectedValue}
              list={reducedBenchmarkFilterGeographicalRegionList}
              style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
            />
          </div>
          {/*  DISABLE THESE START  
          <div className="settings__section">
            <label className="settings__label">{t("PerformanceLevel")}:</label>
            <Dropdown
              onChange={(e: any) => selectDropdownValue(e, "DimBenchmarkFilterPerformanceLevel")}
              value={settingsState.DimBenchmarkFilterPerformanceLevelIdSelected.value}
              list={settingsState.DimBenchmarkFilterPerformanceLevelList}
              style={{ padding: "18px", border: "1px solid #ECECEC", display: "flex", flex: "1", marginRight: "18px" }}
              disabled={true}
            />
          </div>
           DISABLE THESE END  */}
        </div>
      </div>
      <div className="settings__footer">
        <Button variant="primary"
          onClick={handleUpdateSettings}
          label={t("SaveSettings")}
          disabled={settingsState.loading || !settingsState.validData}
        />
        <Button
          variant="secondary"
          onClick={handleResetSettings}
          disabled={settingsState.loading}
          label={t("ResetToDefaultSettings")}
        />
      </div>
      {settingsState.error === "500" &&
        <h3 className="error"> {t("BackEndError500")} </h3>}
    </div>
  );
};

export default Settings;
