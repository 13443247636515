import React from "react";
import {
  StickConsumption,
  StickConsumptionTable,
  CurrentReproductionPerformance,
  TopTenCows,
} from "../../Cards";
import {
  STICK_CONSUMPTION_TOGGLE,
} from "../../redux/Navigation/constants";
import { Grid } from "lattec-ui-library";
import StickConsumptionHeader from "./StickConsumptionHeader";

const StickConsumptionPage: React.FC = (props) => {

  return (
    <>
      <StickConsumptionHeader />
      <Grid container direction="column">
        <Grid item container direction="row">
          <Grid lg={7} xs={12} spacingRight={5} spacingBottom={5} style={{zIndex:0}}>
            <StickConsumptionTable toogleId={STICK_CONSUMPTION_TOGGLE} />
          </Grid>
          <Grid lg={5} xs={12} spacingBottom={5}>
            <StickConsumption
              disableOnClickHeader={true}
              toggleId={STICK_CONSUMPTION_TOGGLE}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid lg={5} xs={12} spacingRight={5} spacingBottom={5}>
            <TopTenCows />
          </Grid>
          <Grid lg={7} xs={12} spacingBottom={5}>
            <CurrentReproductionPerformance
              toggleId={STICK_CONSUMPTION_TOGGLE}
              simple
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StickConsumptionPage;
