import {
  FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE,
  FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST,
  FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS,
  GET_STICK_CONSUMPTION_REQUEST,
  GET_STICK_CONSUMPTION_SUCCESS,
  GET_STICK_CONSUMPTION_FAILURE,
  GET_TOP_STICK_CONSUMING_COWS_REQUEST,
  GET_TOP_STICK_CONSUMING_COWS_SUCCESS,
  GET_TOP_STICK_CONSUMING_COWS_FAILURE,
} from "./constants";

export const initialState: any = {
  loading: false,
  error: null,
  gotData: false,
  topTenCowsGotData: false,
  tableDataGotData: false,
  tableData: [],
  topTenCowsData: [],
  StickCountReferenceBenchmarkFarm: 0,
  StickCountReferenceOwnFarm: 0,
  StickCount: "0",
  StickDiff: "0",
  TrendIndicator: 0,
  data: [{
    id: "YearData",
    data: [],
  }, {
    id: "YearDataReference",
    data: [],
  }],
};

export const StickConsumptionReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_TOP_STICK_CONSUMING_COWS_REQUEST:
    case GET_STICK_CONSUMPTION_REQUEST:
    case FETCH_STICK_CONSUMPTION_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_STICK_CONSUMPTION_CHART_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        gotData: true,
        data: action.payload.newPayload,
        StickCountReferenceBenchmarkFarm: action.payload.StickCountReferenceBenchmarkFarm,
        StickCountReferenceOwnFarm: action.payload.StickCountReferenceOwnFarm,
        StickCount: action.payload.StickCount,
        StickDiff: action.payload.StickDiff,
        TrendIndicator: action.payload.TrendIndicator,
      };
    }

    case GET_TOP_STICK_CONSUMING_COWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        gotData: true,
        topTenCowsGotData: true,
        topTenCowsData: action.payload,
      };
    }

    case GET_STICK_CONSUMPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        gotData: true,
        tableDataGotData: true,
        tableData: action.payload,
      };
    }

    case GET_TOP_STICK_CONSUMING_COWS_FAILURE:
    case GET_STICK_CONSUMPTION_FAILURE:
    case FETCH_STICK_CONSUMPTION_CHART_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        gotData: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
