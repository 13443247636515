import moment from "moment";
import {
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  SELECT_SETTINGS_DROPDOWN_VALUE,
  SET_NEW_SETTINGS_FAILURE,
  SET_NEW_SETTINGS_REQUEST,
  SET_NEW_SETTINGS_SUCCESS,
  UPDATE_TEXT_VALUE,
  GET_SIMPLE_BASELINE_SETTINGS_SUCCESS,
} from "./constants";

// TODO: the initial value of the dropdowns should be the ones in the settings object
export const initialDimZeroMeasurementBasisIdList = [{
  key: 1,
  value: "Before HN",
  age: 0,
}, {
  key: 3,
  value: "6 months ago",
  age: 6,
}, {
  key: 4,
  value: "1 year ago",
  age: 12,
}, {
  key: 5,
  value: "2 years ago",
  age: 24,
}, {
  key: 6,
  value: "3 years ago",
  age: 36,
}];

export const initialDimBenchmarkFilterGeographicalRegionIdList = [{
  key: 0,
  value: "All",
}, {
  key: 1,
  value: "Region",
}, {
  key: 2,
  value: "Own country",
}];

export const initialDimBenchmarkFilterPerformanceLevelIdList = [{
  key: 0,
  value: "Average",
}, {
  key: 1,
  value: "50",
}, {
  key: 2,
  value: "25",
}, {
  key: 3,
  value: "10",
}];

export const initialSettings = {
  VoluntaryWaitingPeriodL1: 70,
  VoluntaryWaitingPeriodL2P: 70,
  DimBenchmarkFilterGeographicalRegionId: 2,
  DimBenchmarkFilterPerformanceLevelId: 0,
  DimZeroMeasurementBasisId: 1,

  // DaysToPregnancy_LagTime: 5,
  // DaysToPregnancy_PeriodBackward: 5,
  // DimFarmId: 22,
  // FKCountries: 752,
  // FarmName: "Berte Gård AB",
  // KetosisDaysSplit: 14,
  // PerformanceLevel: 50,
  // ThresholdAnoestrus: 0.7,
  // ThresholdFollicleCyst: 0.7,
  // ThresholdKetosis: 0.7,
  // ThresholdLutealCyst: 0.7,
  // ThresholdMastitis: 0.7,
  // ZeroMeasurementBasisDescription: "1 year ago",
};

export const initialState: any = {
  loading: false,
  error: null,
  gotData: false,
  settings: initialSettings,
  validData: true,

  DimZeroMeasurementBasisList: initialDimZeroMeasurementBasisIdList,
  DimZeroMeasurementBasisIdSelected: initialDimZeroMeasurementBasisIdList[1],

  DimBenchmarkFilterGeographicalRegionList: initialDimBenchmarkFilterGeographicalRegionIdList,
  DimBenchmarkFilterGeographicalRegionIdSelected: initialDimBenchmarkFilterGeographicalRegionIdList[2],

  DimBenchmarkFilterPerformanceLevelList: initialDimBenchmarkFilterPerformanceLevelIdList,
  DimBenchmarkFilterPerformanceLevelIdSelected: initialDimBenchmarkFilterPerformanceLevelIdList[0],
};

export const SettingsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
    case SET_NEW_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case GET_SETTINGS_SUCCESS: {

      let dimZeroMeasurementBasisIdList = initialDimZeroMeasurementBasisIdList;
      
      if (action.payload?.FarmStartupDate) {
        const startingPointDate = new Date(action.payload.FarmStartupDate)
        dimZeroMeasurementBasisIdList = dimZeroMeasurementBasisIdList.filter(x => x.age < moment().diff(startingPointDate, 'months'));
      }
      
      return {
        ...state,
        loading: false,
        gotData: true,
        settings: action.payload,
        error: null,

        DimZeroMeasurementBasisList: dimZeroMeasurementBasisIdList,

        // for now, we know that index and id is the same value
        DimZeroMeasurementBasisIdSelected: initialDimZeroMeasurementBasisIdList.filter((el) => el.key === action.payload.DimZeroMeasurementBasisId)[0],
        DimBenchmarkFilterGeographicalRegionIdSelected: initialDimBenchmarkFilterGeographicalRegionIdList.filter((el) => el.key === action.payload.DimBenchmarkFilterGeographicalRegionId)[0],
        DimBenchmarkFilterPerformanceLevelIdSelected: initialDimBenchmarkFilterPerformanceLevelIdList.filter((el) => el.key === action.payload.DimBenchmarkFilterPerformanceLevelId)[0], // [action.payload.DimBenchmarkFilterPerformanceLevelId],
      };
    }

    case SET_NEW_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        gotData: true,
        error: null,
      };
    }

    case SET_NEW_SETTINGS_FAILURE:
    case GET_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        gotData: false,
        error: action.payload,
      };
    }

    case UPDATE_TEXT_VALUE: {
      return {
        ...state,
        validData: action.payload.validData,
        settings: {
          ...state.settings,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case SELECT_SETTINGS_DROPDOWN_VALUE: {
      let selected = state[`${action.payload.key}List`].filter((x:any) => x).find((x:any) => x.key === action.payload.index);
      const newState = {
        ...state,
        settings: {
          ...state.settings,
          [`${action.payload.key}Id`]: selected.key,
        },
        [`${action.payload.key}IdSelected`]: selected,
      };
      return newState;
    }

    default:
      return state;
  }
};
