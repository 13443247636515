export const GET_SETTINGS_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/GET_SETTINGS_FAILURE";

export const SET_NEW_SETTINGS_REQUEST = "@@ddw.herdnet.sandbox.hnadvice/SET_NEW_SETTINGS_REQUEST";
export const SET_NEW_SETTINGS_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/SET_NEW_SETTINGS_SUCCESS";
export const SET_NEW_SETTINGS_FAILURE = "@@ddw.herdnet.sandbox.hnadvice/SET_NEW_SETTINGS_FAILURE";

export const SELECT_SETTINGS_DROPDOWN_VALUE = "@@ddw.herdnet.sandbox.hnadvice/SELECT_SETTINGS_DROPDOWN_VALUE";
export const UPDATE_TEXT_VALUE = "@@ddw.herdnet.sandbox.hnadvice/UPDATE_TEXT_VALUE";

export const GET_SIMPLE_BASELINE_SETTINGS_SUCCESS = "@@ddw.herdnet.sandbox.hnadvice/GET_SIMPLE_BASELINE_SETTINGS_SUCCESS";

